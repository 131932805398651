import {
  FileInputInfo,
  InvalidInputFile,
  IpisFileV2,
  UploadFilesOutput,
} from "@eljouren/file-schemas";
import { CustomFileInputFile } from "../../components/files/FileInputButton";
import trpcClient from "../../trpc-setup";
import IAuthRepo from "./interfaces/IAuthRepo";
import IClientWorkOrderFileRepoV2 from "./interfaces/IClientWorkOrderFileRepoV2";

type Method = "upload" | "get" | "delete";
type As = "handyman" | "staff";
type TypeOfFiles = "related" | "main";

type CacheKeyBuildArgs =
  | {
      method: Extract<Method, "upload" | "delete">;
      workOrderId: string;
      as: As;
      type?: undefined;
    }
  | {
      method: Extract<Method, "get">;
      workOrderId: string;
      as: As;
      type: TypeOfFiles;
    };

type CacheKeySaveArgs = CacheKeyBuildArgs & {
  response: PresignedUrlResponse;
};

type PresignedUrlResponse = {
  url: string;
  expires: Date;
};

/* 
	ToDo:
	* Find a way to cache the presign link until expired
  * Tests!
*/
export default class ClientWorkOrderFileRepoV2
  implements IClientWorkOrderFileRepoV2
{
  private cache: Map<string, PresignedUrlResponse> = new Map();

  constructor(
    private props: {
      authRepo: IAuthRepo;
    }
  ) {
    this.subscribeToAuthState();
  }

  private subscribeToAuthState() {
    this.props.authRepo.signedInState.subscribeWithInitial((signedInState) => {
      // Clear cache whenever signed in state changes
      this.cache.clear();
      return true;
    });
  }

  private saveInCache(args: CacheKeySaveArgs) {
    const key = this.buildCacheKey(args);
    this.cache.set(key, args.response);
  }

  private buildCacheKey(args: CacheKeyBuildArgs) {
    return `${args.method}-${args.as}-${args.type}-${args.workOrderId}`;
  }

  private getFromCache(
    args: CacheKeyBuildArgs
  ): PresignedUrlResponse | undefined {
    const key = this.buildCacheKey(args);
    const val = this.cache.get(key);

    if (val === undefined) {
      return undefined;
    }

    // At least one minute before expiration
    const now = new Date();
    const expires = val.expires;
    const diff = expires.getTime() - now.getTime();
    const diffInMinutes = diff / 1000 / 60;

    if (diffInMinutes < 1) {
      return undefined;
    }

    return val;
  }

  async uploadWithPresignedUrl(args: {
    url: string;
    files: CustomFileInputFile[];
  }): Promise<UploadFilesOutput.Type> {
    const url = args.url;
    const formData = new FormData();

    args.files.forEach((fileObj) => {
      formData.append("files", fileObj.native);
      formData.append("meta", JSON.stringify(fileObj.meta));
    });
    const fileRes = await fetch(url, {
      method: "POST",
      body: formData,
    });
    const json = await fileRes.json();

    const parsed = UploadFilesOutput.Schema.parse(json);
    return parsed;
  }

  async getFilesWithPresignedUrl(args: {
    url: string;
  }): Promise<IpisFileV2.Type[]> {
    const url = args.url;
    const fileRes = await fetch(url);
    const json = await fileRes.json();
    const parsed = IpisFileV2.Schema.array().parse(json.files);
    return parsed;
  }

  async uploadAsCustomer(args: {
    files: CustomFileInputFile[];
  }): Promise<UploadFilesOutput.Type> {
    const urlRes =
      await trpcClient.workOrderFilePresignRouter.createUploadPresignLink.mutate(
        {}
      );

    return this.uploadWithPresignedUrl({ url: urlRes.url, files: args.files });
  }
  async uploadAsSalesTeam(args: {
    files: CustomFileInputFile[];
  }): Promise<UploadFilesOutput.Type> {
    const signedInAsSalesTeam =
      this.props.authRepo.signedInState.value.signedInAs === "sales";
    if (!signedInAsSalesTeam) {
      throw new Error("Not signed in as sales team");
    }

    const urlRes =
      await trpcClient.workOrderFilePresignRouter.createUploadPresignLink.mutate(
        {}
      );

    return this.uploadWithPresignedUrl({ url: urlRes.url, files: args.files });
  }

  async uploadAsHandyman(args: {
    workOrderId: string;
    files: CustomFileInputFile[];
  }): Promise<UploadFilesOutput.Type> {
    if (process.env.NODE_ENV === "development" && false) {
      return buildResponseWithAllTypesFailedReasons();
    }

    const signedInAsHandyman =
      this.props.authRepo.signedInState.value.signedInAs === "worker";
    if (!signedInAsHandyman) {
      throw new Error("Not signed in as handyman");
    }

    const cached = this.getFromCache({
      method: "upload",
      workOrderId: args.workOrderId,
      as: "handyman",
    });

    let urlRes: PresignedUrlResponse;
    if (cached) {
      urlRes = cached;
    } else {
      const newUrlRes =
        await trpcClient.workOrderFilePresignRouter.createUploadPresignLink.mutate(
          {
            workOrderId: args.workOrderId,
          }
        );

      this.saveInCache({
        method: "upload",
        workOrderId: args.workOrderId,
        as: "handyman",
        response: newUrlRes,
      });
      urlRes = newUrlRes;
    }

    return this.uploadWithPresignedUrl({ url: urlRes.url, files: args.files });
  }

  async getFilesAsHandyman(args: {
    workOrderId: string;
  }): Promise<IpisFileV2.Type[]> {
    let urlRes: PresignedUrlResponse;
    const cached = this.getFromCache({
      method: "get",
      workOrderId: args.workOrderId,
      as: "handyman",
      type: "main",
    });

    if (cached) {
      urlRes = cached;
    } else {
      const newUrlRes =
        await trpcClient.workOrderFilePresignRouter.createHandymanOrStaffGetMultipleUrl.mutate(
          {
            workOrderId: args.workOrderId,
          }
        );
      this.saveInCache({
        method: "get",
        workOrderId: args.workOrderId,
        response: newUrlRes,
        as: "handyman",
        type: "main",
      });
      urlRes = newUrlRes;
    }

    const url = urlRes.url;
    const fileRes = await fetch(url);
    const json = await fileRes.json();
    const parsed = IpisFileV2.Schema.array().parse(json.files);
    return parsed;
  }

  async getFilesAsStaff(): Promise<IpisFileV2.Type[]> {
    let urlRes: PresignedUrlResponse;
    const cached = this.getFromCache({
      method: "get",
      workOrderId: "",
      as: "staff",
      type: "main",
    });

    if (cached) {
      urlRes = cached;
    } else {
      const newUrlRes =
        await trpcClient.workOrderFilePresignRouter.createHandymanOrStaffGetMultipleUrl.mutate(
          {}
        );
      this.saveInCache({
        method: "get",
        workOrderId: "",
        response: newUrlRes,
        as: "staff",
        type: "main",
      });
      urlRes = newUrlRes;
    }

    const url = urlRes.url;
    const fileRes = await fetch(url);
    const json = await fileRes.json();
    const parsed = IpisFileV2.Schema.array().parse(json.files);
    return parsed;
  }

  async getFilesAsCustomer(): Promise<IpisFileV2.Type[]> {
    const urlRes =
      await trpcClient.workOrderFilePresignRouter.createCustomerGetMultipleUrl.mutate();

    const url = urlRes.url;
    const fileRes = await fetch(url);
    const json = await fileRes.json();
    const parsed = IpisFileV2.Schema.array().parse(json.files);
    return parsed;
  }

  async getRelatedFilesAsHandyman(args: {
    workOrderId: string;
  }): Promise<IpisFileV2.Type[]> {
    let urlRes: PresignedUrlResponse | null;
    const cached = this.getFromCache({
      method: "get",
      workOrderId: args.workOrderId,
      as: "handyman",
      type: "related",
    });

    if (cached) {
      urlRes = cached;
    } else {
      const newUrlRes =
        await trpcClient.workOrderFilePresignRouter.createHandymanOrStaffGetLineItemFilesUrl.mutate(
          {
            workOrderId: args.workOrderId,
          }
        );
      if (newUrlRes !== null) {
        this.saveInCache({
          method: "get",
          workOrderId: args.workOrderId,
          response: newUrlRes,
          as: "handyman",
          type: "related",
        });
      }
      urlRes = newUrlRes;
    }

    if (urlRes === null) {
      return [];
    }

    const url = urlRes.url;
    const fileRes = await fetch(url);
    const json = await fileRes.json();
    const parsed = IpisFileV2.Schema.array().parse(json.files);
    return parsed;
  }

  async getRelatedFilesAsStaff(): Promise<IpisFileV2.Type[]> {
    let urlRes: PresignedUrlResponse | null;
    const cached = this.getFromCache({
      method: "get",
      workOrderId: "",
      as: "staff",
      type: "related",
    });

    if (cached) {
      urlRes = cached;
    } else {
      const newUrlRes =
        await trpcClient.workOrderFilePresignRouter.createHandymanOrStaffGetLineItemFilesUrl.mutate(
          {}
        );
      if (newUrlRes !== null) {
        this.saveInCache({
          method: "get",
          workOrderId: "",
          response: newUrlRes,
          as: "staff",
          type: "related",
        });
      }
      urlRes = newUrlRes;
    }

    if (urlRes === null) {
      return [];
    }

    const url = urlRes.url;
    const fileRes = await fetch(url);
    const json = await fileRes.json();
    const parsed = IpisFileV2.Schema.array().parse(json.files);
    return parsed;
  }

  async deleteFileAsCustomer(args: { file: IpisFileV2.Type }): Promise<void> {
    await trpcClient.workOrderFilePresignRouter.deleteFile.mutate({
      extension: args.file.extension,
      uuid: args.file.guid,
    });
  }

  async deleteFileAsHandyman(args: {
    file: IpisFileV2.Type;
    workOrderId: string;
  }): Promise<void> {
    await trpcClient.workOrderFilePresignRouter.deleteFile.mutate({
      extension: args.file.extension,
      uuid: args.file.guid,
      workOrderId: args.workOrderId,
    });
  }
}

function buildResponseWithAllTypesFailedReasons(): UploadFilesOutput.Type {
  const rejectedFiles = createInvalidInputFiles();
  const failesFiles = [
    {
      file: createMockFileInputInfo(6),
      error: "Det gick inte så bra!",
    },
    {
      file: createMockFileInputInfo(7),
      error: null,
    },
  ];

  return {
    recordId: "123",
    uploadedBy: "worker",
    status: "rejectedAndFailed",
    statusCode: 500,
    rejectedFiles,
    failedFiles: failesFiles,
    uploadedFiles: [],
  };
}

function createInvalidInputFiles(): InvalidInputFile.Type[] {
  return [
    {
      reason: "INVALID_DATA_URL",
      dataUrl: "data:text/plain;base64,SGVsbG8sIFdvcmxkIQ==",
      error: "Invalid data url",
      providedName: "file-1.pdf",
      providedDescription: "description-1",
    },
    {
      reason: "COULD_NOT_DETERMINE_FILE_TYPE",
      file: createMockFileInputInfo(1),
    },
    {
      reason: "INVALID_META_DATA",
      error: {} as never,
    },
    {
      reason: "DISALLOWED_FILE_TYPE",
      file: createMockFileInputInfo(5),
      supposedExtension: "pdf",
      supposedMimeType: "application/pdf",
      determinedExtension: "pdf",
      determinedMimeType: "application/pdf",
      allowedExtensions: ["pdf"],
    },
  ];
}

function createMockFileInputInfo(index: number): FileInputInfo.Type {
  return {
    id: `id-${index}`,
    extension: "pdf",
    mimeType: "application/pdf",
    originalExtension: "pdf",
    originalMimeType: "application/pdf",
    originalName: `file-${index}.pdf`,
    providedName: `file-${index}.pdf`,
    providedDescription: `description-${index}`,
    providedClientId: `client-${index}`,
    isCompressed: false,
    isConverted: false,
    size: 1000,
  };
}
