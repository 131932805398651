import { Checklist } from "@eljouren/domain";
import { IpisFileV2, UploadFilesOutput } from "@eljouren/file-schemas";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import InformationTooltip from "../../../../components/InformationTooltip";
import PlaceholderGridList from "../../../../components/PlaceholderGridList";
import FileInputButton, {
  CustomFileInputFile,
} from "../../../../components/files/FileInputButton";
import ProfileFormSingleInput from "../../../../components/forms/profile-form/ProfileFormSingleInput";
import AppXIcon from "../../../../components/icons/AppXIcon";
import { HandymanWorkOrderFileContext } from "../../../../components/work-order/files/DecoupledWorkOrderFileContextProvider";
import { useLoading } from "../../../../hooks/hooks";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../../hooks/use-media-query";
import useQueue from "../../../../hooks/use-queue";
import { useApiClients } from "../../../../hooks/use-api-clients";
import ClientUtils from "../../../../utils/ClientUtils";
import { ProcessTaskArgs } from "../../../../utils/process/ProcessTask";
import HandymanWorkOrderRouteContext from "../contexts/HandymanWorkOrderRouteContext";
import UploadResponseErrorMessageGenerator from "../../../../_model/helpers/UploadResponseErrorMessageGenerator";

type FormValues = {
  comment: string;
};

interface Props {
  item: Checklist.ListItemType;
  readOnly: boolean;
  className?: string;
}

const ChecklistImageItemUpload = (props: Props) => {
  const isMd = useMediaQuery(TailwindBreakpoint.md);
  const fileCtx = useContext(HandymanWorkOrderFileContext);
  const routeCtx = useContext(HandymanWorkOrderRouteContext);
  const loader = useLoading();
  const form = useForm<FormValues>({
    defaultValues: {
      comment: props.item.comment ?? "",
    },
  });
  const { workOrderRepo, workOrderFileRepo, handymanWorkOrderApiClient } =
    useApiClients();

  const uploadQueue = useQueue({
    id: "checklist-file-upload-queue",
    name: "Filuppladdning",
    onFinished: () => {
      fileCtx.workOrderFileRes.query.refetch();
      routeCtx.checklistRes.query.refetch();
    },
  });

  const maxSizeStr = "10mb";

  async function onFileChange(files: CustomFileInputFile[]) {
    try {
      const task: ProcessTaskArgs<any> = {
        name: props.item.name,
        func: async () => {
          return handymanWorkOrderApiClient.submitCompletionFormImageQuestionAnswer(
            {
              workOrderId: routeCtx.order.orderId,
              questionId: props.item.id,
              comment: props.item.comment ?? null,
              images: files,
            }
          );
        },
        onSuccess: (res: UploadFilesOutput.Type) => {
          if (res.status !== "fulfilled") {
            return {
              state: "failed",
              errorMessages: UploadResponseErrorMessageGenerator.generate(res),
              /* 
                Doesn't make sense to pass this back
              */
              result: res,
            };
          }
        },
      };

      uploadQueue.addTasks(task);
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att ladda upp bilden just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  /* 
    This still uses the old functionality.
    We haven't written the backend endpoint for updating the comment yet.
  */
  async function onUpdateComment(values: FormValues) {
    try {
      await routeCtx.checklistRes.mutate({
        callback: () => {
          return workOrderRepo.updateChecklistComment({
            workOrderId: routeCtx.order.orderId,
            checklistItemId: props.item.id,
            comment: values.comment,
          });
        },
        mutableOptimisticUpdate: (prevValue) => {
          if (prevValue) {
            const imageItems = prevValue.images.items;
            const updatedImageItems = imageItems.map((item) => {
              if (item.id === props.item.id) {
                return {
                  ...item,
                  comment: values.comment,
                };
              }
              return item;
            });
            prevValue.images.items = updatedImageItems;
          }
        },
      });
    } catch (error) {
      window.modal.alert({
        title: "Något gick fel",
        prompt:
          "Kunde inte uppdatera kommentaren. Vänligen försök igen senare.",
        typeOfAlert: "error",
      });
    }
  }

  const uploadedImages =
    fileCtx.workOrderImages?.filter((img) => {
      const tag = img.tag;
      if (!tag) {
        return false;
      }
      return tag.type === "checklistAnswer" && tag.reference === props.item.id;
    }) ?? [];

  async function onRemoveImage(img: IpisFileV2.Type) {
    if (uploadedImages.length <= 1) {
      window.modal.alert({
        title: "Du kan inte ta bort den sista bilden",
        prompt: "Du måste ha minst en bild kopplad till svaret",
        typeOfAlert: "error",
      });
      return;
    }

    const confirm = await window.modal.confirm({
      title: "Vill du ta bort bilden?",
      prompt: "Bilden kommer tas bort från detta uppdrag.",
      yesLabel: "Ta bort",
    });

    /* 
		We shouldn't allow deletion of images in the checklist this way,
		but since we're currently rewriting the fileserver client side validation
		will have to do for now

    Update: This now uses the refactored fileserver, and there should be backend validation
    put in place to validate that you can only delete the images if it isn't the last one.

    For now, I'll just assume that the handymen won't go through the trouble, and if this
    becomes a problem we'll have to add backend validation to prevent it.
	  */
    if (confirm) {
      await fileCtx.workOrderFileRes.mutate({
        callback: () => {
          return workOrderFileRepo.deleteFileAsHandyman({
            file: img,
            workOrderId: routeCtx.order.orderId,
          });
        },
      });
    }
  }

  const [isEditingComment, setIsEditingComment] = useState(false);
  const loading = fileCtx.workOrderFileRes.isLoading || loader.isLoading;
  const showComment = props.item.allowComment && props.item._isAnswered;
  const itemHeight = isMd ? 120 : 96;

  return (
    <section
      className={ClientUtils.twClassNames(
        "flex flex-col gap-2 p-2",
        loading && "pointer-events-none opacity-50"
      )}
      data-checklist-item-type={"image"}
      data-test-class="checklist-item"
    >
      <header className="flex flex-col border-b py-2">
        <h3 className="text-base font-semibold">
          {props.item.name + (props.item.required ? " *" : "")}
        </h3>
        <span className="flex items-center text-xs italic">{`Max ${maxSizeStr} per fil`}</span>
      </header>
      <main className="flex flex-col gap-8 py-6 md:px-2">
        {showComment && (
          <ProfileFormSingleInput
            useIcon
            label="Kommentar"
            buttonPosition="top-right"
            editLabel="Redigera"
            saveLabel="Spara"
            id={`checklist-item-${props.item.id}-comment`}
            editable={!routeCtx.order.isFinished}
            variant="isolated"
            form={form}
            path={"comment"}
            isEditing={isEditingComment}
            onSave={() => {
              setIsEditingComment(false);
              const values = form.getValues();
              onUpdateComment(values);
            }}
            htmlAttributes={{
              rows: 2,
            }}
            onEdit={() => setIsEditingComment(true)}
          />
        )}
        <section>
          <header className="flex items-center justify-between">
            <h3 className="mt-auto text-sm font-normal">Uppladdade bilder</h3>
            {!props.readOnly && (
              <FileInputButton
                id={`checklist-image-upload-${props.item.id}`}
                label="Välj bilder"
                onlyImages
                onChange={onFileChange}
                /* 
                Irrelevant since we're setting the name serverside in this case
              */
                maxFileNameLength={50}
              />
            )}
          </header>
          <main>
            <PlaceholderGridList
              id={`checklist-item-${props.item.id}-images`}
              items={uploadedImages}
              gap={16}
              passThroughProps={{}}
              padding={{
                top: 16,
                bottom: 8,
                right: 8,
              }}
              itemHeight={itemHeight}
              minItemWidth={125}
              RenderPlaceholder={(props) => {
                return (
                  <li
                    className="h-full w-full rounded border border-dashed border-black/30"
                    aria-hidden="true"
                    style={props.style}
                  />
                );
              }}
              RenderItem={(props) => {
                const img = props.item;
                return (
                  <li
                    data-test-class="uploaded-checklist-image"
                    key={img.guid}
                    className="relative w-full"
                    style={props.style}
                  >
                    {props.totalItemCount > 1 && (
                      <button
                        aria-label="Ta bort bild"
                        className={ClientUtils.twClassNames(
                          "absolute -right-2 -top-2 z-10 rounded-full bg-red-500 p-1 transition-colors hover:bg-red-300"
                        )}
                        onClick={() => onRemoveImage(img)}
                      >
                        <AppXIcon size={15} />
                      </button>
                    )}
                    {props.totalItemCount <= 1 && (
                      <InformationTooltip
                        open={"left"}
                        className={ClientUtils.twClassNames(
                          "absolute -right-2 -top-2 z-10 rounded-full border-0 bg-gray-500/60 p-1 transition-colors hover:bg-gray-500/80"
                        )}
                        content={() => {
                          return (
                            <p className="w-[125px] text-xs">
                              Ladda upp en ny bild för att kunna ta bort denna!
                            </p>
                          );
                        }}
                        iconWrapperClassName="opacity-80"
                        customIcon={() => {
                          return <AppXIcon size={15} />;
                        }}
                      />
                    )}
                    <img
                      src={img.src}
                      alt="Bild kopplad till svar på checklistan"
                      className="h-full w-full overflow-hidden rounded object-cover"
                    />
                  </li>
                );
              }}
            />
          </main>
        </section>
      </main>
    </section>
  );
};

export default ChecklistImageItemUpload;
