import { WorkOrderLineItem } from "@eljouren/domain";
import { UUID, Utils } from "@eljouren/utils";
import React, { useContext } from "react";
import WorkOrderReimbursementCalculator from "../../../../_model/helpers/WorkOrderReimbursementCalculator";
import useMutableQuery from "../../../../hooks/use-mutatable-query";
import useQueryWrapper from "../../../../hooks/use-query-wrapper";
import { useApiClients } from "../../../../hooks/use-api-clients";
import HandymanWorkOrderRouteContext from "../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import { HandymanWorkOrderFileContext } from "../../files/DecoupledWorkOrderFileContextProvider";
import HandymanLineItemContext, {
  FilteredReturn,
  QuickAddSubmitValues,
  WithApprovalStateReturn,
} from "./HandymanLineItemContext";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const HandymanLineItemContextProvider = (props: Props) => {
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const fileCtx = useContext(HandymanWorkOrderFileContext);
  const workOrder = ctx.order;
  const { workOrderRepo } = useApiClients();

  const canHandleOrder = workOrder.allowedToHandleOrder;

  const lineItemRes = useMutableQuery({
    enabled: canHandleOrder,
    queryKey: ["lineItems", workOrder.orderId],
    queryFn: () => {
      return workOrderRepo.fetchLineItems({
        workOrderId: workOrder.orderId,
      });
    },
  });

  const quickAddRes = useQueryWrapper({
    queryKey: ["quickAddLineItems", workOrder.orderId],
    queryFn: () => {
      return workOrderRepo.fetchAllQuickAddLineItems({
        workOrderId: workOrder.orderId,
      });
    },
  });

  function getMaterialQuickAddLineItems() {
    const { data } = quickAddRes;

    return data?.filter((el) => el.type === "material") ?? [];
  }

  function getWorkingHourQuickAddLineItems() {
    const { data } = quickAddRes;

    return data?.filter((el) => el.type === "workingHour") ?? [];
  }

  function getFilteredReturn(
    type: WorkOrderLineItem.HandymanType["type"]
  ): FilteredReturn {
    const { isLoading, isError, data } = lineItemRes.query;
    const lineItems = data?.filter((item) => item.type === type) ?? [];

    return {
      type,
      isLoading,
      isError,
      lineItems,
    };
  }

  async function updateLineItemQuantities(args: {
    lineItems: WorkOrderLineItem.HandymanType[];
  }) {
    try {
      const { lineItems } = args;
      const anyInvalidState = lineItems.some(
        (el) => !el.permissions.canEditQuantity
      );

      if (anyInvalidState) {
        window.modal.alert({
          title: "Det gick inte att ändra antal just nu.",
          prompt:
            "Minst en av orderraderna har ett ogiltigt tillstånd för att ändra antal.",
          typeOfAlert: "error",
        });
        return;
      }

      let filterPending = false;
      const anyPending = lineItems.some((el) => el.approvalState === "pending");
      const allPending = lineItems.every(
        (el) => el.approvalState === "pending"
      );

      if (anyPending) {
        if (allPending) {
          const lineItemDeclension =
            lineItems.length > 1 ? "orderraderna" : "orderraden";
          const res = await window.modal.confirm({
            title: `${Utils.capitalize(
              lineItemDeclension
            )} väntar på godkännande av kund`,

            prompt: `Är du säker på att du vill ändra antal på ${lineItemDeclension} som väntar på godkännande?`,
            yesLabel: "Ja, ändra ändå",
            noLabel: "Nej, avbryt",
          });
          filterPending = !res;
        } else {
          const res = await window.modal.confirm({
            title: "Minst en av orderraderna väntar på godkännande av kund",
            prompt:
              "Är du säker på att du vill ändra antal på orderraderna som väntar på godkännande?",
            yesLabel: "Ja, ändra ändå",
            noLabel: "Nej, skippa de som väntar på godkännande",
          });
          filterPending = !res;
        }
      }

      const filteredLineItems = filterPending
        ? lineItems.filter((el) => el.approvalState !== "pending")
        : lineItems;

      if (!filteredLineItems.length) {
        return;
      }

      await lineItemRes.mutate({
        callback: () =>
          workOrderRepo.editLineItemQuantities({
            workOrderId: workOrder.orderId,
            lineItems: filteredLineItems,
          }),
      });
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att ändra antal just nu.",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  async function deleteOrInactivateLineItem(
    entry: WorkOrderLineItem.HandymanType
  ) {
    try {
      if (!entry.permissions.canBeCanceled && !entry.permissions.canBeRemoved) {
        throw new Error("This material cannot be removed or canceled.");
      }

      let optimisticUpdate: WorkOrderLineItem.HandymanType[] | undefined;

      if (entry.permissions.canBeCanceled) {
        optimisticUpdate = lineItemRes.query.data
          ? lineItemRes.query.data.map((el) => {
              if (el.id !== entry.id) {
                return el;
              }

              return {
                ...el,
                isInactive: true,
              };
            })
          : undefined;
      } else if (entry.permissions.canBeRemoved) {
        optimisticUpdate = lineItemRes.query.data
          ? lineItemRes.query.data.filter((el) => el.id !== entry.id)
          : undefined;
      }

      if (entry.approvalState === "pending") {
        const value = await window.modal.confirm({
          title: "Material väntar på godkännande av kund",
          prompt: "Är du säker på att du vill ta bort detta material?",
        });
        if (!value) {
          return;
        }
      }

      if (
        entry.approvalState === "approved" &&
        entry.permissions.canBeCanceled &&
        !entry.permissions.canBeRemoved
      ) {
        const value = await window.modal.confirm({
          title: "Materialet har redan godkänts av kund",
          prompt: "Är du säker på att du vill inaktivera detta material?",
        });
        if (!value) {
          return;
        }
      }

      await lineItemRes.mutateDebounced({
        mutationKey: "removeLineItems",
        args: entry.id,
        callback: (ids) => {
          return workOrderRepo.removeLineItems({
            entryIds: ids,
            workOrderId: workOrder.orderId,
          });
        },
        options: {
          optimisticUpdate,
          onSettled: async () => {
            /*
                  It would be better if this happens automatically when material are added, but the related files
                  are in a higher context than the materials. 
            */
            fileCtx.lineItemFileRes.refetch();
          },
          onError: (er) => {
            window.modal.alert({
              title: "Det gick inte att ta bort material just nu.",
              prompt: "Vänligen försök igen senare.",
              typeOfAlert: "error",
              error: er,
            });
          },
        },
        debounceTime: 2000,
      });
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att ta bort material just nu.",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  async function onQuickAddProduct(args: QuickAddSubmitValues) {
    const entry: WorkOrderLineItem.NewEntryType = {
      productId: args.lineItem.id,
      quantity: args.quantity,
      workOrderId: workOrder.orderId,
    };

    try {
      await lineItemRes.mutate({
        callback: () => {
          return workOrderRepo.reportMaterial(entry);
        },
        mutableOptimisticUpdate: (prev) => {
          const optimisticLineItem = createOptimisticLineItem({
            unit: args.lineItem.type === "material" ? "st" : "h",
            type: args.lineItem.type,
            productId: args.lineItem.id,
            name: args.lineItem.name,
            quantity: args.quantity,
            unitPrice: args.lineItem.unitPrice,
          });

          const prevProduct = prev?.find((el) => {
            const sameId = el.productId === args.lineItem.id;
            return sameId && el.permissions.canEditQuantity;
          });
          if (prevProduct) {
            prevProduct.quantity += args.quantity;
          } else if (prev === undefined) {
            prev = [optimisticLineItem];
          } else {
            prev.push(optimisticLineItem);
          }
        },
      });
      /*
            (Previously: It would be better if this happens automatically when material are added, but the related files
            are in a higher context than the materials.)
  
            ToDo: 
            * These are now the same context, which means we could make this happen automatically.
        */
      fileCtx.lineItemFileRes.refetch();
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att lägga till material just nu.",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  function createOptimisticLineItem(args: {
    unit: WorkOrderLineItem.HandymanType["unit"];
    type: WorkOrderLineItem.HandymanType["type"];
    productId: string;
    name: string;
    quantity: number;
    unitPrice: number | undefined;
  }): WorkOrderLineItem.HandymanType {
    const optimisticLineItem: WorkOrderLineItem.HandymanType = {
      id: UUID.generate().value,
      isCustom: false,
      addedByHandyman: true,
      createdDate: new Date(),
      approvalState: "notApproved",
      lastModifiedDate: new Date(),
      linkedMaterialApprovalImages: [],
      permissions: {
        canBeCanceled: false,
        canBeRemoved: true,
        canEditQuantity: true,
      },
      compensationDetails: {
        showCompensation: args.unitPrice !== undefined,
        compensationPerUnit: args.unitPrice ?? 0,
      } as WorkOrderLineItem.HandymanType["compensationDetails"],
      customerPriceDetails: {
        hasCustomerPrice: false,
      },
      createdFor: "handyman",
      isInactive: false,
      ...args,
    };

    return optimisticLineItem;
  }

  const calculator = new WorkOrderReimbursementCalculator({
    hoursData: getFilteredReturn("workingHour").lineItems,
    materialData: getFilteredReturn("material").lineItems,
  });

  function getWithApprovalState<
    T extends WorkOrderLineItem.HandymanType["approvalState"]
  >(...args: T[]): WithApprovalStateReturn<T> {
    const { data } = lineItemRes.query;
    const filtered =
      data?.filter((el) => {
        return args.includes(el.approvalState as T);
      }) ?? [];

    return {
      exists: !!filtered.length,
      items: filtered,
      count: filtered.length,
      state: args,
    };
  }

  return (
    <HandymanLineItemContext.Provider
      value={{
        lineItemRes,
        quickAddRes,
        getMaterialQuickAddLineItems,
        getWorkingHourQuickAddLineItems,
        getMaterialLineItems: () => getFilteredReturn("material"),
        getWorkingHourLineItems: () => getFilteredReturn("workingHour"),
        updateLineItemQuantities,
        deleteOrInactivateLineItem,
        onQuickAddProduct,
        calculator,
        getWithApprovalState,
      }}
    >
      {props.children}
    </HandymanLineItemContext.Provider>
  );
};

export default HandymanLineItemContextProvider;
