import {
  IpisFormElement,
  FormElementWithState,
  IpisFormPage,
} from "@eljouren/domain";
import { IpisFormAnswerValidator } from "@eljouren/domain-utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import { Reorder, motion, useDragControls } from "framer-motion";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useFormEditor } from "../../../../../hooks/checklist-hooks";
import { useContextMenu } from "../../../../../hooks/use-context-menu";
import ClientUtils from "../../../../../utils/ClientUtils";
import AppContextMenu from "../../../../__v2__/menu/AppContextMenu";
import AppMenuIcon from "../../../../icons/AppMenuIcon";
import AppMoveIcon from "../../../../icons/AppMoveIcon";
import ChecklistPageContext from "../../checklist-page/ChecklistPageContext";
import ChecklistPageFooter from "../../checklist-page/ChecklistPageFooter";
import ChecklistPageHeader from "../../checklist-page/ChecklistPageHeader";
import ChecklistQuestionRouter from "../../checklist-page/ChecklistQuestionRouter";
import { FormEditorContext } from "../layout/editor/FormEditorContextProvider";
import ConditionalOverlay from "./ConditionalOverlay";

interface Props {
  className?: string;
  page: IpisFormPage.WithoutAnswersType;
  isFinalPage: boolean;
  proceed: (values: Record<string, any>) => void;
  goBack: () => void;
  isFirstPage: boolean;
  schema: z.ZodObject<any>;
  otherPageValues: Record<string, any>;
}

const EditableFormPageComponent = (props: Props) => {
  const form = useForm({
    defaultValues: props.otherPageValues,
    resolver: zodResolver(props.schema),
  });

  const editorCtx = useFormEditor();
  const page = props.page;
  //const [page, setPage] = useState(props.page);
  function reorderQuestions(questions: IpisFormElement.Type[]) {
    editorCtx.controls.reorderElements({
      elementIdsInNewOrder: questions.map((q) => q.id),
    });
  }

  function onSubmit(values: any) {
    props.proceed(values);
  }

  function onError(errors: any) {
    console.log({
      errors,
    });
  }

  const validatedChecklist = new IpisFormAnswerValidator({
    form: editorCtx.form,
    currentFormValues: form.watch(),
  });

  function onRenameChecklistPage(newTitle: string) {
    editorCtx.controls.renameFormPage({
      pageId: page.id,
      newTitle,
    });
  }

  function onDescriptionChange(newDescription: string) {
    editorCtx.controls.renameFormPage({
      pageId: page.id,
      newDescription,
    });
  }

  function onPreparationsChange(newPreparations: string) {
    editorCtx.controls.renameFormPage({
      pageId: page.id,
      newPreparations,
    });
  }

  function onRenameShorthand(newShorthand: string) {
    editorCtx.controls.renameFormPage({
      pageId: page.id,
      newShorthand,
    });
  }

  return (
    <ChecklistPageContext.Provider value={{ form, validatedChecklist }}>
      <motion.form
        className={ClientUtils.classNames(
          "flex flex-col gap-8 px-2 py-4 xs:px-4",
          props.className
        )}
        onSubmit={form.handleSubmit(onSubmit, onError)}
      >
        <ChecklistPageHeader
          page={page}
          descriptionPlaceholder="Dubbelklicka för att lägga till en beskrivning..."
          preparationsPlaceholder="Dubbelklicka för att lägga till en liten text som visas för användaren i förhandsvisningen..."
          onRenameShorthand={(newShorthand) => {
            onRenameShorthand(newShorthand);
          }}
          onRename={(newName) => {
            onRenameChecklistPage(newName);
          }}
          onDescriptionChange={(newDescription) => {
            onDescriptionChange(newDescription);
          }}
          onPreparationsChange={(newDescription) => {
            onPreparationsChange(newDescription);
          }}
        />
        <Reorder.Group
          axis="y"
          values={page.elements}
          onReorder={(questions) => reorderQuestions(questions)}
          className="flex flex-col gap-8"
          layoutScroll
        >
          {page.elements.map((question) => {
            return (
              <ReorderListItem
                page={page}
                key={question.id}
                element={question}
              />
            );
          })}
        </Reorder.Group>
        <ChecklistPageFooter {...props} hideSubmitButton />
      </motion.form>
    </ChecklistPageContext.Provider>
  );
};

const ReorderListItem = (props: {
  element: IpisFormElement.Type;
  page: IpisFormPage.WithoutAnswersType;
}) => {
  const controls = useDragControls();

  const menu = useContextMenu({
    preventCloseOnLeftClick: true,
    openTo: "left",
  });
  const editor = useContext(FormEditorContext);
  const ctx = useFormEditor();
  const pageCtx = useContext(ChecklistPageContext);
  const validatedChecklist = pageCtx.validatedChecklist;

  /* if (1 < 2) {
    return <div>test</div>;
  } */
  let withState: FormElementWithState.MaybeType;
  let meetsConditions = false;
  try {
    withState = validatedChecklist.getElement(props.element.id);
    meetsConditions = !!withState.state?.meetsConditions;
  } catch (er) {
    /* 
      Alright, this is a bit of a hack and I'm sure there might be a better way to do this. The problem arises
      from a question from the previous checklist being rendered even after the checklist has changed. I think this is 
      because of the page animations, that keeps the old page from the previous checklist in the DOM until the animation is done.

      This makes is to that we look for a question in the new checklist that only exists in the old checklist.

      If I pass down the checklist via props instead, this would probably not be needed. For now, I'll just leave it like this.
    */
    return <></>;
  }

  function removeQuestion() {
    ctx.controls.removeQuestion({
      questionId: props.element.id,
    });
  }

  function moveQuestionToAnotherPage(pageId: string) {
    ctx.controls.moveQuestionToAnotherPage({
      questionId: props.element.id,
      targetPageId: pageId,
    });
  }

  return (
    <Reorder.Item
      key={props.element.id}
      value={props.element}
      dragControls={controls}
      className={ClientUtils.classNames(
        "flex flex-col gap-2",
        !meetsConditions && "opacity-70"
      )}
      onContextMenu={menu.listener}
    >
      <header className="flex items-center justify-end gap-2">
        {/* <span>{props.element.id}</span> */}
        <button
          className="resize-x"
          type="button"
          onClick={(e) => {
            controls.start(e);
          }}
        >
          <AppMoveIcon size={"lg"} direction="vertical" />
        </button>
        <button className="menu" type="button" onClick={menu.listener}>
          <AppMenuIcon size="lg" />
        </button>
      </header>
      <div className="relative">
        {!meetsConditions && <ConditionalOverlay question={props.element} />}
        <ChecklistQuestionRouter question={props.element} />
      </div>
      <AppContextMenu
        id={props.element.id + "-context-menu"}
        variant="light"
        handleSelectedStateInternally
        menu={{
          items: [
            {
              id: "edit",
              type: "button",
              label: "Redigera fråga",
              onClick: () => {
                editor.setShowEditQuestionForm(props.element);
              },
            },
            {
              id: "add-condition",
              type: "button",
              label: "Lägg till villkor",
              onClick: () => {
                editor.setShowConditionalLogicForm(props.element);
              },
            },
            {
              id: "move",
              type: "button",
              label: "Flytta fråga",
              nestedMenu: {
                items: ctx.form.pages
                  .filter((p) => p.id !== props.page.id)
                  .map((p) => {
                    /* console.log(p.pageTitleShorthand); */
                    return {
                      id: p.id,
                      type: "button",
                      label: p.pageTitleShorthand,
                      onClick: () => {
                        moveQuestionToAnotherPage(p.id);
                      },
                    };
                  }),
              },
            },

            {
              id: "remove",
              type: "button",
              label: "Ta bort fråga",
              onClick: () => {
                removeQuestion();
              },
            },
          ],
        }}
        ctxMenu={menu}
      />
    </Reorder.Item>
  );
};

export default EditableFormPageComponent;
