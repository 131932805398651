import { WorkOrder } from "@eljouren/domain/build";
import { IpisFileV2 } from "@eljouren/file-schemas/build";
import { createContext, useContext } from "react";
import useMutableQuery, {
  UseMutableQueryResult,
} from "../../../../hooks/use-mutatable-query";
import { useApiClients } from "../../../../hooks/use-api-clients";
import { GlobalContext } from "../../../../top-level-contexts";

export const HandymanWorkOrderScaledDownFileContext = createContext<{
  workOrderFileRes: UseMutableQueryResult<IpisFileV2.Type[]>;
}>({} as never);

interface Props {
  order: WorkOrder.HandymanType;
  children?: React.ReactNode;
}

const HandymanWorkOrderScaledDownFileContextProvider = (props: Props) => {
  const { workOrderFileRepo } = useApiClients();
  const { signInState } = useContext(GlobalContext);
  const workOrderFileRes = useMutableQuery({
    queryKey: ["workOrderFiles", props.order.orderId, signInState.signedInAs],
    queryFn: () =>
      workOrderFileRepo.getFilesAsHandyman({
        workOrderId: props.order.orderId,
      }),
  });

  return (
    <HandymanWorkOrderScaledDownFileContext.Provider
      value={{
        workOrderFileRes,
      }}
    >
      {props.children}
    </HandymanWorkOrderScaledDownFileContext.Provider>
  );
};

export default HandymanWorkOrderScaledDownFileContextProvider;
