"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormElementAnswer = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var file_schemas_1 = require("@eljouren/file-schemas");
var FormElementAnswer;
(function (FormElementAnswer) {
    FormElementAnswer.YesNoFormAnswerSchema = function (q) {
        var value = centralized_zod_1.z.boolean();
        if (!q || !q.required) {
            value = value.optional();
        }
        return centralized_zod_1.z.object({
            value: value,
        });
    };
    FormElementAnswer.NumberFormAnswer = function (q) {
        var valueSchema = centralized_zod_1.z.number();
        if ((q === null || q === void 0 ? void 0 : q.min) !== undefined) {
            valueSchema = valueSchema.min(q.min);
        }
        if ((q === null || q === void 0 ? void 0 : q.max) !== undefined) {
            valueSchema = valueSchema.max(q.max);
        }
        if (!(q === null || q === void 0 ? void 0 : q.required)) {
            valueSchema = valueSchema.optional();
        }
        return centralized_zod_1.z.object({
            value: valueSchema,
        });
    };
    FormElementAnswer.TextFormAnswer = function (q) {
        if ((q === null || q === void 0 ? void 0 : q.typeOfQuestion) === "rich-text") {
            if (q.required) {
                return centralized_zod_1.z.object({
                    value: centralized_zod_1.z.string().min(1),
                });
            }
            else {
                return centralized_zod_1.z.object({
                    value: centralized_zod_1.z.string().optional(),
                });
            }
        }
        var valueSchema = centralized_zod_1.z.string();
        if ((q === null || q === void 0 ? void 0 : q.minLength) !== undefined) {
            valueSchema = valueSchema.min(q.minLength);
        }
        else if ((q === null || q === void 0 ? void 0 : q.required) === true) {
            valueSchema = valueSchema.min(1);
        }
        if ((q === null || q === void 0 ? void 0 : q.maxLength) !== undefined) {
            valueSchema = valueSchema.max(q.maxLength);
        }
        if (!(q === null || q === void 0 ? void 0 : q.required)) {
            valueSchema = valueSchema.optional();
            return centralized_zod_1.z.object({
                value: centralized_zod_1.z.union([centralized_zod_1.z.literal(""), valueSchema]),
            });
        }
        return centralized_zod_1.z.object({
            value: valueSchema,
        });
    };
    FormElementAnswer.RichTextFormAnswer = function () {
        return centralized_zod_1.z.object({
            value: centralized_zod_1.z.string().describe("Html"),
        });
    };
    FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION = "OTHER";
    FormElementAnswer.MultipleChoiceFormAnswer = function (q) {
        if (!q) {
            return centralized_zod_1.z.object({
                value: centralized_zod_1.z.union([centralized_zod_1.z.string(), centralized_zod_1.z.string().array()]).optional(),
                specification: centralized_zod_1.z.string().optional(),
            });
        }
        else {
            var values_1 = q.options.map(function (option) { return option.value; });
            if (q.multiple) {
                var value = centralized_zod_1.z
                    .string()
                    .array()
                    .nonempty()
                    .refine(function (value) {
                    return value.every(function (v) {
                        return (values_1.includes(v) ||
                            (q.allowOther && v === FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION));
                    });
                });
                if (!q.required) {
                    value = value.optional();
                }
                return centralized_zod_1.z.object({
                    value: value,
                    specification: centralized_zod_1.z.string().optional(),
                });
            }
            else {
                var value = centralized_zod_1.z
                    .string()
                    .refine(function (value) {
                    return (values_1.includes(value) ||
                        (q.allowOther && value === FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION));
                });
                if (!q.required) {
                    value = value.optional();
                }
                return centralized_zod_1.z.object({
                    value: value,
                    specification: centralized_zod_1.z.string().optional(),
                });
            }
        }
    };
    /*
      Should preferably create the schema based on the question,
      but this becomes a bit more complex and for now we will just
      be happy with the generic schema
    */
    FormElementAnswer.RepeaterQuestionFormAnswer = function () {
        return centralized_zod_1.z.object({
            repeaterItems: centralized_zod_1.z.record(
            // Group ID
            centralized_zod_1.z.string(), centralized_zod_1.z.record(
            // Repeater Item ID
            centralized_zod_1.z.string(), centralized_zod_1.z.union([
                FormElementAnswer.YesNoFormAnswerSchema(),
                FormElementAnswer.NumberFormAnswer(),
                FormElementAnswer.TextFormAnswer(),
                FormElementAnswer.MultipleChoiceFormAnswer(),
                centralized_zod_1.z.object({
                    value: centralized_zod_1.z.union([centralized_zod_1.z.literal(""), centralized_zod_1.z.literal(undefined)]),
                }),
            ]))),
        });
    };
    FormElementAnswer.PreUploadImageSchema = centralized_zod_1.z.object({
        state: centralized_zod_1.z.literal("preupload"),
        native: centralized_zod_1.z
            .any()
            .refine(function (v) { return v instanceof File; })
            .transform(function (v) { return v; }),
        guid: centralized_zod_1.z.string(),
        dataUrl: centralized_zod_1.z.string(),
        meta: centralized_zod_1.z.object({
            name: centralized_zod_1.z.string().optional(),
        }),
    });
    FormElementAnswer.PostUploadImageSchema = file_schemas_1.IpisFileV2.Schema.extend({
        state: centralized_zod_1.z.literal("postupload"),
        /* guid: z.string(),
        name: z.string(),
        extension: z.string(),
        src: z.string(), */
    });
    FormElementAnswer.ImageGroupFormAnswer = function (q) {
        if (!q) {
            return centralized_zod_1.z.object({
                images: centralized_zod_1.z.record(centralized_zod_1.z.string(), centralized_zod_1.z
                    .union([FormElementAnswer.PreUploadImageSchema, FormElementAnswer.PostUploadImageSchema])
                    .array()
                    .optional()),
                imageUploadComment: centralized_zod_1.z.string().optional(),
            });
        }
        var images = centralized_zod_1.z.object({});
        q.imagePrompts.forEach(function (prompt) {
            var _a, _b;
            if (prompt.required) {
                images = images.extend((_a = {},
                    _a[prompt.id] = centralized_zod_1.z
                        .union([FormElementAnswer.PreUploadImageSchema, FormElementAnswer.PostUploadImageSchema])
                        .array()
                        .min(1),
                    _a));
            }
            else {
                images = images.extend((_b = {},
                    _b[prompt.id] = centralized_zod_1.z
                        .union([FormElementAnswer.PreUploadImageSchema, FormElementAnswer.PostUploadImageSchema])
                        .array()
                        .optional(),
                    _b));
            }
        });
        return centralized_zod_1.z.object({
            images: images,
            imageUploadComment: centralized_zod_1.z.string().optional(),
        });
    };
    FormElementAnswer.Schema = centralized_zod_1.z.union([
        FormElementAnswer.YesNoFormAnswerSchema(),
        FormElementAnswer.NumberFormAnswer(),
        FormElementAnswer.TextFormAnswer(),
        FormElementAnswer.MultipleChoiceFormAnswer(),
        FormElementAnswer.ImageGroupFormAnswer(),
        FormElementAnswer.RepeaterQuestionFormAnswer(),
    ]);
    FormElementAnswer.WithApiSupportSchema = centralized_zod_1.z.union([
        FormElementAnswer.YesNoFormAnswerSchema(),
        FormElementAnswer.NumberFormAnswer(),
        FormElementAnswer.TextFormAnswer(),
        FormElementAnswer.MultipleChoiceFormAnswer(),
        FormElementAnswer.ImageGroupFormAnswer(),
    ]);
    function getAnswerSchemaFromQuestion(q) {
        switch (q.typeOfQuestion) {
            case "multiple-choice":
                return FormElementAnswer.MultipleChoiceFormAnswer(q);
            case "text":
            case "textarea":
            case "rich-text":
                return FormElementAnswer.TextFormAnswer(q);
            case "yes/no":
                return FormElementAnswer.YesNoFormAnswerSchema();
            case "number":
                return FormElementAnswer.NumberFormAnswer(q);
            case "image-group":
                return FormElementAnswer.ImageGroupFormAnswer(q);
            case "repeater":
                return FormElementAnswer.RepeaterQuestionFormAnswer();
        }
        throw new Error("Unknown question type");
    }
    FormElementAnswer.getAnswerSchemaFromQuestion = getAnswerSchemaFromQuestion;
})(FormElementAnswer || (FormElementAnswer = {}));
exports.FormElementAnswer = FormElementAnswer;
