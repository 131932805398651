import { useEffect, useRef } from "react";
import useQueryWrapper from "../../../hooks/use-query-wrapper";
import { useApiClients } from "../../../hooks/use-api-clients";
import { AppLoader } from "../../common/loaders/AppLoader";

interface Props {
  className?: string;
  sessionId: string;
}

function BankIdQRCode(props: Props) {
  const { bankIdRepo } = useApiClients();
  const qrCodeRes = useQueryWrapper({
    queryKey: ["bankIdQRCode", props.sessionId],
    queryFn: () => {
      return bankIdRepo.getQrCode();
    },
    keepPreviousData: true,
  });

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const { data, isError, refetch } = qrCodeRes;
  useEffect(() => {
    const clear = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };

    clear();
    if (data && !isError) {
      intervalRef.current = setInterval(() => {
        refetch();
      }, 1000);
    }
    return clear;
  }, [data, isError, refetch]);

  if (qrCodeRes.isLoading) {
    return <AppLoader className="mx-auto my-4 h-6 w-6" />;
  }
  if (!qrCodeRes.data || qrCodeRes.isError) {
    return (
      <span className="text-red-600">
        Det gick inte att visa QR-koden just nu
      </span>
    );
  }

  return (
    <img
      src={qrCodeRes.data}
      className="h-60 w-60 rounded border"
      alt="BankID QR-kod"
    />
  );
}

export default BankIdQRCode;
