import ClientUtils from "./../../../../utils/ClientUtils";
import { useContext } from "react";
import SearchFieldSlideDown from "../../../../components/common/search/SearchFieldSlideDown";
import HandymanWorkOrderListItem from "../../../../components/work-order/handyman/HandymanWorkOrderListItem";
import HandymanWorkOrderListSection from "../../../../components/work-order/handyman/HandymanWorkOrderListSection";
import { useApiClients } from "../../../../hooks/use-api-clients";

import { HandymanLandingPageContext } from "../HandymanRoute";

interface Props {
  className?: string;
}

const HandymanDashboard = (props: Props) => {
  const ctx = useContext(HandymanLandingPageContext);
  const { workOrderRepo } = useApiClients();
  return (
    <main
      className={ClientUtils.twClassNames(
        "flex h-full flex-col gap-4",
        props.className
      )}
    >
      <section className={ClientUtils.twClassNames("relative flex text-lg")}>
        {/* 
          ToDo: Needs accessible label
        */}
        <SearchFieldSlideDown
          className="w-full"
          inputWrapperClassName="py-2 shadow-inner shadow-black/50"
          placeholder="Sök på arbetsordrar"
          fetch={(query: string) =>
            workOrderRepo.searchForOrders({
              query,
              handymanId: ctx.handyman.id,
            })
          }
          id="orders"
          Render={(props) => {
            if (!props.data.length) {
              return <p className="p-2">Inga arbetsordrar hittades</p>;
            }

            return (
              <ul className="flex flex-col gap-2">
                {props.data.map((order) => (
                  <HandymanWorkOrderListItem
                    className="rounded"
                    key={order.orderId}
                    order={order}
                  />
                ))}
              </ul>
            );
          }}
        />
      </section>
      <HandymanWorkOrderListSection onlyForToday />
    </main>
  );
};

export default HandymanDashboard;
