import { WorkOrder, WorkOrderFinishedReport } from "@eljouren/domain";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnimatePresence, motion } from "framer-motion";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import GoBackButton from "../../../components/GoBackButton";
import { AppButton } from "../../../components/common/buttons/AppButton";
import { AppFormTextArea } from "../../../components/common/text-areas/AppFormTextArea";
import { AppFormTextField } from "../../../components/common/text-fields/AppFormTextField";
import ControlledDatePicker from "../../../components/common/time/ControlledDatePicker";
import { useApiClients } from "../../../hooks/use-api-clients";
import ClientUtils from "./../../../utils/ClientUtils";
import HandymanWorkOrderRouteContext from "./contexts/HandymanWorkOrderRouteContext";

interface Props {
  className?: string;
  onClose(): any;
}

const HandymanWorkOrderMarkAsFinishedModal = (props: Props) => {
  const { orderRes, order } = useContext(HandymanWorkOrderRouteContext);
  const form = useForm<WorkOrderFinishedReport.Type>({
    mode: "onChange",
    defaultValues: {
      workOrderId: order.orderId,
      rebooking: "no",
    },
    resolver: zodResolver(WorkOrderFinishedReport.Schema),
  });
  const { workOrderRepo } = useApiClients();

  form.watch();

  async function onSubmit(values: WorkOrderFinishedReport.Type) {
    try {
      props.onClose();

      let optimisticUpdate: WorkOrder.HandymanWithPermissionsType | undefined;
      /* 
        If we are in development mode, we don't want to optimistically update
        the work order. This is because we want to be able to test that the 
        work order finished indicator is only shown when the work order is
        actually finished.
      */
      if (process.env.NODE_ENV !== "development") {
        optimisticUpdate = {
          ...order,
          isFinished: true,
        };
      }

      await orderRes.mutate({
        callback: () =>
          workOrderRepo.reportAsFinished({
            ...values,
          }),
        optimisticUpdate,
      });
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte klarmarkera arbetet just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={ClientUtils.twClassNames(
        props.className,
        "flex h-full w-full flex-col bg-white/90 p-4 backdrop-blur md:pt-32"
      )}
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mx-auto flex h-full w-full max-w-screen-lg flex-col gap-2 overflow-auto"
      >
        <header className="flex w-full justify-between gap-2 py-2">
          <h2 className="text-xl">Klarmarkera arbetet</h2>
        </header>
        <main className="flex grow flex-col gap-4 overflow-auto p-2">
          <fieldset className="flex flex-col gap-2">
            <h3 className="text-base font-semibold">
              Behövs det bokas ett återkommande arbete?
            </h3>
            <p className="flex items-center gap-2">
              <input
                className="cursor-pointer p-1"
                type="radio"
                id="rebookingFalse"
                value={"no"}
                {...form.register("rebooking")}
              />
              <label
                htmlFor="rebookingFalse"
                className="max-w-full cursor-pointer p-1"
              >
                Nej
              </label>
            </p>
            <p className="flex items-center gap-2">
              <input
                className="cursor-pointer p-1"
                type="radio"
                id="rebookingTrue"
                value={"yes"}
                {...form.register("rebooking")}
              />
              <label
                htmlFor="rebookingTrue"
                className="max-w-full cursor-pointer p-1"
              >
                Ja
              </label>
            </p>
          </fieldset>

          <AnimatePresence>
            {form.getValues("rebooking") === "yes" && (
              <motion.fieldset
                className="flex w-full flex-col gap-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <AppFormTextArea
                  register={form.register}
                  boldLabel
                  name="description"
                  label="Vad ska utföras på arbetet?"
                  htmlAttributes={{
                    maxLength: 255,
                    minLength: 1,
                  }}
                />
                <AppFormTextField
                  register={form.register}
                  name="timeEstimateInHours"
                  label="Uppskattad tidsåtgång i timmar"
                  htmlAttributes={{
                    step: 0.5,
                    min: 0.5,
                    type: "number",
                  }}
                />
                <p className="flex items-center gap-2">
                  <input
                    id="selfBooking"
                    type="checkbox"
                    className="h-4 w-4 cursor-pointer"
                    {...form.register("newDateBooked")}
                  ></input>
                  <label htmlFor="selfBooking" className="cursor-pointer">
                    Jag har bokat in ett nytt datum
                  </label>
                </p>

                <p
                  className={ClientUtils.twClassNames(
                    "flex flex-col gap-2",
                    !form.getValues("newDateBooked") &&
                      "pointer-events-none opacity-50"
                  )}
                >
                  <label htmlFor="date">Datum och tid</label>
                  <ControlledDatePicker
                    id="date"
                    control={form.control}
                    name="date"
                    type="datetime-local"
                  />
                </p>
              </motion.fieldset>
            )}
          </AnimatePresence>
        </main>
        <footer className="flex flex-col items-end gap-2">
          <AppButton
            requiresNetworkConnection
            type="submit"
            contentClassName="w-72"
            disabled={!form.formState.isValid}
          >
            Bekräfta klarmarkering
          </AppButton>
          <GoBackButton
            onClick={props.onClose}
            appButtonProps={{
              className: "w-72",
            }}
          />
        </footer>
      </form>
    </motion.section>
  );
};

export default HandymanWorkOrderMarkAsFinishedModal;
