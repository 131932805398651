import {
  Handyman,
  HandymanProfession,
  HandymanProvince,
  HandymanWorkingHours,
} from "@eljouren/domain/build";
import { z } from "@ipis/centralized-zod";

export const WorkerAssociationSchema = z.enum([
  "vvs",
  "spol",
  "el",
  "låssmed",
  "läckage",
  "snickare",
  "fixare",
]);
export type TWorkerAssociation = z.infer<typeof WorkerAssociationSchema>;
export default interface IHandymanRepo {
  fetchHandyman(id: string): Promise<Handyman.Type>;
  fetchByProfession(
    profession: HandymanProfession.Type
  ): Promise<Handyman.Type[]>;
  updateProfileInfo(worker: Handyman.Type): Promise<boolean>;
  fetchProvincesWithCounties(): Promise<HandymanProvince.Type[]>;

  fetchWorkingHours(args: {
    handymanId: string;
    interval: {
      start: Date;
      end: Date;
    };
  }): Promise<HandymanWorkingHours.DictIndexedByStartOfDayType>;
  saveWorkingHours(args: {
    handymanId: string;
    workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType;
  }): Promise<void>;
}
