import { WorkOrder } from "@eljouren/domain/build";
import { useCallback, useContext, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { TWorkerSignInData } from "../../../_model/schemas-and-types/repo-schemas";
import withHandymanCredentials, {
  SignedInContext,
} from "../../../components/auth/hocs/withWorkerCredentials";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import { useManipulateBackButtonStack } from "../../../components/common/navigation/use-back-button-stack";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import DecoupledWorkOrderFileContextProvider from "../../../components/work-order/files/DecoupledWorkOrderFileContextProvider";
import HandymanLineItemContextProvider from "../../../components/work-order/handyman/line-items/HandymanLineItemContextProvider";
import useMutableQuery from "../../../hooks/use-mutatable-query";
import { useApiClients } from "../../../hooks/use-api-clients";
import { useCustomSearchParamStateNoHistory } from "../../../hooks/use-search-params";
import HandymanWorkOrderPage from "./HandymanWorkOrderPage";
import HandymanWorkOrderCheckInOutContextProvider from "./contexts/HandymanWorkOrderCheckInOutContextProvider";
import HandymanWorkOrderRouteContext, {
  WorkOrderControlModule,
  WorkOrderTabs,
  WorkOrderView,
} from "./contexts/HandymanWorkOrderRouteContext";
import { useQueryWrapper } from "@ipis/client-essentials";

interface Props extends TWorkerSignInData {}

const HandymanWorkOrderRoute = (props: Props) => {
  const { workerGuid } = useParams();
  const { workOrderRepo, ipisFormRepo, workOrderFileRepo } = useApiClients();
  const { handyman } = useContext(SignedInContext);
  const queryClient = useQueryClient();
  const { handle } = useManipulateBackButtonStack();

  const defaultView: WorkOrderView = {
    tab: WorkOrderTabs.overview,
  };
  const [view, _setView] = useCustomSearchParamStateNoHistory<WorkOrderView>(
    "wos",
    defaultView,
    {
      toString: (value, prevValue) => {
        const index = Object.values(WorkOrderTabs).indexOf(value.tab);
        const module = value.module ?? prevValue?.module;
        const moduleIndex = module
          ? Object.values(WorkOrderControlModule).indexOf(module)
          : -1;
        return `${index};${moduleIndex}`;
      },
      fromString: (value) => {
        if (!value) {
          return defaultView;
        }
        const [tabIndex, controlIndex] = value.split(";");
        let tab = Object.values(WorkOrderTabs)[parseInt(tabIndex)];
        const module = controlIndex
          ? Object.values(WorkOrderControlModule)[parseInt(controlIndex)]
          : undefined;

        return {
          tab,
          module,
        } as WorkOrderView;
      },
    }
  );

  const setView = useCallback(
    async (value: WorkOrderView) => {
      const handled = await handle({
        onlyHandleBeforeBack: true,
      });
      if (handled) {
        return;
      }
      _setView(value);
    },
    [_setView, handle]
  );

  const res = useMutableQuery({
    queryKey: ["workOrder", workerGuid],
    queryFn: () => {
      return workOrderRepo.getHandymanWorkOrder(workerGuid!);
    },
    retry: 1,
    initialData: () => {
      const data = queryClient.getQueriesData(["workOrders", handyman.id]);
      const workOrders = data
        .map(([queryKey, workOrders]) => workOrders)
        .flat();

      const safeParse =
        WorkOrder.HandymanWithPermissionsSchema.array().safeParse(workOrders);

      if (safeParse.success) {
        const workOrder = safeParse.data.find(
          (wo) => wo.workerGuid === workerGuid
        );
        return workOrder;
      }

      return undefined;
    },
  });

  const order = res.query.data;

  useEffect(() => {
    if (
      order &&
      !order.allowedToHandleOrder &&
      view.tab === WorkOrderTabs.handle
    ) {
      setView({
        tab: WorkOrderTabs.overview,
      });
    }
  }, [order, view.tab, setView]);

  const checklistRes = useMutableQuery({
    queryKey: [
      "workOrderChecklist",
      res.query.data?.orderId,
      res.query.data?.allowedToHandleOrder,
    ],
    queryFn: async () => {
      if (!res.query.data?.checklist || !res.query.data?.allowedToHandleOrder) {
        return undefined;
      }

      return workOrderRepo.fetchChecklist(res.query.data.orderId);
    },
  });

  const form = order?.externalCustomerPreparationForm;
  const preparationFormRes = useQueryWrapper({
    queryKey: ["preparationForm", order?.orderId],
    queryFn: () =>
      ipisFormRepo.getPreparationsFormForHandyman({
        /* 
            This won't be enabled if the order doesn't exist        
        */
        workOrderId: order?.orderId!,
        fileRepo: workOrderFileRepo,
      }),
    enabled: !!form && !!form.completedDate,
  });

  const showError =
    res.query.isError || (!res.query.isLoading && !res.query.data);

  const enableFailedWorkOrderReporting =
    process.env.REACT_APP_ENABLE_FAILED_WORK_ORDER_REPORTING === "true";

  const enableMaterialReview =
    process.env.REACT_APP_ENABLE_MATERIAL_REVIEW === "true";

  const enableUpdatedChecklist =
    process.env.REACT_APP_ENABLE_UPDATED_CHECKLIST === "true";

  return (
    <LoadingContent
      loading={res.query.isLoading && !res.query.data}
      renderContentSeparately
    >
      {showError && (
        <SomethingWentWrong
          error={res.query.error}
          description="Denna order går inte att hämta just nu, vilket förmodligen betyder att den inte finns."
        />
      )}
      {!showError && res.query.data && (
        <HandymanWorkOrderRouteContext.Provider
          value={{
            orderRes: res,
            order: res.query.data,
            checklistRes,
            currentView: view,
            preparationFormRes,
            navigateTo: setView,
            enableFailedWorkOrderReporting,
            enableMaterialReview,
            enableUpdatedChecklist,
          }}
        >
          <HandymanWorkOrderCheckInOutContextProvider>
            <DecoupledWorkOrderFileContextProvider
              as="handyman"
              workOrder={res.query.data}
              preparationForm={preparationFormRes.data}
            >
              <HandymanLineItemContextProvider>
                <HandymanWorkOrderPage />
              </HandymanLineItemContextProvider>
            </DecoupledWorkOrderFileContextProvider>
          </HandymanWorkOrderCheckInOutContextProvider>
        </HandymanWorkOrderRouteContext.Provider>
      )}
    </LoadingContent>
  );
};

export default withHandymanCredentials(HandymanWorkOrderRoute);
