"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFile = void 0;
var UploaderSource_1 = require("../shared-schemas/UploaderSource");
var zod_1 = require("zod");
var FileMeta_1 = require("./FileMeta");
var CollectionType_1 = require("../shared-schemas/CollectionType");
var IpisFileRelation_1 = require("./IpisFileRelation");
var IpisFile;
(function (IpisFile) {
    IpisFile.NonHeicImages = zod_1.z.enum(["jpg", "jpeg", "png", "webp"]);
    IpisFile.HeicImages = zod_1.z.enum(["heic"]);
    IpisFile.ImageExtensions = zod_1.z.enum(__spreadArray(__spreadArray([], IpisFile.NonHeicImages.options, true), IpisFile.HeicImages.options, true));
    IpisFile.DocumentExtensions = zod_1.z.enum([
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "rtf",
    ]);
    IpisFile.AllowedExtensionsWithoutHeic = zod_1.z.enum(__spreadArray(__spreadArray([], IpisFile.NonHeicImages.options, true), IpisFile.DocumentExtensions.options, true));
    IpisFile.AllowedExtensions = zod_1.z.enum(__spreadArray(__spreadArray([], IpisFile.ImageExtensions.options, true), IpisFile.DocumentExtensions.options, true));
    IpisFile.OnlySrcSchema = zod_1.z.string();
    IpisFile.ObjectSchema = zod_1.z.object({
        src: zod_1.z.string(),
        name: zod_1.z.string(),
        uploadedBy: UploaderSource_1.UploaderSource.Schema,
        recordId: zod_1.z.string(),
        ext: zod_1.z.string(),
        mimeType: zod_1.z.string(),
        guid: zod_1.z.string(),
        collectionType: CollectionType_1.CollectionType.Schema,
        createdDate: zod_1.z.date(),
    });
    IpisFile.WithMetaSchema = IpisFile.ObjectSchema.extend({
        meta: FileMeta_1.FileMeta.Schema.optional(),
    });
    IpisFile.WithRelationSchema = IpisFile.WithMetaSchema.extend({
        relations: IpisFileRelation_1.IpisFileRelation.Schema.array(),
    });
})(IpisFile || (IpisFile = {}));
exports.IpisFile = IpisFile;
