import {
  FormCondition,
  FormElementAnswer,
  IpisFormElement,
} from "@eljouren/domain";
import { UUID } from "@eljouren/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import {
  IpisButton,
  IpisSelectCompact,
  IpisSelectCompactOption,
  classNames,
} from "@ipis/client-essentials";
import { useEffect } from "react";
import { DefaultValues, useForm } from "react-hook-form";
import { useFormEditor } from "../../../../../../hooks/checklist-hooks";
import MyDialog from "../../../../../common/MyDialog";

interface Props {
  question: IpisFormElement.Type | null;
  close: () => void;
}

const ConditionalLogicFormDialog = (props: Props) => {
  return (
    <MyDialog isOpen={!!props.question} cancel={() => props.close()}>
      {!!props.question && (
        <Form element={props.question} close={props.close} />
      )}
    </MyDialog>
  );
};

const FormSchema = z.object({
  controllingQuestionId: z.string(),
  comparator: FormCondition.FormConditionComparisonSchema,
  value: z.any(),
});

type FormValues = z.infer<typeof FormSchema>;

type ValidElement =
  | IpisFormElement.YesNoType
  | IpisFormElement.MultipleChoiceType;

/* 
  Just in case we want to move this to an isolated component

  For the sake of simplicity, we will only allow one condition to begin with
*/
const Form = (props: { element: IpisFormElement.Type; close: () => void }) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(),
  });

  const editor = useFormEditor();
  const editingForm = editor.form;
  const validElements = editingForm.pages
    .flatMap((p) => p.elements)
    .filter((el): el is ValidElement => {
      const validType =
        el.typeOfQuestion === "yes/no" ||
        el.typeOfQuestion === "multiple-choice";
      const validId = el.id !== props.element.id;
      return validType && validId;
    });

  const selectedValue = form.watch("value");

  const controllingQuestionId = form.watch("controllingQuestionId");
  const controllingQuestion = validElements.find(
    (el) => el.id === controllingQuestionId
  );

  function getDefaultValues(): DefaultValues<FormValues> {
    const firstCondition = props.element.conditionGroups?.[0]?.conditions[0];
    if (!props.element.conditionGroups?.length || !firstCondition) {
      return {
        comparator: "equals",
      };
    } else {
      const values: DefaultValues<FormValues> = {
        controllingQuestionId: firstCondition.reference,
        comparator: firstCondition.comparison,
        value: firstCondition.value.toString(),
      };
      return values;
    }
  }

  function getValueOptions(controllingQuestion: ValidElement | undefined) {
    let valueOptions: IpisSelectCompactOption[] = [];
    if (controllingQuestion) {
      if (controllingQuestion.typeOfQuestion === "yes/no") {
        valueOptions = [
          { label: "Ja", value: "true" },
          { label: "Nej", value: "false" },
        ];
      } else if (controllingQuestion.typeOfQuestion === "multiple-choice") {
        valueOptions = controllingQuestion.options.map((o) => ({
          label: o.value,
          value: o.id,
        }));
        if (controllingQuestion.allowOther) {
          valueOptions.push({
            label: "Annat",
            value: FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION,
          });
        }
      }
    }
    return valueOptions;
  }

  useEffect(() => {
    if (selectedValue === undefined) {
      return;
    }

    const options = getValueOptions(controllingQuestion);
    const selectedInOptions = options.some((o) => o.value === selectedValue);

    if (!selectedInOptions) {
      form.setValue("value", options[0]?.value);
    }
  }, [form, selectedValue, controllingQuestion]);

  const controllingQuestionOptions = validElements.map((el) => ({
    label: el.title,
    value: el.id,
  }));

  const e = props.element;
  const isImageGroup = e.typeOfQuestion === "image-group";

  const comparators = FormCondition.FormConditionComparisonSchema.options;
  const comparatorOptions = comparators.map((c) => {
    let label: string;
    switch (c) {
      case "equals":
        label = "är lika med";
        break;
      case "not-equals":
        label = "är inte lika med";
        break;
    }

    return {
      label,
      value: c,
    };
  });

  const valueOptions = getValueOptions(controllingQuestion);

  async function onClearConditions() {
    const confirm = await window.ipisModal.confirm({
      title: "Radera villkor",
      prompt: "Är du säker på att du vill radera alla villkor?",
    });

    if (!confirm) {
      return;
    }

    editor.controls.setConditionGroups({
      elementId: props.element.id,
      conditionGroups: [],
    });
    props.close();
  }

  function onSubmit(values: FormValues) {
    const controllingElement = validElements.find(
      (el) => el.id === values.controllingQuestionId
    );

    if (!controllingElement) {
      window.ipisModal.alert({
        title: "Något gick fel",
        prompt: "Kontrollera att du valt en giltig styrande fråga.",
        typeOfAlert: "error",
      });
      return;
    }

    if (UUID.validate(controllingElement.id)) {
      window.ipisModal.alert({
        title: "Kontrollerande fråga är inte sparad",
        prompt:
          "Vänligen spara formuläret så att den kontrollerande frågan kan läggas till i databasen innan du lägger till villkor.",
        typeOfAlert: "error",
      });
      return;
    }

    const valueOptions = getValueOptions(controllingElement);
    const validOption = valueOptions.find((o) => o.value === values.value);

    if (!validOption) {
      window.ipisModal.alert({
        title: "Något gick fel",
        prompt: "Kontrollera att du valt ett giltigt värde.",
        typeOfAlert: "error",
      });
      return;
    }

    let value: any;
    if (controllingElement.typeOfQuestion === "yes/no") {
      value = values.value === "true";
    } else {
      if (UUID.validate(values.value)) {
        window.ipisModal.alert({
          title: "Värde är inte sparad",
          prompt:
            "Vänligen spara formuläret så att det valda värdet kan läggas till i databasen innan du lägger till villkor.",
          typeOfAlert: "error",
        });
        return;
      }
      value = values.value;
    }

    const id = UUID.generate().value;
    const rawCondition: FormCondition.Type = {
      id,
      clientSideId: id,
      reference: values.controllingQuestionId,
      referenceType: "question",
      typeOfQuestion: controllingElement.typeOfQuestion,
      comparison: values.comparator,
      value,
    };

    const conditionSafeParse = z
      .union([
        FormCondition.YesNoQuestionSchema,
        FormCondition.MultipleChoiceQuestionSchema,
      ])
      .safeParse(rawCondition);
    if (!conditionSafeParse.success) {
      window.ipisModal.alert({
        title: "Något gick fel",
        prompt: "Kontrollera att du valt giltiga värden.",
        typeOfAlert: "error",
      });
      return;
    }

    const condition = conditionSafeParse.data;

    const groupId = UUID.generate().value;
    editor.controls.setConditionGroups({
      elementId: props.element.id,
      conditionGroups: [
        {
          id: groupId,
          clientSideId: groupId,
          conditions: [condition],
          relationship: "and",
        },
      ],
    });

    props.close();
  }

  return (
    <section
      className={classNames(
        "mx-auto grid h-full w-full grid-cols-2 divide-x-2 divide-dark-200 rounded p-8"
      )}
    >
      <section className="flex h-full w-full flex-col gap-8">
        <header>
          <h2 className="text-2xl font-bold">Lägg till villkor</h2>
          {/*   <p className="text-sm text-semi-faded">
            {isImageGroup ? "För bildgrupp" : `För '${e.title}'`}
          </p> */}
        </header>
        {!validElements.length && (
          <p>
            Formuläret innehåller inga frågor som kan användas för villkor.
            Giltiga frågor är ja/nej och flervalsfrågor.
          </p>
        )}
        {!!validElements.length && (
          <form
            className="grid grid-cols-[100px,minmax(0,1fr)] items-center gap-x-4 gap-y-8"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <span className="font-bold">Om</span>
            <IpisSelectCompact
              required
              id="mainQuestion"
              label="Styrande fråga"
              form={form}
              name="controllingQuestionId"
              options={controllingQuestionOptions}
            />
            <fieldset className="col-start-2 grid grid-cols-3 gap-x-4">
              <IpisSelectCompact
                required
                id="comparator"
                label="Jämförelse"
                form={form}
                name="comparator"
                options={comparatorOptions}
              />
              <IpisSelectCompact
                required
                id="value"
                label="Värde"
                form={form}
                name="value"
                disabled={!controllingQuestion}
                options={valueOptions}
                className="col-span-2"
              />
            </fieldset>
            <span className="font-bold">Visa</span>
            <span className="rounded border p-4">
              {" "}
              {isImageGroup ? "Bildgrupp" : `${e.title}`}
            </span>
            <footer className="col-start-2 flex flex-col gap-2">
              <IpisButton
                type="submit"
                label="Spara"
                disabled={!form.formState.isValid}
              />
              {!!props.element.conditionGroups?.length && (
                <IpisButton
                  variant="secondary-on-light-background"
                  label="Rensa villkor"
                  onClick={onClearConditions}
                />
              )}
              <IpisButton variant="text" label="Avbryt" onClick={props.close} />
            </footer>
          </form>
        )}
      </section>
    </section>
  );
};

export default ConditionalLogicFormDialog;
