import {
  FormElementAnswer,
  IpisForm,
  IpisFormElementIsolatedAnswer,
} from "@eljouren/domain";
import { IpisFileV2 } from "@eljouren/file-schemas";
import {
  FormInputContext,
  IpisButton,
  IpisTextArea,
  IpisTextInput,
  IpisTextInputCompact,
  IpisToggleInput,
  IpisXIcon,
  classNames,
} from "@ipis/client-essentials";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import SwipableImageViewer from "../../__v2__/images/SwipableImageViewer";
import { motion } from "framer-motion";

type AnswerSummaryContextType = {
  selectImage: (imageId: string) => void;
  selectedImageId: string | null;
};

const AnswerSummaryContext = createContext<AnswerSummaryContextType>({
  selectImage: () => {},
  selectedImageId: null,
});

type Sections = {
  id: string;
  title: string;
  index: number;
  answers: IpisFormElementIsolatedAnswer.ClientSideType[];
};

interface Props {
  className?: string;
  goBack: () => void;
  currentFormVersion: IpisForm.ShellType | null;
  answers: IpisFormElementIsolatedAnswer.ClientSideType[];
  addXCloseButton?: boolean;
}

const ChecklistAnswerSummary = (props: Props) => {
  const form = useForm({
    defaultValues: getDefaultValues(),
  });

  const [selectedImageId, setSelectedImageId] = useState<string | null>(null);

  function getDefaultValues() {
    const entries = props.answers.map((answerElement) => {
      return [answerElement.id, answerElement.answer];
    });
    return Object.fromEntries(entries);
  }

  function buildSections(): Sections[] {
    const other: IpisFormElementIsolatedAnswer.ClientSideType[] = [];
    const sections: Sections[] = [];
    const answers = props.answers;

    answers.forEach((answer) => {
      const foundReference = props.currentFormVersion?.pages.some(
        (page, pageIndex) => {
          return page.elements.some((element) => {
            if (element.id === answer.formElementId) {
              const previousSection = sections.find(
                (section) => section.id === page.id
              );
              if (previousSection) {
                previousSection.answers.push(answer);
              } else {
                sections.push({
                  id: page.id,
                  title: page.pageTitle,
                  index: pageIndex,
                  answers: [answer],
                });
              }

              return true;
            }

            return false;
          });
        }
      );
      if (!foundReference) {
        other.push(answer);
      }
    });

    sections.sort((a, b) => a.index - b.index);
    if (other.length > 0) {
      sections.push({
        id: "other",
        title: "Övrigt",
        index: sections.length,
        answers: other,
      });
    }

    sections.forEach((section) => {
      section.answers.sort((a, b) => a.indexWithinPage - b.indexWithinPage);
    });

    return sections;
  }

  function getAllImages(): IpisFileV2.Type[] {
    return props.answers
      .filter(
        (
          answer
        ): answer is IpisFormElementIsolatedAnswer.ClientSideImageGroupType =>
          answer.type === "image-group"
      )
      .map((answer) => answer.answer.images)
      .flat()
      .filter((image): image is any => image.state === "postupload");
  }

  const sections = buildSections();
  const allImages = getAllImages();

  return (
    <FormInputContext.Provider
      value={{
        omitDescription: true,
        omitError: true,
        omitTooltip: true,
        omitNote: true,
        omitSlideUpElement: true,
        readOnly: true,
      }}
    >
      <AnswerSummaryContext.Provider
        value={{
          selectImage: setSelectedImageId,
          selectedImageId,
        }}
      >
        <section
          className={classNames(
            "relative grid h-full w-full grid-rows-[auto,minmax(0,1fr),auto] gap-4 py-8",
            props.className
          )}
        >
          {allImages.length > 0 && selectedImageId && (
            <SwipableImageViewer
              images={allImages}
              absolute
              onClose={() => setSelectedImageId(null)}
              hideOnClickOutside
            />
          )}
          <header className="flex justify-between px-2">
            <h2 className="text-semi-faded">
              {props.currentFormVersion?.name ?? "Förberedelseformulär"}
            </h2>
            {props.addXCloseButton && (
              <button onClick={props.goBack} className="text-black">
                <IpisXIcon className="h-6 w-6 text-black" />
              </button>
            )}
          </header>
          <div className="relative flex h-full flex-col gap-8 overflow-auto px-2">
            {sections.map((section) => {
              return (
                <section key={section.id} className="flex flex-col gap-4">
                  <header className=" sticky top-0 z-10 border-b border-border-color bg-white">
                    <h3 className="text-lg">{section.title}</h3>
                  </header>
                  <ul className="flex flex-col gap-8">
                    {section.answers.map((answer) => {
                      return (
                        <li key={answer.id}>
                          <AnswerInputRouter
                            key={answer.id}
                            form={form}
                            answer={answer}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </section>
              );
            })}
          </div>
          <footer className="flex flex-col">
            <IpisButton label="Gå tillbaka" onClick={props.goBack} />
          </footer>
        </section>
      </AnswerSummaryContext.Provider>
    </FormInputContext.Provider>
  );
};

const AnswerInputRouter = (props: {
  answer: IpisFormElementIsolatedAnswer.ClientSideType;
  form: UseFormReturn<any>;
}) => {
  const ans = props.answer;

  if (ans.type === "text") {
    return (
      <IpisTextInput
        id={ans.id}
        name={`${ans.id}.value`}
        required={false}
        label={ans.title}
        form={props.form}
      />
    );
  }

  if (ans.type === "textarea") {
    return (
      <IpisTextArea
        id={ans.id}
        name={`${ans.id}.value`}
        label={ans.title}
        required={false}
        rows={3}
        form={props.form}
      />
    );
  }

  if (ans.type === "number") {
    return (
      <IpisTextInput
        id={ans.id}
        name={`${ans.id}.value`}
        required={false}
        label={ans.title}
        type="number"
        form={props.form}
      />
    );
  }

  if (ans.type === "image-group") {
    return <ImageGroupAnswerComponent answer={ans} form={props.form} />;
  }

  if (ans.type === "yes/no") {
    return (
      <IpisToggleInput
        id={ans.id}
        name={`${ans.id}.value`}
        label={ans.title}
        required={false}
        form={props.form}
      />
    );
  }

  if (ans.type === "multiple-choice") {
    /* 
      Bit of a hack ngl
    */
    const value =
      ans.answer.value === FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION
        ? "Övrigt"
        : ans.answer.value;
    if (ans.answer.specification) {
      return (
        <fieldset className="flex flex-col gap-4">
          <IpisTextInput
            id={ans.id}
            name={`${ans.id}.value`}
            required={false}
            label={ans.title}
            controls={{
              value,
            }}
          />
          <IpisTextInputCompact
            id={ans.id}
            name={`${ans.id}.specification`}
            required={false}
            label={"Specifikation"}
            controls={{
              value: ans.answer.specification,
            }}
          />
        </fieldset>
      );
    }

    return (
      <IpisTextInput
        id={ans.id}
        name={`${ans.id}.value`}
        required={false}
        label={ans.title}
        controls={{
          value,
        }}
      />
    );
  }

  return <></>;
};

function groupImagesByName(
  images: IpisFormElementIsolatedAnswer.ClientSideImageGroupType["answer"]["images"]
): Record<
  string,
  IpisFormElementIsolatedAnswer.ClientSideImageGroupType["answer"]["images"]
> {
  return Object.values(images)
    .flat()
    .reduce((acc, image) => {
      let name: string;
      if (image.state === "postupload") {
        name = image.name;
      } else {
        name = image.meta?.name ?? image.native.name;
      }
      if (acc[name]) {
        acc[name].push(image);
      } else {
        acc[name] = [image];
      }
      return acc;
    }, {});
}
const ImageGroupAnswerComponent = (props: {
  answer: IpisFormElementIsolatedAnswer.ClientSideImageGroupType;
  form: UseFormReturn<any>;
}) => {
  const ctx = useContext(AnswerSummaryContext);
  const ans = props.answer;
  const images = Object.values(ans.answer.images).flat();
  const groupedImagesRef = useRef(groupImagesByName(images));

  useEffect(() => {
    groupedImagesRef.current = groupImagesByName(images);
  }, [images]);

  const groupedImages = groupedImagesRef.current;

  return (
    <section className="flex w-full flex-col gap-4">
      <h3 className="text-lg font-normal">Bilder</h3>

      {images.length === 0 && (
        <p className="text-semi-faded">Inga bilder uppladdade</p>
      )}
      {images.length > 0 && (
        <ul className="flex flex-col gap-4">
          {Object.entries(groupedImages).map(([name, images]) => {
            return (
              <li key={name} className="flex flex-col gap-2">
                <h4 className="font-normal">{name}</h4>
                <ul className="grid w-full grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-2">
                  {images.map((image) => {
                    if (image.state === "postupload") {
                      return (
                        <li
                          key={image.guid}
                          onClick={() => ctx.selectImage(image.guid)}
                        >
                          <motion.img
                            src={image.src}
                            alt={image.name}
                            className="h-40 w-full object-cover"
                          />
                        </li>
                      );
                    } else {
                      return (
                        <li
                          key={image.guid}
                          onClick={() => ctx.selectImage(image.guid)}
                        >
                          <motion.img
                            src={image.dataUrl}
                            alt={image.meta.name}
                            className="h-40 w-full object-cover"
                          />
                        </li>
                      );
                    }
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      )}
      {ans.answer.imageUploadComment && (
        <IpisTextArea
          id={`${ans.id}.imageUploadComment`}
          name={`${ans.id}.imageUploadComment`}
          required={false}
          label={"Kommentar till bilder"}
          form={props.form}
          rows={3}
        />
      )}
    </section>
  );
};

export default ChecklistAnswerSummary;
