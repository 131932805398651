import { Handyman, HandymanProvince } from "@eljouren/domain/build";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useQuery } from "react-query";
import { useApiClients } from "../../../hooks/use-api-clients";

import ClientUtils from "../../../utils/ClientUtils";
import GoBackButton from "../../GoBackButton";
import MyDialog from "../../common/MyDialog";
import PageSection from "../../common/PageSection";
import { AppButton } from "../../common/buttons/AppButton";
import AppTextButton from "../../common/buttons/AppTextButton";
import { AppFormCheckbox } from "../../common/checkboxes/AppFormCheckbox";
import { AppLoader } from "../../common/loaders/AppLoader";
import SignOutIcon from "../../icons/SignOutIcon";
import ProfileFormMergedInput from "./ProfileFormMergedInput";

interface Props {
  className?: string;
  user: Handyman.Type;
  loading?: boolean;
  updateProfileInfo(worker: Handyman.Type): Promise<void>;
}

const ProfileFormSection = (props: Props) => {
  const { authRepo, handymanRepo } = useApiClients();

  const provinceRes = useQuery(["provinces"], () =>
    handymanRepo.fetchProvincesWithCounties()
  );

  const form = useForm<Handyman.Type>({
    resolver: zodResolver(Handyman.Schema),
    defaultValues: props.user,
  });

  useEffect(
    () => {
      form.reset(props.user);
    },
    /* 
    Using form as a dependency here used to work, but since react-hook-form 7.54.0, 
    this causes an infinite rerender loop.

    While I will still downgrade react-hook-form back to 7.53.2, Iäve removed the form
    dependency here as a way to avoid this happening again.
  */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.user]
  );

  async function onSubmit(values: Handyman.Type) {
    props.updateProfileInfo(values);
  }

  function onSignOut(e: React.MouseEvent) {
    e.preventDefault();
    authRepo.signOut();
  }

  const [dialogOpen, setDialogOpen] = useState(false);

  function openDialog() {
    setDialogOpen(true);
  }

  function closeDialog() {
    setDialogOpen(false);
  }

  function saveActiveAreas(activeAreas: string[]) {
    closeDialog();
    props.updateProfileInfo({
      ...props.user,
      activeAreas: activeAreas.sort(),
    });
  }

  return (
    <PageSection
      as="section"
      className={ClientUtils.twClassNames(
        "flex h-full flex-col",
        props.className
      )}
    >
      <header className="flex flex-col items-center justify-center py-6">
        <h3 className="text-lg font-bold text-black">
          {props.user.firstName} {props.user.lastName}
        </h3>
        <span className="text-sm">{props.user.email}</span>
      </header>
      <form
        className={ClientUtils.twClassNames(
          "mx-auto flex w-full max-w-xl flex-col gap-4 p-2 py-4 xs:p-6 md:gap-6 md:p-6",
          props.loading && "pointer-events-none opacity-50",
          props.className
        )}
      >
        <main className="flex flex-col gap-2">
          <ProfileFormMergedInput
            label="Adress"
            value={`${props.user.street}, ${props.user.postalCode} ${props.user.city}`}
            //canEdit
            id="addressProfileFormInput"
            paths={[
              { label: "Gata", path: "street" },
              { label: "Postnummer", path: "postalCode" },
              { label: "Stad", path: "city" },
            ]}
            //onSave={form.handleSubmit(onSubmit)}
            form={form}
            canEdit={false}
          />
          <ProfileFormMergedInput
            label="Mobil"
            value={props.user.phone}
            canEdit
            id="phoneProfileFormInput"
            paths={["phone"]}
            form={form}
            onSave={form.handleSubmit(onSubmit)}
          />

          <ProfileFormMergedInput
            label="Aktiva områden"
            value={props.user.activeAreas.join(", ")}
            canEdit
            id="activeAreasProfileFormInput"
            onEdit={openDialog}
            htmlAttributes={{
              rows: 2,
            }}
          />
        </main>
      </form>
      <footer className="mx-auto my-auto flex w-full max-w-xl flex-col gap-2 p-4">
        <AppTextButton
          onClick={onSignOut}
          className="flex items-center gap-3 text-sm"
        >
          <SignOutIcon
            size={30}
            className="rounded-full bg-red-100 p-2 text-red-400"
          />
          Logga ut
        </AppTextButton>
      </footer>
      <MyDialog isOpen={dialogOpen}>
        {provinceRes.isError && (
          <p className="p-2 text-red-600">
            Det gick inte att hämta områden just nu
          </p>
        )}

        {provinceRes.isLoading && !provinceRes.data && <AppLoader />}

        {provinceRes.data && (
          <ActiveAreasForm
            {...props}
            goBack={closeDialog}
            provinces={provinceRes.data}
            save={saveActiveAreas}
          />
        )}
      </MyDialog>
    </PageSection>
  );
};

type TActiveAreasFormValues = { activeAreas: string[] };

const ActiveAreasForm = (props: {
  user: Handyman.Type;
  goBack: () => void;
  provinces: HandymanProvince.Type[];
  save(areas: string[]): void;
}) => {
  const form = useForm<TActiveAreasFormValues>({
    defaultValues: {
      activeAreas: props.user.activeAreas,
    },
  });

  function onSubmit(values: TActiveAreasFormValues) {
    props.save(values.activeAreas);
  }

  return (
    <form
      className="grid h-full w-full max-w-screen-lg grid-rows-hmf flex-col gap-2 overflow-hidden bg-white/90 p-4 md:p-8 lg:p-16"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <header>
        <h2 className="text-2xl">Aktiva områden</h2>
      </header>
      <main className="flex flex-col gap-4 overflow-y-auto p-2">
        {props.provinces.map((province) => (
          <details key={province.name} className="border-b pb-2">
            <summary className="cursor-pointer text-xl">
              {province.name}
            </summary>
            <fieldset className="flex flex-col gap-2 p-2">
              {province.counties.sort().map((county) => (
                <AppFormCheckbox
                  key={county}
                  label={county}
                  type="checkbox"
                  register={form.register}
                  //@ts-ignore
                  name={`activeAreas[]`}
                  value={county}
                />
              ))}
            </fieldset>
          </details>
        ))}
      </main>

      <footer className="flex flex-col items-end justify-end gap-2">
        <AppButton className="w-72" type="submit" requiresNetworkConnection>
          Spara aktiva områden
        </AppButton>
        <GoBackButton
          iconSize={24}
          appButtonProps={{
            className: "w-72",
          }}
          onClick={props.goBack}
        />
      </footer>
    </form>
  );
};

export default ProfileFormSection;
