"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormAnswerValidator = void 0;
var domain_1 = require("@eljouren/domain");
/*
    This should only be done once, at the top level of the checklist module, and then passed down via context
*/
var IpisFormAnswerValidator = /** @class */ (function () {
    function IpisFormAnswerValidator(props) {
        this.previousQuestions = {};
        this.shell = props.form;
        this.imagesStrippedToReduceRequestPayload =
            !!props.imagesStrippedToReduceRequestPayload;
        this.futureQuestions = this.getQuestionMap(props.form);
        this.po = this.validateAndCombine(props);
    }
    IpisFormAnswerValidator.fromSplit = function (args) {
        var entries = args.answers.map(function (a) { return [a.formElementId, a.answer]; });
        var currentFormValues = Object.fromEntries(entries);
        return new IpisFormAnswerValidator({
            form: args.form,
            currentFormValues: currentFormValues,
            imagesStrippedToReduceRequestPayload: true,
        });
    };
    IpisFormAnswerValidator.prototype.inDevelopment = function () {
        var _a;
        try {
            if (typeof process === "undefined") {
                return false;
            }
            var env = (_a = process === null || process === void 0 ? void 0 : process.env) !== null && _a !== void 0 ? _a : {};
            return env["NODE_ENV"] === "development";
        }
        catch (e) {
            return false;
        }
    };
    IpisFormAnswerValidator.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (this.inDevelopment()) {
            console.log.apply(console, args);
        }
    };
    IpisFormAnswerValidator.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (this.inDevelopment()) {
            console.warn.apply(console, args);
        }
    };
    IpisFormAnswerValidator.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (this.inDevelopment()) {
            console.error.apply(console, args);
        }
    };
    IpisFormAnswerValidator.prototype.passesValidation = function () {
        return !!this.safelyGetFullyValidated();
    };
    IpisFormAnswerValidator.prototype.getFullyValidated = function () {
        var safe = this.safelyGetFullyValidated();
        if (!safe) {
            throw new Error("Form is not fully answered; use the isFullyAnswered method to check this first");
        }
        return safe;
    };
    IpisFormAnswerValidator.prototype.safelyGetFullyValidated = function () {
        var _a;
        if (this.fullyAnswered === undefined) {
            var safeParse = domain_1.IpisForm.FullyValidatedSchema.safeParse(this.po);
            if (safeParse.success) {
                this.fullyAnswered = safeParse.data;
            }
            else {
                this.log("po", this.po);
                var prettyErrorJson = JSON.stringify(safeParse.error, null, 2);
                this.error("Failed to validate form: ".concat(prettyErrorJson));
                this.fullyAnswered = null;
            }
        }
        return (_a = this.fullyAnswered) !== null && _a !== void 0 ? _a : undefined;
    };
    IpisFormAnswerValidator.prototype.validateAndCombine = function (args) {
        var _this = this;
        var pages = args.form.pages.map(function (page) {
            var elements = page.elements.map(function (element) {
                var _a;
                var answer = (_a = args.currentFormValues) === null || _a === void 0 ? void 0 : _a[element.id];
                return _this.validateElement(element, answer);
            });
            return __assign(__assign({}, page), { elements: elements });
        });
        return __assign(__assign({}, args.form), { pages: pages });
    };
    IpisFormAnswerValidator.prototype.validateElement = function (element, answer) {
        var typeOfQuestion = element.typeOfQuestion;
        var conditionsMet = this.validateConditions({ question: element });
        if (typeOfQuestion === "information") {
            return this.handleElement(__assign(__assign({}, element), { state: {
                    answerState: "information",
                    isAnswered: false,
                    isValid: true,
                    meetsConditions: conditionsMet,
                } }));
        }
        if (!conditionsMet) {
            return this.handleElement(__assign(__assign({}, element), { state: {
                    answerState: "unanswered",
                    isAnswered: false,
                    meetsConditions: false,
                    isValid: true,
                    answer: undefined,
                } }));
        }
        if (answer === undefined) {
            return this.handleElement(__assign(__assign({}, element), { state: {
                    answerState: "unanswered",
                    isAnswered: false,
                    meetsConditions: true,
                    isValid: !element.required,
                    answer: undefined,
                } }));
        }
        if (element.typeOfQuestion === "image-group" &&
            this.imagesStrippedToReduceRequestPayload) {
            return this.handleElement(__assign(__assign({}, element), { state: {
                    answerState: "unanswered",
                    isAnswered: false,
                    meetsConditions: true,
                    isValid: true,
                    answer: undefined,
                } }));
        }
        var schema = domain_1.FormElementAnswer.getAnswerSchemaFromQuestion(element);
        var safeParse = schema.safeParse(answer);
        if (safeParse.success) {
            return this.handleElement(__assign(__assign({}, element), { state: {
                    answerState: "answered",
                    isAnswered: true,
                    meetsConditions: true,
                    isValid: true,
                    answer: safeParse.data,
                } }));
        }
        return this.handleElement(__assign(__assign({}, element), { state: {
                answerState: "answered-invalid",
                isAnswered: true,
                meetsConditions: true,
                isValid: false,
                answer: answer,
            } }));
    };
    /*
          Utils
    */
    IpisFormAnswerValidator.prototype.getQuestionMap = function (form) {
        var questions = form.pages.flatMap(function (page) { return page.elements; });
        return Object.fromEntries(questions.map(function (q) { return [q.id, q]; }));
    };
    IpisFormAnswerValidator.prototype.handleElement = function (question) {
        this.previousQuestions[question.id] = question;
        delete this.futureQuestions[question.id];
        return question;
    };
    IpisFormAnswerValidator.prototype.validateConditions = function (args) {
        var _this = this;
        var conditionGroups = args.question.conditionGroups;
        /*
                If there are no condition groups, the conditions are always considered met
              */
        if (!conditionGroups) {
            return true;
        }
        /*
                Now, if there are condition groups, the rules are as follows:
                * If the condition reference is towards a question, that question needs to be in the previousQuestions map.
                  Otherwise, the checklist might not be possible to answer fully, as a question might activate a question on a previous page, rendering the checklist incomplete.
                * This should be validated beforehand. If this somehow fails, we'll throw an error if the question doesn't exist at all. But if we find the question in the
                    futureQuestions map, we'll log a warning and consider the condition met.
                * If the condition reference is towards a repeaterItem, the repeaterItem needs to be in the SAME repeaterGroup. Otherwise we'll throw an error.
              */
        var conditionMet = conditionGroups.every(function (group) {
            if (group.relationship === "and") {
                return group.conditions.every(function (condition) {
                    return _this.validateElementCondition({
                        condition: condition,
                    });
                });
            }
            else {
                return group.conditions.some(function (condition) {
                    return _this.validateElementCondition({
                        condition: condition,
                    });
                });
            }
        });
        return conditionMet;
    };
    IpisFormAnswerValidator.prototype.validateElementCondition = function (args) {
        var con = args.condition;
        var id = con.reference;
        var referenceType = con.typeOfQuestion;
        var cacheId = "".concat(con.id, "-").concat(id);
        if (id in this.previousQuestions) {
            var question = this.previousQuestions[id];
            if (question.typeOfQuestion !== referenceType) {
                throw new Error("Condition references a question of the wrong type: ".concat(id));
            }
            var state = question.state;
            if (!state.isAnswered) {
                return false;
            }
            if (!state.isValid) {
                return false;
            }
            var shouldBeVisible = void 0;
            if (con.typeOfQuestion === "yes/no") {
                if (con.comparison === "equals") {
                    shouldBeVisible = state.answer.value === con.value;
                }
                else {
                    shouldBeVisible = state.answer.value !== con.value;
                }
                delete IpisFormAnswerValidator.conditionWarningCache[cacheId];
            }
            else {
                if (question.typeOfQuestion !== "multiple-choice") {
                    if (!(cacheId in IpisFormAnswerValidator.conditionWarningCache)) {
                        this.log({
                            context: "IpisFormAnswerValidator.validateElementCondition",
                            message: "The condition references a question which does not match the type of the condition. This condition will always be considered met.",
                        });
                        IpisFormAnswerValidator.conditionWarningCache[cacheId] = true;
                    }
                    return true;
                }
                var option = question.options.find(function (option) { return option.id === con.value; });
                if (!option) {
                    if (!(cacheId in IpisFormAnswerValidator.conditionWarningCache)) {
                        this.log({
                            context: "IpisFormAnswerValidator.validateElementCondition",
                            message: "The condition value points to an option that doesn't exist. This condition will always be considered met.",
                        });
                        IpisFormAnswerValidator.conditionWarningCache[cacheId] = true;
                    }
                    return true;
                }
                delete IpisFormAnswerValidator.conditionWarningCache[cacheId];
                if (con.comparison === "equals") {
                    shouldBeVisible = state.answer.value === option.value;
                }
                else {
                    shouldBeVisible = state.answer.value !== option.value;
                }
            }
            return shouldBeVisible;
        }
        else {
            if (id in this.futureQuestions) {
                if (!(cacheId in IpisFormAnswerValidator.conditionWarningCache)) {
                    this.warn("Condition references a future question: ".concat(id));
                    IpisFormAnswerValidator.conditionWarningCache[cacheId] = true;
                }
                return true;
            }
            else {
                if (!(cacheId in IpisFormAnswerValidator.conditionWarningCache)) {
                    this.warn("Condition references a non-existing question: ".concat(id));
                    IpisFormAnswerValidator.conditionWarningCache[cacheId] = true;
                }
                return true;
            }
        }
    };
    /*
        Should infer the return type from the id
      */
    IpisFormAnswerValidator.prototype.getElement = function (id) {
        if (id in this.previousQuestions) {
            return this.previousQuestions[id];
        }
        throw new Error("Element not found: ".concat(id));
    };
    IpisFormAnswerValidator.prototype.getAllElements = function () {
        return Object.values(this.previousQuestions);
    };
    IpisFormAnswerValidator.prototype.getAnswer = function (id) {
        var _a;
        return (_a = this.getElement(id).state) === null || _a === void 0 ? void 0 : _a.answer;
    };
    IpisFormAnswerValidator.prototype.getPo = function () {
        return this.po;
    };
    IpisFormAnswerValidator.prototype.getFormValues = function () {
        var values = {};
        Object.values(this.previousQuestions).forEach(function (question) {
            var _a, _b;
            if (((_a = question.state) === null || _a === void 0 ? void 0 : _a.isAnswered) && ((_b = question.state) === null || _b === void 0 ? void 0 : _b.isValid)) {
                values[question.id] = question.state.answer;
            }
        });
        return values;
    };
    IpisFormAnswerValidator.prototype.prepareForSaving = function () {
        var validated = this.getFullyValidated();
        var elements = validated.pages.flatMap(function (page) { return page.elements; });
        var hasInvalidElementTypes = elements.some(function (e) { return e.typeOfQuestion === "repeater" || e.typeOfQuestion === "rich-text"; });
        if (hasInvalidElementTypes) {
            throw new Error("Invalid element types found - these are only used internally and should not be saved.");
        }
        var answers = [];
        validated.pages.forEach(function (page) {
            page.elements.forEach(function (e, i) {
                var _a;
                var base = {
                    id: crypto.randomUUID(),
                    formElementId: e.id,
                    title: e.typeOfQuestion === "image-group" ? "Bildgrupp" : e.title,
                    indexWithinPage: i,
                };
                if (e.state.answer === undefined) {
                    return;
                }
                switch (e.typeOfQuestion) {
                    case "yes/no":
                        answers.push(__assign(__assign({}, base), { type: "yes/no", answer: {
                                value: e.state.answer.value,
                            } }));
                        break;
                    case "image-group":
                        answers.push(__assign(__assign({}, base), { type: "image-group", 
                            /*
                              The files will be uploaded client-side
                            */
                            answer: {
                                value: null,
                                imageUploadComment: (_a = e.state.answer.imageUploadComment) !== null && _a !== void 0 ? _a : null,
                            } }));
                        break;
                    case "multiple-choice":
                        answers.push(__assign(__assign({}, base), { type: "multiple-choice", answer: {
                                value: e.state.answer.value,
                                specification: e.state.answer.specification,
                            } }));
                        break;
                    case "number":
                        answers.push(__assign(__assign({}, base), { type: "number", answer: {
                                value: e.state.answer.value,
                            } }));
                        break;
                    case "text":
                        answers.push(__assign(__assign({}, base), { type: "text", answer: {
                                value: e.state.answer.value,
                            } }));
                        break;
                    case "textarea":
                        answers.push(__assign(__assign({}, base), { type: "textarea", answer: {
                                value: e.state.answer.value,
                            } }));
                        break;
                }
            });
        });
        return {
            form: this.shell,
            answers: answers,
        };
    };
    /*
      Probably a confusing way to handle this logic, also not sure if this belongs here.
    */
    IpisFormAnswerValidator.prototype.shouldShowRepeaterItem = function (args) {
        var _this = this;
        var groups = args.repeaterItem.conditionGroups;
        if (!groups) {
            return true;
        }
        var question = this.getElement(args.parentQuestion.id);
        if (question.typeOfQuestion !== "repeater") {
            throw new Error("Parent question is not a repeater question");
        }
        return groups.every(function (group) {
            var callback = function (condition) {
                var _a, _b;
                var type = condition.referenceType;
                if (type === "question") {
                    return _this.validateElementCondition({
                        condition: condition,
                    });
                }
                else {
                    var reference_1 = condition.reference;
                    var item = question.repeaterGroup.items.find(function (item) { return item.id === reference_1; });
                    if (!item) {
                        throw new Error("Repeater item not found");
                    }
                    var state = question.state;
                    /*
                      Nice naming scheme!
                    */
                    if (state.answerState !== "answered") {
                        return false;
                    }
                    var value = (_b = (_a = state.answer.repeaterItems[args.repeaterGroupId]) === null || _a === void 0 ? void 0 : _a[reference_1]) === null || _b === void 0 ? void 0 : _b.value;
                    return value === condition.value;
                }
            };
            if (group.relationship === "and") {
                return group.conditions.every(callback);
            }
            else {
                return group.conditions.some(callback);
            }
        });
    };
    IpisFormAnswerValidator.conditionWarningCache = {};
    return IpisFormAnswerValidator;
}());
exports.IpisFormAnswerValidator = IpisFormAnswerValidator;
