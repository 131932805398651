import { IpisFileV2 } from "@eljouren/file-schemas/build";
import { createContext, useContext, useEffect } from "react";
import { CustomFileInputFile } from "../../components/files/FileInputButton";
import useMutableQuery, {
  UseMutableQueryResult,
} from "../../hooks/use-mutatable-query";
import useQueue from "../../hooks/use-queue";
import { useApiClients } from "../../hooks/use-api-clients";
import { GlobalContext } from "../../top-level-contexts";
import {
  FilePermissions,
  SingleFilePermissions,
} from "../../types/file-permissions";
import { ProcessTaskArgs } from "../../utils/process/ProcessTask";
import CustomerWorkOrderContext from "./CustomerWorkOrderContext";
import { WorkOrder } from "@eljouren/domain";
export const CustomerWorkOrderFileContext = createContext<{
  fileRes: UseMutableQueryResult<IpisFileV2.Type[]>;
  uploadFileMutation: (files: CustomFileInputFile[]) => void;
  deleteFileMutation: (file: IpisFileV2.Type) => void;
  permissions: FilePermissions;
  images: IpisFileV2.Type[];
  documents: IpisFileV2.Type[];
}>({} as never);

interface Props {
  children?: React.ReactNode;
  workOrder?: WorkOrder.CustomerType;
}

const CustomerWorkOrderFileContextProvider = (props: Props) => {
  const { signInState } = useContext(GlobalContext);
  const { workOrderFileRepo } = useApiClients();
  /* 
    Please note! This context is no longer guaranteed to have been initialised when using this component.
  */
  const workOrderContext = useContext(CustomerWorkOrderContext);

  const workOrder = props.workOrder ?? workOrderContext.workOrder;

  const uploadQueue = useQueue({
    id: "file-upload-queue",
    name: "Filuppladdning",
  });
  const deleteQueue = useQueue({
    id: "file-delete-queue",
    name: "Ta bort filer",
  });
  const fileRes = useMutableQuery({
    queryKey: [
      "workOrderFiles",
      workOrder.orderId,
      workOrder.confirmedByCustomer,
      signInState.signedInAs,
    ],
    queryFn: () => workOrderFileRepo.getFilesAsCustomer(),
  });

  useEffect(() => {
    let unsubscribe: () => void;
    if (uploadQueue.isInProgress) {
      unsubscribe = uploadQueue.process.onFinished(() => {
        fileRes.query.refetch();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  });

  useEffect(() => {
    let unsubscribe: () => void;
    if (deleteQueue.isInProgress) {
      unsubscribe = deleteQueue.process.onFinished(() => {
        fileRes.query.refetch();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  });

  const canUpload = !workOrder.isFinished;
  function singleFilePermissions(file: IpisFileV2.Type): SingleFilePermissions {
    const relatedToWorkOrder = !!file.relations?.every(
      (rel) => rel.type === "workOrder"
    );
    const uploadedByCustomer = file.uploadedBy === "customer";
    return {
      canDelete: canUpload && relatedToWorkOrder && uploadedByCustomer,
    };
  }

  const uploadFileMutation = async (files: CustomFileInputFile[]) => {
    if (!canUpload) {
      window.modal.alert({
        typeOfAlert: "error",
        title: "Något gick fel",
        prompt: "Du har inte rättigheter att ladda upp filer",
        error: null,
      });
      return;
    }
    const tasks: ProcessTaskArgs<any>[] = files.map((file) => {
      return {
        name: file.meta?.name || file.guid,
        func: async () => {
          //await new Promise((res) => setTimeout(res, 5000));
          await workOrderFileRepo.uploadAsCustomer({
            files: [file],
          });
        },
      };
    });

    uploadQueue.addTasks(...tasks);
  };

  const deleteFileMutation = async (obj: IpisFileV2.Type) => {
    if (!singleFilePermissions(obj).canDelete) {
      window.modal.alert({
        typeOfAlert: "error",
        title: "Något gick fel",
        prompt: "Du har inte rättigheter att ta bort filen",
        error: null,
      });
      return;
    }
    const tasks: ProcessTaskArgs<any> = {
      name: obj.name,
      func: async () => {
        const res = await workOrderFileRepo.deleteFileAsCustomer({
          file: obj,
        });
        return res;
      },
      onError: (er) => {
        return {
          errorMessage: "Det gick inte att ta bort filen",
        };
      },
    };
    deleteQueue.addTasks(tasks);
  };

  const images =
    fileRes.query.data?.filter((file) => file.mimeType.includes("image")) ?? [];
  const documents =
    fileRes.query.data?.filter((file) => !file.mimeType.includes("image")) ??
    [];

  return (
    <CustomerWorkOrderFileContext.Provider
      value={{
        fileRes,
        uploadFileMutation,
        deleteFileMutation,
        images,
        documents,
        permissions: {
          canUpload,
          forFile: singleFilePermissions,
        },
      }}
    >
      {props.children}
    </CustomerWorkOrderFileContext.Provider>
  );
};

export default CustomerWorkOrderFileContextProvider;
