import {
  FormElementAnswer,
  IpisFormElementIsolatedAnswer,
  WorkOrder,
} from "@eljouren/domain";
import { IpisFileV2 } from "@eljouren/file-schemas/build";
import { createContext, useContext, useEffect } from "react";
import UploadResponseErrorMessageGenerator from "../../../_model/helpers/UploadResponseErrorMessageGenerator";
import IClientWorkOrderFileRepoV2 from "../../../_model/repos/interfaces/IClientWorkOrderFileRepoV2";
import { HandymanGetPreparationsFormOutput } from "../../../_model/repos/IpisFormRepo";
import { useApiClients } from "../../../hooks/use-api-clients";
import useMutableQuery, {
  UseMutableQueryResult,
} from "../../../hooks/use-mutatable-query";
import useQueryWrapper, {
  UseQueryWrapperResult,
} from "../../../hooks/use-query-wrapper";
import useQueue from "../../../hooks/use-queue";
import { GlobalContext } from "../../../top-level-contexts";
import {
  FilePermissions,
  SingleFilePermissions,
} from "../../../types/file-permissions";
import { ProcessTaskArgs } from "../../../utils/process/ProcessTask";
import { CustomFileInputFile } from "../../files/FileInputButton";

type FileWithCustomRelation = IpisFileV2.Type & {
  isLinkedToPreparationsForm?: boolean;
};

export type THandymanFileFilters = {
  workOrderFiles: boolean;
  serviceContractFiles: boolean;
  workOrderLineItemFiles: boolean;
  opportunityLineItemFiles: boolean;
  productPackageFiles: boolean;
  preparationFormFiles: boolean;
};

export const HandymanWorkOrderFileContext = createContext<{
  workOrderFileRes: UseMutableQueryResult<IpisFileV2.Type[]>;
  lineItemFileRes: UseQueryWrapperResult<IpisFileV2.Type[]>;
  workOrderImages: IpisFileV2.Type[] | undefined;
  workOrderDocuments: IpisFileV2.Type[] | undefined;
  workOrderLineItemImages: IpisFileV2.Type[] | undefined;
  workOrderLineItemDocuments: IpisFileV2.Type[] | undefined;
  opportunityLineItemImages: IpisFileV2.Type[] | undefined;
  opportunityLineItemDocuments: IpisFileV2.Type[] | undefined;
  productPackageImages: IpisFileV2.Type[] | undefined;
  productPackageDocuments: IpisFileV2.Type[] | undefined;
  serviceContractImages: IpisFileV2.Type[] | undefined;
  serviceContractDocuments: IpisFileV2.Type[] | undefined;
  preparationFormImages: IpisFileV2.Type[] | undefined;
  defaultFilterValues: THandymanFileFilters;
  allImages: IpisFileV2.Type[];
  allDocuments: IpisFileV2.Type[];
  allFiles: IpisFileV2.Type[];
  uploadFiles: (files: CustomFileInputFile[]) => void;
  deleteFile: (file: IpisFileV2.Type) => void;
  applyFilters: (filters: THandymanFileFilters) => {
    images: IpisFileV2.Type[];
    documents: IpisFileV2.Type[];
  };
  isLoading: boolean;
  isError: boolean;
  isErrorWithoutData: boolean;
  isErrorWithData: boolean;
  permissions: FilePermissions;
  hasServiceContract: boolean;
  hasPreparationsForm: boolean;
}>({} as never);

type Props = {
  children?: React.ReactNode;
} & (
  | {
      as: "salesTeam";
      workOrder: WorkOrder.CustomerType;
      preparationForm: HandymanGetPreparationsFormOutput | undefined;
    }
  | {
      as: "handyman";
      workOrder: WorkOrder.HandymanWithPermissionsType;
      preparationForm: HandymanGetPreparationsFormOutput | undefined;
    }
);

const DecoupledWorkOrderFileContextProvider = (props: Props) => {
  //const { order } = useContext(HandymanWorkOrderRouteContext);

  const { workOrderFileRepo } = useApiClients();
  const { signInState } = useContext(GlobalContext);
  const workOrderFileRes = useMutableQuery({
    queryKey: [
      "workOrderFiles",
      props.as,
      props.workOrder.orderId,
      signInState.signedInAs,
    ],
    queryFn: () => {
      if (props.as === "handyman") {
        return workOrderFileRepo.getFilesAsHandyman({
          workOrderId: props.workOrder.orderId,
        });
      } else {
        return workOrderFileRepo.getFilesAsStaff();
      }
    },
  });

  const lineItemFileRes = useQueryWrapper({
    queryKey: [
      "lineItemFiles",
      props.as,
      props.workOrder?.orderId,
      signInState.signedInAs,
    ],
    queryFn: () => {
      if (props.as === "handyman") {
        return workOrderFileRepo.getRelatedFilesAsHandyman({
          workOrderId: props.workOrder.orderId,
        });
      } else {
        return workOrderFileRepo.getRelatedFilesAsStaff();
      }
    },
  });

  const uploadQueue = useQueue({
    id: "file-upload-queue",
    name: "Filuppladdning",
  });
  const deleteQueue = useQueue({
    id: "file-delete-queue",
    name: "Ta bort filer",
  });

  useEffect(() => {
    let unsubscribe: () => void;
    if (uploadQueue.isInProgress) {
      unsubscribe = uploadQueue.process.onFinished(() => {
        workOrderFileRes.query.refetch();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  });

  useEffect(() => {
    let unsubscribe: () => void;
    if (deleteQueue.isInProgress) {
      unsubscribe = deleteQueue.process.onFinished(() => {
        workOrderFileRes.query.refetch();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  });

  const hasServiceContract =
    props.as === "handyman" && !!props.workOrder.serviceContract;
  const allowedToHandle =
    props.as === "salesTeam" || props.workOrder.allowedToHandleOrder;
  const canUpload = !props.workOrder.isFinished && allowedToHandle;
  function singleFilePermissions(file: IpisFileV2.Type): SingleFilePermissions {
    const relatedToWorkOrder = !!file.relations?.every(
      (rel) => rel.type === "workOrder"
    );
    const uploadedByHandymanOrCustomer =
      file.uploadedBy === "worker" || file.uploadedBy === "customer";

    const hasTag = !!file.tag;
    return {
      canDelete:
        canUpload &&
        relatedToWorkOrder &&
        uploadedByHandymanOrCustomer &&
        !hasTag,
    };
  }

  const uploadFiles = async (files: CustomFileInputFile[]) => {
    const useBatchUpload = false;
    if (useBatchUpload) {
      const task: ProcessTaskArgs<any> = {
        name: "Laddar upp filer",
        func: async () => {
          if (props.as === "handyman") {
            const res = await workOrderFileRepo.uploadAsHandyman({
              workOrderId: props.workOrder.orderId,
              files,
            });
            return res;
          } else {
            const res = await workOrderFileRepo.uploadAsSalesTeam({
              files,
            });
            return res;
          }
        },
        onSuccess: (
          res: Awaited<
            ReturnType<IClientWorkOrderFileRepoV2["uploadAsHandyman"]>
          >
        ) => {
          if (res.status !== "fulfilled") {
            return {
              state: "mixed",
              errorMessages: UploadResponseErrorMessageGenerator.generate(res),
              /* 
                Doesn't make sense to pass this back
              */
              result: res,
            };
          }
        },
      };

      uploadQueue.addTasks(task);
    } else {
      const tasks: ProcessTaskArgs<any>[] = files.map((file) => {
        return {
          name: file.meta.name || file.guid,
          func: async () => {
            if (props.as === "salesTeam") {
              const res = await workOrderFileRepo.uploadAsSalesTeam({
                files: [file],
              });
              return res;
            } else {
              const res = await workOrderFileRepo.uploadAsHandyman({
                workOrderId: props.workOrder.orderId,
                files: [file],
              });
              return res;
            }
          },
          onSuccess: (
            res: Awaited<
              ReturnType<IClientWorkOrderFileRepoV2["uploadAsHandyman"]>
            >
          ) => {
            if (res.status !== "fulfilled") {
              return {
                state: "failed",
                errorMessages:
                  UploadResponseErrorMessageGenerator.generate(res),
                /* 
                  Doesn't make sense to pass this back
                */
                result: res,
              };
            }
          },
        };
      });

      uploadQueue.addTasks(...tasks);
    }
  };

  const deleteFile = async (file: IpisFileV2.Type) => {
    if (!singleFilePermissions(file).canDelete) {
      window.modal.alert({
        typeOfAlert: "error",
        title: "Något gick fel",
        prompt: "Du har inte rättigheter att ta bort filen",
        error: null,
      });
      return;
    }
    const tasks: ProcessTaskArgs<any> = {
      name: file.name,
      func: async () => {
        const res = await workOrderFileRepo.deleteFileAsHandyman({
          file,
          workOrderId: props.workOrder.orderId,
        });
        return res;
      },
      onError: (er) => {
        return {
          errorMessage: "Det gick inte att ta bort filen",
        };
      },
    };
    deleteQueue.addTasks(tasks);
  };

  function separateImagesAndDocuments(files: IpisFileV2.Type[]): {
    images: IpisFileV2.Type[];
    documents: IpisFileV2.Type[];
  } {
    const images: IpisFileV2.Type[] = [];
    const documents: IpisFileV2.Type[] = [];
    files.forEach((file) => {
      if (file.collectionType === "images") {
        images.push(file);
      } else {
        documents.push(file);
      }
    });
    return { images, documents };
  }

  const { images: workOrderImages, documents: workOrderDocuments } =
    separateImagesAndDocuments(workOrderFileRes.query.data ?? []);
  const { images: lineItemsImages, documents: lineItemDocuments } =
    separateImagesAndDocuments(lineItemFileRes.data ?? []);

  const preparationFormImages: FileWithCustomRelation[] =
    props.preparationForm?.answers
      .filter(
        (ans): ans is IpisFormElementIsolatedAnswer.ClientSideImageGroupType =>
          ans.type === "image-group"
      )
      .flatMap((ans) => ans.answer.images)
      .filter((img): img is FormElementAnswer.PostUploadImageType => {
        return img.state === "postupload";
      })
      .map((el) => {
        return {
          ...el,
          isLinkedToPreparationsForm: true,
        };
      }) ?? [];

  const workOrderLineItemImages: IpisFileV2.Type[] = lineItemsImages?.filter(
    (file) => file.relations?.some((rel) => rel.type === "workOrderLineItem")
  );
  const workOrderLineItemDocuments: IpisFileV2.Type[] =
    lineItemDocuments?.filter((file) =>
      file.relations?.some((rel) => rel.type === "workOrderLineItem")
    );
  const opportunityLineItemImages: IpisFileV2.Type[] = lineItemsImages?.filter(
    (file) => file.relations?.some((rel) => rel.type === "opportunityLineItem")
  );
  const opportunityLineItemDocuments: IpisFileV2.Type[] =
    lineItemDocuments?.filter((file) =>
      file.relations?.some((rel) => rel.type === "opportunityLineItem")
    );
  const productPackageImages: IpisFileV2.Type[] = lineItemsImages?.filter(
    (file) => file.relations?.some((rel) => rel.type === "productPackage")
  );
  const productPackageDocuments: IpisFileV2.Type[] = lineItemDocuments?.filter(
    (file) => file.relations?.some((rel) => rel.type === "productPackage")
  );

  const serviceContractImages = lineItemsImages?.filter((file) =>
    file.relations?.some((rel) => rel.type === "serviceContract")
  );
  const serviceContractDocuments = lineItemDocuments?.filter((file) =>
    file.relations?.some((rel) => rel.type === "serviceContract")
  );

  const allImages: FileWithCustomRelation[] = [
    ...workOrderImages,
    ...lineItemsImages,
    ...preparationFormImages,
  ];
  const allDocuments: FileWithCustomRelation[] = [
    ...workOrderDocuments,
    ...lineItemDocuments,
  ];

  const allFiles: FileWithCustomRelation[] = [...allImages, ...allDocuments];

  function filterFile(
    filters: THandymanFileFilters,
    file: FileWithCustomRelation
  ): boolean {
    if (file.isLinkedToPreparationsForm) {
      return filters.preparationFormFiles;
    }

    const relations = file.relations;
    return !!relations?.some((rel) => {
      switch (rel.type) {
        case "workOrder":
        case "case":
          return filters.workOrderFiles;
        case "workOrderLineItem":
          return filters.workOrderLineItemFiles;
        case "opportunityLineItem":
          return filters.opportunityLineItemFiles;
        case "productPackage":
          return filters.productPackageFiles;
        case "serviceContract":
          return filters.serviceContractFiles;
      }
      return false;
    });
  }

  function applyFilters(filters: THandymanFileFilters) {
    const images: IpisFileV2.Type[] = allImages.filter((file) =>
      filterFile(filters, file)
    );
    const documents: IpisFileV2.Type[] = allDocuments.filter((file) =>
      filterFile(filters, file)
    );

    return { images, documents };
  }

  const isLoading =
    workOrderFileRes.query.isLoading || lineItemFileRes.isLoading;

  const isError = workOrderFileRes.query.isError || lineItemFileRes.isError;
  const isErrorWithoutData =
    isError &&
    !workOrderFileRes.query.data?.length &&
    !lineItemFileRes.data?.length;
  const isErrorWithData = isError && !isErrorWithoutData;

  const defaultFilterValues: THandymanFileFilters = {
    workOrderFiles: true,
    serviceContractFiles: true,
    workOrderLineItemFiles: true,
    opportunityLineItemFiles: true,
    productPackageFiles: true,
    preparationFormFiles: !!props.workOrder.externalCustomerPreparationForm,
  };

  return (
    <HandymanWorkOrderFileContext.Provider
      value={{
        allFiles,
        hasServiceContract,
        hasPreparationsForm: !!props.workOrder.externalCustomerPreparationForm,
        workOrderFileRes,
        lineItemFileRes,
        uploadFiles,
        defaultFilterValues,
        deleteFile,
        workOrderImages,
        workOrderDocuments,
        workOrderLineItemImages,
        workOrderLineItemDocuments,
        opportunityLineItemImages,
        opportunityLineItemDocuments,
        productPackageImages,
        productPackageDocuments,
        serviceContractImages,
        serviceContractDocuments,
        preparationFormImages,
        allImages,
        allDocuments,
        applyFilters,
        isLoading,
        isError,
        isErrorWithoutData,
        isErrorWithData,
        permissions: {
          canUpload,
          forFile: singleFilePermissions,
        },
      }}
    >
      {props.children}
    </HandymanWorkOrderFileContext.Provider>
  );
};

export default DecoupledWorkOrderFileContextProvider;
