import { HandymanWorkingHours } from "@eljouren/domain/build";
import { DateHelper, WorkOrdersAndWorkingHoursMerger } from "@eljouren/utils";
import { format } from "date-fns";
import React from "react";
import { useMutation, useQuery } from "react-query";
import DatePeriod from "../../_model/helpers/AvailabilityPeriod";
import { useApiClients } from "../../hooks/use-api-clients";
import {
  useBundledCustomSearchParamStateNoHistory,
  useCustomSearchParamStateNoHistory,
} from "../../hooks/use-search-params";
import HandymanContext, { Holiday } from "./HandymanContext";

interface Props {
  children?: React.ReactNode;
  handymanId: string;
  pageIndexSearchParamKey: string;
}

const HandymanContextProvider = (props: Props) => {
  const [weeksInPeriod, _setWeeksInPeriod] =
    useCustomSearchParamStateNoHistory<number>(
      props.pageIndexSearchParamKey + "wip",
      getDefaultWeeksInPeriod(),
      {
        fromString: (str) => {
          try {
            const val = str ? Number.parseInt(str) : 2;
            if (val < 1 || val > 2) {
              return 2;
            }

            return val;
          } catch (er) {
            return 2;
          }
        },
        toString: (index) => index.toString(),
      }
    );
  const { handymanRepo, workOrderRepo } = useApiClients();
  const pageBundle = useBundledCustomSearchParamStateNoHistory<number>(
    props.pageIndexSearchParamKey,
    0,
    {
      fromString: (str) => {
        return str ? Number.parseInt(str) : 0;
      },
      toString: (index) => index.toString(),
    }
  );

  function validateWeeksInPeriod(weeks: number) {
    return weeks >= 1 && weeks <= 2;
  }

  function getDefaultWeeksInPeriod() {
    const key = props.pageIndexSearchParamKey + "wip";
    const val = localStorage.getItem(key);

    if (!val) {
      return 2;
    }

    const num = Number.parseInt(val);

    if (validateWeeksInPeriod(num)) {
      return num;
    }

    return 2;
  }

  function saveWeeksInPeriod(weeks: number) {
    const key = props.pageIndexSearchParamKey + "wip";
    localStorage.setItem(key, weeks.toString());
  }

  function setWeeksInPeriod(weeks: number) {
    _setWeeksInPeriod(weeks);
    saveWeeksInPeriod(weeks);
    pageBundle.set(0);
  }

  function getDatesForPeriod(index: number): Date[] {
    const period = new DatePeriod({
      weeksInPeriod,
    });
    return period.getPeriodAtIndex(index);
  }

  function getInterval(index: number): { start: Date; end: Date } {
    const dates = getDatesForPeriod(index);

    const start = new DateHelper(dates[0]).startOfDay.date;
    const end = new DateHelper(dates[dates.length - 1]).add("days", 1)
      .startOfDay.date;

    return { start, end };
  }

  const workOrderRes = useQuery(
    ["workOrders", props.handymanId, pageBundle.value, weeksInPeriod],
    () => {
      return workOrderRepo.getForHandymanBetween({
        handymanId: props.handymanId,
        interval: getInterval(pageBundle.value),
      });
    }
  );

  const uniqueYears = Array.from(
    new Set(
      getDatesForPeriod(pageBundle.value).map((date) => date.getFullYear())
    )
  );

  const holidaysRes = useQuery(["holidays", uniqueYears], () => {
    if (uniqueYears.length === 0) {
      return {} as Awaited<
        ReturnType<typeof workOrderRepo.getHolidaysForYears>
      >;
    }

    return workOrderRepo.getHolidaysForYears(uniqueYears);
  });

  const workingHoursRes = useQuery(
    ["workingHours", props.handymanId, pageBundle.value, weeksInPeriod],
    () => {
      return handymanRepo.fetchWorkingHours({
        handymanId: props.handymanId,
        interval: getInterval(pageBundle.value),
      });
    }
  );

  const saveWorkingHours = useMutation(
    async (workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType) => {
      return handymanRepo.saveWorkingHours({
        workingHours,
        handymanId: props.handymanId,
      });
    },
    {
      onSettled: () => {
        workingHoursRes.refetch();
      },
    }
  );

  function getHoliday(date: Date): Holiday | undefined {
    const data = holidaysRes.data ?? {};
    const formatted = format(date, "yyyy-MM-dd");
    const holiday = data[formatted];

    return holiday;
  }

  const isLoading =
    workingHoursRes.isFetching ||
    saveWorkingHours.isLoading ||
    workOrderRes.isFetching;

  const merger = new WorkOrdersAndWorkingHoursMerger({
    workingHours: workingHoursRes.data ?? {},
    workOrders: workOrderRes.data ?? [],
  });

  return (
    <HandymanContext.Provider
      value={{
        merger,
        isLoading,
        weeksInPeriod,
        getHoliday,
        workingHoursRes,
        holidays: holidaysRes.data ?? {},
        workOrderRes,
        setWeeksInPeriod,
        setPage: pageBundle.set,
        page: pageBundle.value,
        incrementPage: () => pageBundle.set(pageBundle.value + 1),
        decrementPage: () => pageBundle.set(pageBundle.value - 1),
        saveWorkingHours,
      }}
    >
      {props.children}
    </HandymanContext.Provider>
  );
};

export default HandymanContextProvider;
