import { MaterialReviewList } from "@eljouren/domain";
import { useContext, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useLoading } from "../../../../../../hooks/hooks";
import { useApiClients } from "../../../../../../hooks/use-api-clients";
import HandymanWorkOrderRouteContext from "../../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import ClientUtils from "../../../../../../utils/ClientUtils";
import GoBackButton from "../../../../../GoBackButton";
import { AppButton } from "../../../../../common/buttons/AppButton";
import { AppFormCheckbox } from "../../../../../common/checkboxes/AppFormCheckbox";
import { AppFormRadioButtons } from "../../../../../common/checkboxes/AppFormRadioButtons";
import AppPages from "../../../../../common/pages/AppPages";
import { CustomFileInputFile } from "../../../../../files/FileInputButton";
import WorkOrderFileUploadForm from "../../../../files/WorkOrderFileUploadForm";
import { HandymanWorkOrderFileContext } from "../../../../files/DecoupledWorkOrderFileContextProvider";
import HandymanLineItemContext from "../../../line-items/HandymanLineItemContext";
import HandymanLineItemList from "../components/HandymanLineItemList";

type FormValues = {
  includePendingLineItems: boolean;
  confirmAllMaterialsAreIncludedInTheImages: boolean;
};

enum Page {
  review,
  upload,
}

interface Props {
  className?: string;
  closeFileUploadModal: () => void;
  onFinished: () => void;
  materialReviewList: MaterialReviewList.Type;
}

const WorkOrderMaterialReviewImageModal = (props: Props) => {
  const lineItemCtx = useContext(HandymanLineItemContext);
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const fileCtx = useContext(HandymanWorkOrderFileContext);
  const { workOrderRepo } = useApiClients();
  const [page, setPage] = useState<Page>(Page.review);

  const form = useForm<FormValues>({
    defaultValues: {
      includePendingLineItems: false,
      confirmAllMaterialsAreIncludedInTheImages: false,
    },
  });

  const loader = useLoading();

  /* 
    ToDo: Move to context
  */
  async function uploadImages(files: CustomFileInputFile[]) {
    try {
      const list = props.materialReviewList;
      await loader.loadWhilePromise(
        lineItemCtx.lineItemRes.mutate({
          callback: () =>
            fileCtx.workOrderFileRes.mutate({
              callback: () =>
                workOrderRepo.uploadProofOfMaterialConfirmation({
                  workOrderId: ctx.order.orderId,
                  images: files,
                  materialReviewList: list,
                  includePendingLineItems: form.getValues(
                    "includePendingLineItems"
                  ),
                }),
            }),
        })
      );
      props.onFinished();
    } catch (er) {
      window.modal.alert({
        typeOfAlert: "error",
        title: "Något gick fel",
        prompt:
          "Något gick fel när bilderna skulle laddas upp. Vänligen försök igen senare.",
      });
    }
  }

  return (
    <div className="grid h-full w-full grid-cols-1 grid-rows-1 items-center justify-center bg-white/90 backdrop-blur children-w-screen-lg">
      <AppPages
        className={ClientUtils.classNames(
          "px-2 py-8 sm:px-4",
          loader.isLoading && "pointer-events-none opacity-50"
        )}
        pageIndex={page}
        onlyMain
        mainGridRow={1}
      >
        {page === Page.review && (
          <ReviewPage
            form={form}
            className="col-start-1 row-start-1"
            materialReviewList={props.materialReviewList}
            onSubmit={() => setPage(Page.upload)}
            onGoBack={props.closeFileUploadModal}
          />
        )}
        {page === Page.upload && (
          <section
            className={ClientUtils.classNames(
              "col-start-1 row-start-1 flex flex-col",
              loader.isLoading && "pointer-events-none opacity-50"
            )}
          >
            <WorkOrderFileUploadForm
              onSubmit={uploadImages}
              uploadLabel="Ladda upp bilder"
              requireAtLeastOneFile
              onlyImages
              prompt="Ladda upp bilder på medgivande från kunden. Namnet på bilderna kommer att sättas automatiskt."
              onGoBack={() => setPage(Page.review)}
              omitName
            />
          </section>
        )}
      </AppPages>
    </div>
  );
};

interface ReviewPageProps {
  materialReviewList: MaterialReviewList.Type;
  onSubmit: () => void;
  onGoBack: () => void;
  className?: string;
  form: UseFormReturn<FormValues>;
}

const ReviewPage = (props: ReviewPageProps) => {
  const form = props.form;
  const list = props.materialReviewList;

  const atleastOnePendingLineItem =
    list &&
    list.pendingLineItemSummary.lineItems.some(
      (el) => el.approvalState === "pending"
    );

  const atleastOneNotApprovedLineItem =
    list &&
    list.treatingNotApprovedAsPending &&
    list.pendingLineItemSummary.lineItems.some(
      (el) => el.approvalState === "notApproved"
    );

  const includePendingLineItems = form.watch("includePendingLineItems");
  const hasConfirmed = form.watch("confirmAllMaterialsAreIncludedInTheImages");
  const atleastOneLineItemActive =
    atleastOneNotApprovedLineItem ||
    (atleastOnePendingLineItem && includePendingLineItems);
  const canProceedToUploadPage = atleastOneLineItemActive && hasConfirmed;

  return (
    <form
      className={ClientUtils.classNames("flex flex-col gap-4", props.className)}
    >
      <header>
        <h2>Samtliga av orderraderna nedan ska omfattas av medgivandet</h2>
        <p className="font-opacity-90 text-sm text-dark-gray">
          Godkännande för orderraderna måste synas tydligt.
        </p>
      </header>
      <main className="flex flex-col gap-4">
        {atleastOnePendingLineItem && (
          <fieldset className="rounded  bg-off-white p-4">
            <AppFormRadioButtons
              buttonFlow="row"
              heading="Vill du inkludera orderrader som väntar på godkännande?"
              subheading="Minst en orderrad väntar redan på godkännande från kund via BankID. Om du väljer att inkludera dessa orderrader så kommer dessa orderrader också gå direkt till godkännande."
              id="includePendingLineItemsRadioButtons"
              name="includePendingLineItems"
              control={form.control}
              buttons={[
                {
                  label: "Ja",
                  value: true,
                },
                {
                  label: "Nej",
                  value: false,
                },
              ]}
            />
          </fieldset>
        )}
        <fieldset className="rounded  bg-off-white p-4">
          <HandymanLineItemList
            data={list.pendingLineItemSummary.lineItems}
            isLoading={false}
            useCustomerPrice
          />
        </fieldset>
      </main>
      <footer className="flex flex-col gap-2">
        {atleastOneLineItemActive && (
          <fieldset className="p-2">
            <AppFormCheckbox
              labelClassName="text-sm"
              register={form.register}
              name="confirmAllMaterialsAreIncludedInTheImages"
              label="Jag bekräftar att samtliga orderrader som ska godkännas av kunden finns med på bilderna."
              type="checkbox"
            />
            {/* <AppFormRadioButtons
              buttonFlow="row"
              heading={(props) => {
                if (atleastOnePendingLineItem && includePendingLineItems) {
                  return (
                    <h3 style={props.style}>
                      Alla orderrader i listan, inklusive orderraderna som redan
                      väntar på godkännande, finns med i medgivandet.
                    </h3>
                  );
                }

                if (atleastOnePendingLineItem && !includePendingLineItems) {
                  return (
                    <h3 style={props.style}>
                      Alla orderrader i listan, men inte orderraderna som redan
                      väntar på godkännande, finns med i medgivandet.
                    </h3>
                  );
                }

                return (
                  <h3 style={props.style}>
                    Alla orderrader i listan finns med i medgivandet.
                  </h3>
                );
              }}
              id="allMaterialsIncludedInTheImagesRadioButtons"
              name="confirmAllMaterialsAreIncludedInTheImages"
              control={form.control}
              buttons={[
                {
                  label: "Ja",
                  value: true,
                },
                {
                  label: "Nej",
                  value: false,
                },
              ]}
            /> */}
          </fieldset>
        )}
        <AppButton
          onClick={form.handleSubmit(props.onSubmit)}
          disabled={!canProceedToUploadPage}
        >
          Gå till uppladdning för medgivande
        </AppButton>
        <GoBackButton onClick={props.onGoBack} />
      </footer>
    </form>
  );
};

export default WorkOrderMaterialReviewImageModal;
