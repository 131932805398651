import { Handyman } from "@eljouren/domain/build";
import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../top-level-contexts";
import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";
import SignInSection from "../SignInSection";
import LoadingContent from "../../common/loaders/LoadingContent";
import { useApiClients } from "../../../hooks/use-api-clients";
import SessionTimeoutOverlay from "../SessionTimeoutOverlay";

export const SignedInContext = React.createContext<{
  handyman: Handyman.Type;
}>({
  get handyman(): Handyman.Type {
    throw new Error("Not signed in");
  },
});

const withHandymanCredentials =
  (WrappedComponent: (props: TSignedInWorkerState) => React.ReactElement) =>
  () => {
    const { signInState } = useContext(GlobalContext);
    const { authRepo } = useApiClients();
    useEffect(() => {
      if (signInState.signedInAs !== "worker" && !signInState.isLoading) {
        authRepo.signOut();
      }
    }, [signInState.signedInAs, signInState.isLoading, authRepo]);

    return (
      <LoadingContent loading={signInState.isLoading} renderContentSeparately>
        {(!signInState.isSignedIn || signInState.signedInAs === "customer") && (
          <SignInSection />
        )}
        {signInState.isSignedIn && signInState.signedInAs === "worker" && (
          <SignedInContext.Provider
            value={{
              handyman: signInState.handyman,
            }}
          >
            <SessionTimeoutOverlay>
              <WrappedComponent {...signInState} />
            </SessionTimeoutOverlay>
          </SignedInContext.Provider>
        )}
      </LoadingContent>
    );
  };

export default withHandymanCredentials;
