import { IpisFileV2 } from "@eljouren/file-schemas";
import {
  classNames,
  IpisArrowIcon,
  IpisXIcon,
  useOnOutsideClick,
} from "@ipis/client-essentials";
import { AnimatePresence, motion, PanInfo, useAnimation } from "framer-motion";
import { useState, useEffect, useRef, useCallback } from "react";

interface Props {
  initialId?: string;
  images: IpisFileV2.Type[];
  absolute?: boolean;
  carousel?: boolean;
  onClose: () => void;
  hideOnClickOutside?: boolean;
}

const SwipableImageViewer = (props: Props) => {
  const [currentIndex, setCurrentIndex] = useState(getInitialIndex());
  const [direction, setDirection] = useState(0);
  const [showInfo, setShowInfo] = useState(true);
  const controls = useAnimation();
  const containerRef = useRef<HTMLDivElement>(null);
  const currentImage = props.images[currentIndex];

  useOnOutsideClick({
    ref: containerRef,
    callback: () => {
      if (props.hideOnClickOutside) {
        props.onClose();
      }
    },
  });

  /*   const test = true;
  if (test) {
    const longTestName = "This is a long test name to see how it behaves";
    const longTestDescription =
      "This is a long test description to see how it behaves. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.";
    currentImage.name = longTestName;
    currentImage.description = longTestDescription;
  } */

  function getInitialIndex(): number {
    if (props.initialId === undefined) {
      return 0;
    }
    return (
      props.images.findIndex((image) => image.guid === props.initialId) ?? 0
    );
  }

  function canGoRight() {
    return currentIndex < props.images.length - 1 || props.carousel;
  }

  function canGoLeft() {
    return currentIndex > 0 || props.carousel;
  }
  const navigate = useCallback(
    (newDirection: number) => {
      let newIndex = currentIndex + newDirection;

      if (props.carousel) {
        newIndex = (newIndex + props.images.length) % props.images.length;
      } else {
        newIndex = Math.max(0, Math.min(newIndex, props.images.length - 1));
      }

      if (newIndex !== currentIndex) {
        setDirection(newDirection);
        setCurrentIndex(newIndex);
      }
    },
    [currentIndex, props.carousel, props.images.length]
  );

  function handleDragEnd(
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) {
    const threshold = 50;
    const swipeDirection = info.offset.x > 0 ? -1 : 1;

    if (Math.abs(info.offset.x) > threshold) {
      navigate(swipeDirection);
    } else {
      controls.start({ x: 0 });
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        navigate(-1);
      } else if (event.key === "ArrowRight") {
        navigate(1);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [currentIndex, navigate]);

  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      x: direction < 0 ? "100%" : "-100%",
      opacity: 0,
    }),
  };

  return (
    <div
      ref={containerRef}
      className={classNames(
        "grid h-full w-full grid-cols-1 grid-rows-1 overflow-hidden bg-black",
        props.absolute && "absolute z-20"
      )}
      onClick={() => setShowInfo(!showInfo)}
    >
      <button
        onClick={() => {
          props.onClose();
        }}
        className="absolute right-2 top-2 z-10 rounded-full bg-black/50 p-2 text-white"
      >
        <IpisXIcon />
      </button>

      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentIndex}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{
            left: 0,
            right: 0,
          }}
          dragElastic={0.1}
          onDragEnd={handleDragEnd}
          className="col-start-1 row-start-1 h-full w-full"
        >
          <motion.img
            src={currentImage.src}
            className="h-full w-full object-contain"
            alt={currentImage.name}
            draggable={false}
          />
        </motion.div>
      </AnimatePresence>

      <AnimatePresence>
        {showInfo && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="pointer-events-none absolute inset-0 flex items-center justify-between p-4"
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (canGoLeft()) {
                  navigate(-1);
                }
              }}
              disabled={!canGoLeft()}
              className="pointer-events-auto rounded-full bg-black/50 p-2 text-white disabled:opacity-30"
            >
              <IpisArrowIcon direction="left" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (canGoRight()) {
                  navigate(1);
                }
              }}
              disabled={!canGoRight()}
              className="pointer-events-auto rounded-full bg-black/50 p-2 text-white disabled:opacity-30"
            >
              <IpisArrowIcon direction="right" />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showInfo && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="z-10 col-start-1 row-start-1 mt-auto flex flex-col bg-black/70 p-4 text-white"
          >
            <h3 className="text-lg font-bold">{currentImage.name}</h3>
            {!!currentImage.description && <p>{currentImage.description}</p>}
            <span className="ml-auto text-white">
              {currentIndex + 1} / {props.images.length}
            </span>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SwipableImageViewer;
