import { WorkOrder } from "@eljouren/domain/build";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../../../hooks/use-media-query";
import { useApiClients } from "../../../../../hooks/use-api-clients";
import HandymanWorkOrderRouteContext from "../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import ClientUtils from "./../../../../../utils/ClientUtils";

import SaveAndGoBackFooter from "../../../../SaveAndGoBackFooter";
import { useAddToBackButtonStack } from "../../../../common/navigation/use-back-button-stack";
import useBeforeBackButton from "../../../../common/navigation/use-before-back-button";
import { AppFormTextArea } from "../../../../common/text-areas/AppFormTextArea";

type FormValues = Pick<
  WorkOrder.HandymanType,
  "actionDescription" | "internalActionNote"
>;

interface Props {
  className?: string;
  goBack(): void;
}

const WorkOrderControlsActionSection = (props: Props) => {
  const { orderRes, order } = useContext(HandymanWorkOrderRouteContext);

  const form = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      internalActionNote: order.internalActionNote,
      actionDescription: order.actionDescription,
    },
  });

  const isLg = useMediaQuery(TailwindBreakpoint.lg);

  const { workOrderRepo } = useApiClients();

  const beforeBack = useBeforeBackButton(() => {
    if (!form.formState.isDirty) {
      return false;
    }

    return async () => {
      const shouldSave = await window.modal.confirm({
        title: "Vill du spara ändringarna?",
        prompt: "Du har ändringar som inte är sparade.",
        yesLabel: "Spara",
        noLabel: "Avbryt",
      });

      if (shouldSave) {
        await form.handleSubmit(onSubmit)();
      }

      return true;
    };
  });

  useAddToBackButtonStack(props.goBack);

  async function onGoBack() {
    const value = await beforeBack();
    if (value) {
      props.goBack();
    }
  }

  /*  useEffect(() => {
    form.reset({
      internalActionNote: order.internalActionNote,
      actionDescription: order.actionDescription,
    });
  }, [order, form]); */

  async function onSubmit(values: FormValues) {
    try {
      form.reset(values);
      await orderRes.mutate({
        callback: async () => {
          return workOrderRepo.edit({
            workOrderId: order.orderId,
            ...values,
          });
        },
        optimisticUpdate: {
          ...order,
          ...values,
        },
      });
    } catch (ex) {
      form.reset({
        internalActionNote: order.internalActionNote,
        actionDescription: order.actionDescription,
      });
      window.modal.alert({
        title: "Det gick inte spara informationen just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: ex,
      });
    }
  }

  return (
    <section
      className={ClientUtils.twClassNames(
        "grid h-full grid-cols-1 grid-rows-1",
        props.className
      )}
    >
      <form
        className="grid h-full grid-rows-[minmax(0,1fr),auto] gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <main className="flex h-full flex-col gap-4 overflow-auto p-4">
          <AppFormTextArea
            name="actionDescription"
            register={form.register}
            label="Åtgärd"
            boldLabel
            htmlAttributes={{
              rows: isLg ? 6 : 4,
              placeholder:
                "Beskriv vad du har utfört hos kund. Denna information kommer att synas på kundfakturan.",
              readOnly: order.isFinished,
              maxLength: 32768,
            }}
          />
          <AppFormTextArea
            name="internalActionNote"
            register={form.register}
            label="Intern kommentar"
            boldLabel
            htmlAttributes={{
              rows: isLg ? 6 : 4,
              placeholder:
                "Denna information syns bara internt och visas inte för kund.",
              readOnly: order.isFinished,
              maxLength: 32768,
            }}
          />
        </main>

        <SaveAndGoBackFooter
          onGoBack={onGoBack}
          onSave={form.handleSubmit(onSubmit)}
          isLoading={orderRes.isInMutationProcess}
          isDisabled={!form.formState.isDirty || orderRes.isLoading}
          readonly={order.isFinished}
        />
      </form>
    </section>
  );
};

export default WorkOrderControlsActionSection;
