import { classNames } from "@ipis/client-essentials";
import { useContext, useState } from "react";
import ChecklistAnswerSummary from "../../../components/checklist/v2/ChecklistAnswerSummary";
import { AppButton } from "../../../components/common/buttons/AppButton";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import MyDialog from "../../../components/common/MyDialog";
import HandymanWorkOrderRouteContext from "./contexts/HandymanWorkOrderRouteContext";

interface Props {
  className?: string;
}

const HandymanWorkOrderPreparationFormComponent = (props: Props) => {
  const { order, preparationFormRes } = useContext(
    HandymanWorkOrderRouteContext
  );

  const [showModal, setShowModal] = useState(false);
  const form = order.externalCustomerPreparationForm;

  if (!form) {
    return <></>;
  }

  if (!form.completedDate) {
    return (
      <div className={classNames(props.className)}>
        <span className="text-sm text-semi-faded">
          Kundchecklista ej ifylld
        </span>
      </div>
    );
  }

  if (preparationFormRes.isError) {
    return (
      <div className={classNames(props.className)}>
        <span className="text-sm text-error">
          Okänt fel vid hämtning av svar på kundchecklista
        </span>
      </div>
    );
  }

  return (
    <div className={classNames(props.className)}>
      {preparationFormRes.isLoading && <AppLoader className="h-8 w-8" />}
      {preparationFormRes.data && (
        <AppButton className="w-full" onClick={() => setShowModal(true)}>
          Visa svar på kundchecklista
        </AppButton>
      )}
      <MyDialog
        isOpen={showModal && !!preparationFormRes.data}
        cancel={() => setShowModal(false)}
        /* addCloseButton */
      >
        {!!preparationFormRes.data && (
          <div className="relative mx-auto h-full w-full max-w-screen-lg overflow-y-auto">
            <ChecklistAnswerSummary
              className="p-4"
              currentFormVersion={preparationFormRes.data.form}
              answers={preparationFormRes.data.answers}
              addXCloseButton
              goBack={() => setShowModal(false)}
            />
          </div>
        )}
      </MyDialog>
    </div>
  );
};

export default HandymanWorkOrderPreparationFormComponent;
