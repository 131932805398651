import { UploadFilesOutput } from "@eljouren/file-schemas";

export default class UploadResponseErrorMessageGenerator {
  static generate(response: UploadFilesOutput.Type): string[] {
    const failedMessages = response.failedFiles.map((failedUpload) =>
      this.generateErrorMessageFromFailedUpload(failedUpload)
    );
    const rejectedFiles = response.rejectedFiles.map((rejectedFile) =>
      this.generateErrorMessageFromRejectedFile(rejectedFile)
    );
    return [...failedMessages, ...rejectedFiles];
  }

  private static generateErrorMessageFromFailedUpload(
    failedUpload: UploadFilesOutput.Type["failedFiles"][number]
  ): string {
    const file = failedUpload.file;
    const name = file.providedName ?? file.originalName ?? file.id;
    if (failedUpload.error) {
      return `'${name}' kunde inte laddas upp med felmeddelande '${failedUpload.error}'`;
    } else {
      return `'${name}' kunde inte laddas upp av okänd anledning`;
    }
  }

  private static generateErrorMessageFromRejectedFile(
    rejectedFile: UploadFilesOutput.Type["rejectedFiles"][number]
  ): string {
    const obj = rejectedFile;
    if (obj.reason === "INVALID_DATA_URL") {
      if (obj.providedName) {
        return `'${obj.providedName}' kunde inte laddas upp på grund av en ogiltig data-url`;
      } else {
        return `En av filerna kunde inte laddas upp på grund av en ogiltig data-url`;
      }
    }

    if (obj.reason === "INVALID_META_DATA") {
      return `En av filerna kunde inte laddas upp på grund av ogiltig metadata`;
    }

    const file = obj.file;
    const name = file.providedName ?? file.originalName ?? file.id;

    if (obj.reason === "COULD_NOT_DETERMINE_FILE_TYPE") {
      return `'${name}' kunde inte laddas upp eftersom filtypen inte kunde bestämmas`;
    }

    if (obj.reason === "DISALLOWED_FILE_TYPE") {
      return `'${name}' kunde inte laddas upp eftersom filtypen .${file.extension} inte är tillåten`;
    }

    if (obj.reason === "FAILED_HEIC_CONVERSION") {
      return `'${name}' kunde inte laddas upp eftersom filen inte kunde konverteras från HEIC/HEIF till en giltig filtyp`;
    }

    return `En av filerna kunde inte laddas upp av okänd anledning`;
  }
}
