"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidInputFile = void 0;
var AllowedFileExtensions_1 = require("../../../domain/AllowedFileExtensions");
var zod_1 = require("zod");
var FileInputInfo_1 = require("./FileInputInfo");
var InvalidInputFile;
(function (InvalidInputFile) {
    InvalidInputFile.DataUrlSchema = zod_1.z.object({
        reason: zod_1.z.literal("INVALID_DATA_URL"),
        dataUrl: zod_1.z.string(),
        error: zod_1.z.string(),
        providedName: zod_1.z.string().optional(),
        providedDescription: zod_1.z.string().optional(),
    });
    InvalidInputFile.UnrecognizedFileTypeSchema = zod_1.z.object({
        reason: zod_1.z.literal("COULD_NOT_DETERMINE_FILE_TYPE"),
        file: FileInputInfo_1.FileInputInfo.Schema,
    });
    InvalidInputFile.InvalidMetaDataSchema = zod_1.z.object({
        reason: zod_1.z.literal("INVALID_META_DATA"),
        error: zod_1.z.instanceof(zod_1.ZodError),
    });
    InvalidInputFile.DisallowedFileTypeSchema = zod_1.z.object({
        reason: zod_1.z.literal("DISALLOWED_FILE_TYPE"),
        file: FileInputInfo_1.FileInputInfo.Schema,
        supposedExtension: zod_1.z.string(),
        supposedMimeType: zod_1.z.string(),
        determinedExtension: zod_1.z.string(),
        determinedMimeType: zod_1.z.string(),
        allowedExtensions: AllowedFileExtensions_1.AllowedFileExtensions.All.array(),
    });
    InvalidInputFile.DisallowedFileTypeInputSchema = zod_1.z.object({
        reason: zod_1.z.literal("DISALLOWED_FILE_TYPE_INPUT"),
        file: FileInputInfo_1.FileInputInfo.Schema,
        supposedExtension: zod_1.z.string(),
        determinedMimeType: zod_1.z.string(),
        allowedExtensions: AllowedFileExtensions_1.AllowedFileExtensions.All.array(),
    });
    InvalidInputFile.FailedHeicConversionSchema = zod_1.z.object({
        reason: zod_1.z.literal("FAILED_HEIC_CONVERSION"),
        file: FileInputInfo_1.FileInputInfo.Schema,
    });
    InvalidInputFile.Schema = zod_1.z.union([
        InvalidInputFile.DataUrlSchema,
        InvalidInputFile.InvalidMetaDataSchema,
        InvalidInputFile.UnrecognizedFileTypeSchema,
        InvalidInputFile.DisallowedFileTypeSchema,
        InvalidInputFile.DisallowedFileTypeInputSchema,
        InvalidInputFile.FailedHeicConversionSchema,
    ]);
})(InvalidInputFile || (InvalidInputFile = {}));
exports.InvalidInputFile = InvalidInputFile;
