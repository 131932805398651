import { IpisFormElement } from "@eljouren/domain";
import { useContext } from "react";
import FormBuilderContext from "../components/checklist/v2/builder/FormBuilderContext";
import { FormEditorContext } from "../components/checklist/v2/builder/layout/editor/FormEditorContextProvider";
import ChecklistPageContext from "../components/checklist/v2/checklist-page/ChecklistPageContext";
import {
  FormContext,
  FormRenderContext,
} from "../components/checklist/v2/ChecklistContexts";

export function useFormEditor() {
  const ctx = useContext(FormEditorContext);
  if (!ctx) {
    throw new Error("FormEditorContext not found");
  }
  return ctx;
}

export function useOptionalFormEditor():
  | ReturnType<typeof useFormEditor>
  | undefined {
  const ctx = useContext(FormEditorContext);
  return ctx;
}

export function useFormBuilder() {
  const ctx = useContext(FormBuilderContext);
  return ctx;
}

export function useIpisForm() {
  const ctx = useContext(FormContext);
  return ctx;
}

export function useChecklistRenderOptions() {
  const ctx = useContext(FormRenderContext);
  return ctx;
}

export function useChecklistPage() {
  const ctx = useContext(ChecklistPageContext);
  return ctx;
}

export function useChecklistError(question: IpisFormElement.Type) {
  const ctx = useChecklistPage();

  const error = ctx.form.formState.errors[question.id];

  if (!error || typeof error.message !== "string") {
    return undefined;
  }

  return error.message;
}

export function useChecklistConditions(
  question: IpisFormElement.Type
): boolean {
  const checklist = useIpisForm();
  const page = useChecklistPage();
  if (checklist.byPassConditionalValidation) {
    return true;
  }

  if (!question.conditionGroups?.length) {
    return true;
  }

  const check = page.validatedChecklist;
  const withState = check.getElement(question.id);
  const conditionsMet = withState.state?.meetsConditions;
  return !!conditionsMet;
}
