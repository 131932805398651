"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormError = useFormError;
exports.useFormValue = useFormValue;
exports.useFormRegisterProps = useFormRegisterProps;
var react_1 = require("react");
function useFormError(args) {
    if (!args.form) {
        return undefined;
    }
    var errors = args.form.formState.errors;
    var subpaths = args.name.split(".");
    var first = subpaths[0], rest = subpaths.slice(1);
    var _error = errors[first];
    for (var _i = 0, rest_1 = rest; _i < rest_1.length; _i++) {
        var subpath = rest_1[_i];
        if (_error === undefined || typeof _error !== "object") {
            break;
        }
        _error = _error[subpath];
    }
    if (typeof _error === "object" && typeof _error.message === "string") {
        return _error.message;
    }
    return undefined;
}
function useFormValue(props, settings) {
    if (settings === void 0) { settings = {}; }
    var defaultValueSetRef = (0, react_1.useRef)(false);
    function getValue() {
        if (props.form) {
            return props.form.watch(props.name);
        }
        else if (props.controls) {
            return props.controls.value;
        }
        return undefined;
    }
    var value = getValue();
    var form = props.form;
    var controls = props.controls;
    var defaultValue = settings.defaultValue;
    (0, react_1.useEffect)(function () {
        if (defaultValueSetRef.current) {
            return;
        }
        if (defaultValue === undefined && !settings.setDefaultValueAsUndefined) {
            return;
        }
        var currentVal = controls === null || controls === void 0 ? void 0 : controls.value;
        if (!(controls === null || controls === void 0 ? void 0 : controls.onChange) || currentVal !== undefined) {
            return;
        }
        controls.onChange(defaultValue);
        defaultValueSetRef.current = true;
    }, [controls, defaultValue]);
    (0, react_1.useEffect)(function () {
        if (defaultValueSetRef.current) {
            return;
        }
        if (defaultValue === undefined && !settings.setDefaultValueAsUndefined) {
            return;
        }
        if (!form) {
            return;
        }
        var value = form.getValues(props.name);
        if (value !== undefined) {
            return;
        }
        form.setValue(props.name, defaultValue);
        defaultValueSetRef.current = true;
    }, 
    /*
    Using form as a dependency here used to work, but since react-hook-form 7.54.0,
    this causes an infinite rerender loop.

    While I will still downgrade react-hook-form back to 7.53.2, Iäve removed the form
    dependency here as a way to avoid this happening again.
  */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.name, defaultValue]);
    return value;
}
function processValue(args) {
    var rootArgs = args.rootArgs, val = args.val, defaultTransformation = args.defaultTransformation;
    if (rootArgs.transformValue) {
        return rootArgs.transformValue(val);
    }
    if (defaultTransformation) {
        return defaultTransformation(val);
    }
    return val;
}
/*
  Needs to take readyonly and disabled into account, from args and from context
*/
function useOnChange(args) {
    if (!args.props.controls) {
        return function () { };
    }
    var callback = args.props.controls.onChange;
    if (args.type === "select") {
        return function (e) {
            var val = processValue({
                rootArgs: args,
                val: e.target.value,
            });
            callback === null || callback === void 0 ? void 0 : callback(val);
        };
    }
    if (args.type === "textarea" || args.type === "text") {
        return function (e) {
            var val = processValue({
                rootArgs: args,
                val: e.target.value,
            });
            callback === null || callback === void 0 ? void 0 : callback(val);
        };
    }
    if (args.type === "number") {
        return function (e) {
            var val = processValue({
                rootArgs: args,
                val: e.target.value,
                defaultTransformation: function (val) {
                    var number = parseFloat(val);
                    return isNaN(number) ? undefined : number;
                },
            });
            callback === null || callback === void 0 ? void 0 : callback(val);
        };
    }
    return function () { };
}
function useFormRegisterProps(args) {
    var props = args.props, type = args.type;
    var onChange = useOnChange(args);
    var base = {
        onBlur: args.props.onBlur,
        onFocus: args.props.onFocus,
    };
    var setValueAs = function (defaultTransformation) { return function (val) {
        var processedValue = processValue({
            rootArgs: args,
            val: val,
            defaultTransformation: defaultTransformation,
        });
        return processedValue;
    }; };
    if (props.form) {
        var registerProps = void 0;
        switch (type) {
            case "select":
                registerProps = props.form.register(props.name, {
                    setValueAs: setValueAs(),
                });
                break;
            case "textarea":
            case "text":
                registerProps = props.form.register(props.name, {
                    minLength: props.minLength,
                    maxLength: props.maxLength,
                    setValueAs: setValueAs(),
                });
                break;
            case "number":
                registerProps = props.form.register(props.name, {
                    min: props.min,
                    max: props.max,
                    setValueAs: setValueAs(function (val) {
                        var number = parseFloat(val);
                        return isNaN(number) ? undefined : number;
                    }),
                });
                break;
            case "radio":
                throw new Error("Radio not implemented; the setValueAs function apparently only works for textinputs,\n          and I need to transform the value. Writing a custom onChange function kind of works,\n          but the ref from RHF somehow overrides that value. And omitting the ref makes it so that\n          it wont get focused on error.");
            /*  registerProps = {
                name: rhfResult.name,
                ref: rhfResult.ref,
                onBlur: rhfResult.onBlur,
                onChange: async (e: any) => {
                  const event = e as React.ChangeEvent<HTMLInputElement>;
                  const rawValue = event.target.value;
                  const value = processValue({
                    rootArgs: args,
                    val: rawValue,
                  });
                  console.log({
                    rawValue,
                    value,
                    name: props.name,
                    hasForm: !!props.form,
                  });
                  props.form?.clearErrors(props.name);
                  props.form?.setValue(props.name, value);
                },
              };
              break;*/
        }
        return __assign(__assign({}, base), registerProps);
    }
    if (props.controls) {
        var controlledProps = {
            value: props.controls.value,
            onChange: onChange,
        };
        return __assign(__assign({}, base), controlledProps);
    }
    return base;
}
