import { Checklist, ChecklistAnswers } from "@eljouren/domain/build";
import ClientUtils from "./../../../../utils/ClientUtils";

import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "@ipis/centralized-zod";
import SaveAndGoBackFooter from "../../../../components/SaveAndGoBackFooter";
import ScrollIndicatorContainer from "../../../../components/ScrollIndicatorContainer";
import { AppLoader } from "../../../../components/common/loaders/AppLoader";
import { useAddToBackButtonStack } from "../../../../components/common/navigation/use-back-button-stack";
import useBeforeBackButton from "../../../../components/common/navigation/use-before-back-button";
import { useApiClients } from "../../../../hooks/use-api-clients";
import HandymanWorkOrderRouteContext from "../contexts/HandymanWorkOrderRouteContext";
import ChecklistImageUploadView from "./ChecklistImageListSection";

const noOption = "no-option";

const FormSchema = z.record(
  z.string(),
  z.object({
    answer: z.string(),
    comment: z.string().optional().nullable(),
  })
);

export type TChecklistFormValues = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
  goBack(): void;
  //onSubmit();
}

function getDefaultValues(data: Checklist.Type | undefined) {
  if (!data) {
    return undefined;
  }

  const items = data.images.items;
  const entries = items
    .filter((item) => {
      return !!item.comment || !!item.answer;
    })
    .map((item) => [
      item.id,
      { answer: item.answer ?? noOption, comment: item.comment },
    ]);
  const values = Object.fromEntries(entries);
  return values;
}

const WorkOrderChecklistImageModule = (props: Props) => {
  const { workOrderRepo } = useApiClients();
  const { checklistRes, order } = useContext(HandymanWorkOrderRouteContext);
  //const readOnly = !order.allowedToHandleOrder || order.isFinished;

  useAddToBackButtonStack(props.goBack);
  const form = useForm<TChecklistFormValues>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(checklistRes.query.data),
  });

  useEffect(
    () => {
      const values = getDefaultValues(checklistRes.query.data);
      form.reset(values);
    },
    /* 
    Using form as a dependency here used to work, but since react-hook-form 7.54.0, 
    this causes an infinite rerender loop.

    While I will still downgrade react-hook-form back to 7.53.2, Iäve removed the form
    dependency here as a way to avoid this happening again.
  */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checklistRes.query.data]
  );

  const beforeBack = useBeforeBackButton(() => {
    if (!form.formState.isDirty) {
      return false;
    }

    return async () => {
      const shouldSave = await window.modal.confirm({
        title: "Vill du spara ändringarna?",
        prompt: "Du har ändringar som inte är sparade.",
        yesLabel: "Spara",
        noLabel: "Avbryt",
      });

      if (shouldSave) {
        await form.handleSubmit(onSubmit)();
      }

      return true;
    };
  });

  async function onBack() {
    const res = await beforeBack();
    if (res) {
      props.goBack();
    }
  }

  async function onSubmit(values: TChecklistFormValues) {
    if (!checklistRes.query.data) {
      return;
    }
    const answers = Object.entries(values)
      .filter(([id, value]) => {
        if (!value) {
          return false;
        }
        return value.answer !== noOption || !!value.comment;
      })
      .map(([id, value]): ChecklistAnswers.SingleAnswerType => {
        return {
          checklistItemId: id,
          answer: value.answer === noOption ? "" : value.answer,
          comment: value.comment ?? null,
        };
      });
    try {
      await checklistRes.mutate({
        callback: () =>
          workOrderRepo.reportChecklistAnswers({
            workOrderId: order.orderId,
            answers,
          }),
      });

      //props.onSubmit();
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att rapportera in svar just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  /* function onError(errors: FieldErrors<TChecklistFormValues>) {
    console.log({ errors });
  } */

  const isLoading = checklistRes.query.isLoading;
  const isError = checklistRes.query.isError;
  const data = checklistRes.query.data;
  const hasData = !isError && data;
  const isLoadingWithoutData = isLoading && !hasData;

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={ClientUtils.twClassNames(
        "grid h-full w-full grid-cols-1 grid-rows-[minmax(0,1fr),auto]",
        props.className
      )}
    >
      {isLoadingWithoutData && <AppLoader />}
      {hasData && (
        <>
          <ScrollIndicatorContainer as="main" innerClassName="flex flex-col">
            <ChecklistImageUploadView checklist={data} />
          </ScrollIndicatorContainer>
        </>
      )}

      <SaveAndGoBackFooter onGoBack={onBack} readonly />
    </motion.section>
  );
};

export default WorkOrderChecklistImageModule;
