import { UploadFilesOutput } from "@eljouren/file-schemas";
import { CustomFileInputFile } from "../../components/files/FileInputButton";
import trpcClient from "../../trpc-setup";
import ClientWorkOrderFileRepoV2 from "../repos/ClientWorkOrderFileRepoV2";

/* 
	Not sure if this taking another api client (or repo, like the old and incorrect terminology),
	is the correct way to go about this.
*/
export default class HandymanWorkOrderApiClient {
  constructor(
    private props: {
      workOrderFileServerApiClient: ClientWorkOrderFileRepoV2;
    }
  ) {}

  /* 
 	Could probably use a better name 

	When/if we've created a serverside method for reverting the answer to the question,
	we should call that in case the image uploading fails.
  */
  async submitCompletionFormImageQuestionAnswer(args: {
    workOrderId: string;
    questionId: string;
    comment: string | null;
    images: CustomFileInputFile[];
  }): Promise<UploadFilesOutput.Type> {
    const res =
      await trpcClient.handymanWorkOrderCompletionFormRouter.submitImageAnswer.mutate(
        {
          workOrderId: args.workOrderId,
          questionId: args.questionId,
          comment: args.comment,
        }
      );

    const presignedUrlResponse = res.presignedUrlResponse;

    const images =
      await this.props.workOrderFileServerApiClient.uploadWithPresignedUrl({
        url: presignedUrlResponse.url,
        files: args.images,
      });

    return images;
  }
}
