import ClientUtils from "../../../utils/ClientUtils";
interface Props {
  className?: string;
}

const SnickareOnlineLogo = (props: Props) => {
  return (
    <img
      className={ClientUtils.twClassNames(
        "rounded",
        //!props.className && "h-40 max-h-full rounded",
        props.className
      )}
      src={"/logos/snickare-online.svg"}
      data-testid="ipis-logo"
      alt="Logga för Ipis"
    />
  );
};

export default SnickareOnlineLogo;
