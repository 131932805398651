import { WorkOrder, WorkOrderConfirmationV2 } from "@eljouren/domain";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DeepPartial } from "react-hook-form";
import { FormProgressIndicator } from "@ipis/client-essentials";
import { CustomerAuthContext } from "../../../../components/auth/hocs/withCustomerGuidV2";
import AppPages from "../../../../components/common/pages/AppPages";
import { FileUploadNewFile } from "../../../../hooks/file-upload-hooks";
import { useApiClients } from "../../../../hooks/use-api-clients";
import { CustomerWorkOrderFileContext } from "../../CustomerWorkOrderFileContextProvider";
import CustomerWorkOrderFormConfirmationPageV2 from "./confirmation-page/CustomerWorkOrderFormConfirmationPageV2";
import CustomerWorkOrderFormInformationPage, {
  CustomerWorkOrderFormInformationPageFormSchema,
  CustomerWorkOrderFormInformationPageFormValues,
} from "./information-page/CustomerWorkOrderFormInformationPage";
import CustomerWorkOrderFormInvoicePage, {
  CustomerWorkOrderFormInvoicePageFormSchema,
  CustomerWorkOrderFormInvoicePageFormValues,
} from "./invoice-page/CustomerWorkOrderFormInvoicePage";

export const CustomerWorkOrderFormContext = React.createContext<{
  workOrder: WorkOrder.CustomerType;
}>({} as never);

interface Props {
  className?: string;
  workOrder: WorkOrder.CustomerType;
}

enum Page {
  information,
  invoice,
  confirmation,
}

type ValidationReturn =
  | {
      validated: true;
      data: WorkOrderConfirmationV2.Type;
      files: FileUploadNewFile[];
    }
  | {
      validated: false;
      failedOnInformation: boolean;
      failedOnInvoice: boolean;
      failedOnConversion: boolean;
    };

const CustomerWorkOrderFormSectionV2 = (props: Props) => {
  const repos = useApiClients();
  const ctx = useContext(CustomerAuthContext);
  const workOrder = props.workOrder;
  const isSubmitting = ctx.workOrderRes.mutation.isLoading;
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [page, setPage] = useState<Page>(getInitialPage());
  const fileCtx = useContext(CustomerWorkOrderFileContext);

  function getInitialPage(): Page {
    if (workOrder.confirmedByCustomer) {
      return Page.confirmation;
    }
    return Page.information;
  }

  useEffect(() => {
    console.warn(
      "Reminder to fix v2 versions of the confirmation modals; to set the correct default values in the summary; Reminder to validate the schema correctly and to figure out what the 'defaultInvoiceMail' prop is for, and implement a proper loading state, error state;"
    );
  });

  const informationPageValuesRef =
    useRef<CustomerWorkOrderFormInformationPageFormValues | null>(null);
  const invoicePageValuesRef =
    useRef<DeepPartial<CustomerWorkOrderFormInvoicePageFormValues> | null>(
      null
    );

  function onInformationPageSubmit(
    values: CustomerWorkOrderFormInformationPageFormValues
  ) {
    informationPageValuesRef.current = values;
    setPage(Page.invoice);
  }

  function onInvoicePageSubmit(
    values: CustomerWorkOrderFormInvoicePageFormValues
  ) {
    invoicePageValuesRef.current = values;
    commit();
  }

  useEffect(() => {
    const confirmed = workOrder.confirmedByCustomer;
    console.log({ confirmed });
    if (confirmed && page !== Page.confirmation) {
      setPage(Page.confirmation);
    }

    if (!confirmed && page === Page.confirmation) {
      setPage(Page.information);
    }
  }, [workOrder, page]);

  function validate(): ValidationReturn {
    const informationSafeParse =
      CustomerWorkOrderFormInformationPageFormSchema.safeParse(
        informationPageValuesRef.current
      );
    const invoiceSafeParse =
      CustomerWorkOrderFormInvoicePageFormSchema.safeParse(
        invoicePageValuesRef.current
      );

    const infoSuccess = informationSafeParse.success;
    const invoiceSuccess = invoiceSafeParse.success;

    if (!infoSuccess || !invoiceSuccess) {
      return {
        validated: false,
        failedOnInformation: !infoSuccess,
        failedOnInvoice: !invoiceSuccess,
        failedOnConversion: false,
      };
    }

    const info = informationSafeParse.data;
    const invoice = invoiceSafeParse.data;

    const raw: Record<keyof WorkOrderConfirmationV2.Type, any> = {
      isBusinessCustomer: workOrder.isBusinessCustomer,
      externalPaymentFlowAllowed:
        workOrder.paymentDetails.externalPaymentFlowAllowed,
      customerHasActivelyChosenToSkipTaxReduction:
        invoice.customerActivelyChoseToSkipTaxReduction,
      isInsuranceCase: invoice.isInsuranceCase,
      // Todo, need to add it to the invoice fields
      internalInvoiceInformation: undefined,
      housing: invoice.housing,

      location: info.location,
      contact: info.contact,
      gdprConfirmed: invoice.gdprConfirmed,
    };

    const factors: WorkOrderConfirmationV2.Factors = {
      isBusinessCustomer: raw.isBusinessCustomer,
      externalPaymentFlowAllowed: raw.externalPaymentFlowAllowed,
      customerHasActivelyChosenToSkipTaxReduction:
        raw.customerHasActivelyChosenToSkipTaxReduction,
      isInsuranceCase: raw.isInsuranceCase,
    };

    const Schema = WorkOrderConfirmationV2.Schema(factors);

    const safeParse = Schema.safeParse(raw);

    if (!safeParse.success) {
      return {
        validated: false,
        failedOnInformation: false,
        failedOnInvoice: false,
        failedOnConversion: true,
      };
    }

    const files: FileUploadNewFile[] = info.images.filter(
      (file): file is FileUploadNewFile => file.state === "preupload"
    );

    return {
      validated: true,
      data: safeParse.data,
      files,
    };
  }

  function onInvoicePageGoBack(
    values: DeepPartial<CustomerWorkOrderFormInvoicePageFormValues>
  ) {
    invoicePageValuesRef.current = values;
    setPage(Page.information);
  }

  function resetSubmitError() {
    setHasSubmitError(false);
  }

  function alertAndGoBackToInformationPage() {
    window.modal.alert({
      title: "Informationen på första sidan saknar viktig information",
      prompt: "Vänligen fyll i alla fält innan du går vidare",
      typeOfAlert: "error",
    });
    setPage(Page.information);
  }

  function alertAndGoBackToInvoicePage() {
    window.modal.alert({
      title: "Informationen på andra sidan saknar viktig information",
      prompt: "Vänligen fyll i alla fält innan du går vidare",
      typeOfAlert: "error",
    });
    setPage(Page.invoice);
  }

  function alertOfFailedConversion() {
    window.modal.alert({
      title: "Vi kunde inte bekräfta uppdraget",
      prompt:
        "Tyvärr gick det inte att bekräfta uppdraget just nu. Vänligen försök igen senare.",
      typeOfAlert: "error",
    });
  }

  function onReset() {
    if (process.env.NODE_ENV !== "development") {
      return;
    }
    ctx.workOrderRes.mutate({
      callback: () => repos.workOrderRepo.reset(ctx.workOrderRes.query.data!),
    });
  }

  async function commit() {
    try {
      const validationResult = validate();

      if (!validationResult.validated) {
        if (validationResult.failedOnInformation) {
          return alertAndGoBackToInformationPage();
        } else if (validationResult.failedOnInvoice) {
          return alertAndGoBackToInvoicePage();
        } else {
          return alertOfFailedConversion();
        }
      }

      await ctx.workOrderRes.mutate({
        callback: () => {
          return repos.customerWorkOrderRepo.confirmWorkOrder({
            values: validationResult.data,
          });
        },
      });

      await uploadFiles({ files: validationResult.files });
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att bekräfta uppdraget just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  async function uploadFiles(files: { files: FileUploadNewFile[] }) {
    try {
      if (files.files.length === 0) {
        return;
      }

      await fileCtx.fileRes.mutate({
        callback: () => {
          return repos.workOrderFileRepo.uploadAsCustomer({
            files: files.files,
          });
        },
      });
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att ladda upp filer just nu",
        prompt: "Vi kontaktar dig om vi behöver mer information.",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  return (
    <CustomerWorkOrderFormContext.Provider
      value={{
        workOrder,
      }}
    >
      <section className="mx-auto grid h-full w-full max-w-screen-md grid-cols-1 grid-rows-[auto,minmax(0,1fr)] pb-4">
        {page !== Page.confirmation && (
          <motion.aside
            className="flex flex-col"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <FormProgressIndicator
              pages={[
                { label: "Information" },
                { label: "Fakturering" },
                { label: "Bekräftelse" },
              ]}
              currentStep={page}
            />
          </motion.aside>
        )}
        <AppPages pageIndex={page} onlyMain className="overflow-y-auto p-4">
          {page === Page.information && (
            <CustomerWorkOrderFormInformationPage
              onSubmit={onInformationPageSubmit}
              defaultValues={
                informationPageValuesRef.current ?? {
                  images: [],
                  location: {
                    city: workOrder.location.city,
                    postalCode: workOrder.location.postalCode,
                    street: workOrder.location.street,
                    doorCode: workOrder.location.doorCode,
                    floor: workOrder.location.floor,
                    country: workOrder.location.country,
                  },
                  contact: {
                    email: workOrder.contact.email,
                    fullName: workOrder.contact.fullName,
                    phone: workOrder.contact.phone,
                  },
                }
              }
            />
          )}
          {page === Page.invoice && (
            <CustomerWorkOrderFormInvoicePage
              hasSubmitError={hasSubmitError}
              resetSubmitError={resetSubmitError}
              onSubmit={onInvoicePageSubmit}
              defaultValues={invoicePageValuesRef.current ?? undefined}
              onGoBack={onInvoicePageGoBack}
              isSubmitting={isSubmitting}
            />
          )}
          {page === Page.confirmation && (
            <CustomerWorkOrderFormConfirmationPageV2 onReset={onReset} />
          )}
        </AppPages>
      </section>
    </CustomerWorkOrderFormContext.Provider>
  );
};

/* 
<motion.form
        className={ClientUtils.classNames(
          "relative mx-auto flex h-full w-full max-w-screen-sm flex-col items-center gap-2 overflow-y-auto border py-6",
          isLoading && "pointer-events-none",
          props.className
        )}
        onSubmit={form.handleSubmit(onSubmit, onErrors)}
        animate={{
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <header className="flex flex-col items-center gap-2 py-6">
          <h2 className="pt-4 text-2xl">Tack för Er bokning!</h2>
          <p>Vänligen kontrollera att information nedan stämmer</p>
        </header>
        <main className="flex w-full flex-col gap-4">
          <WorkOrderFormDescriptionExpandableCard />
          <WorkOrderFormLocationExpandableCard />
          <WorkOrderFormContactExpandableSection />
        </main>
        <footer className="flex w-full max-w-[500px] flex-col items-center gap-4 p-4 pt-8">
          <GDPRLinkAndConfirmationCheckbox form={form} />
          <AppButton className="w-full" type="submit">
            Informationen stämmer, spara!
          </AppButton>
        </footer>
      </motion.form>
*/

export default CustomerWorkOrderFormSectionV2;
