import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { FormInputContext } from "@ipis/client-essentials";
import { useContext } from "react";

type UseCase = "workOrderConfirmationForm" | "orderLineReview";

interface Props<T extends FieldValues> {
  name: Path<T>;
  form: UseFormReturn<T>;
  useCase: UseCase;
}

const GDPRLinkAndConfirmationCheckboxV2 = <T extends FieldValues>(
  props: Props<T>
) => {
  const ctx = useContext(FormInputContext);

  const useCase = props.useCase;
  if (useCase === "workOrderConfirmationForm") {
    return (
      <div className="my-8 grid grid-cols-[minmax(0,1fr),auto] gap-3 rounded border border-transparent py-3 text-sm focus-within:border-main-bg-dark">
        <label htmlFor="confirmCheckbox">
          Jag godkänner IPIS AB:s{" "}
          <a
            href="https://ipisverige.se/kundservice-allmanna-villkor"
            className="italic text-blue-600 underline"
            target={"_blank"}
            rel="noreferrer"
          >
            IPIS AB:s allmänna villkor och integritetspolicy
          </a>{" "}
          och accepterar att de lagrar och bearbetar mina uppgifter.
        </label>
        <input
          type="checkbox"
          id="confirmCheckbox"
          {...props.form.register(props.name)}
          className="m-auto h-6 w-6 cursor-pointer"
          required
          readOnly={!!ctx?.readOnly}
          disabled={!!ctx?.readOnly}
        />
      </div>
    );
  }

  if (useCase === "orderLineReview") {
    return (
      <div className="my-8 grid grid-cols-[minmax(0,1fr),auto] gap-3 rounded border border-transparent py-3 text-sm focus-within:border-main-bg-dark">
        <label htmlFor="confirmCheckbox">
          Jag godkänner IPIS AB:s{" "}
          <a
            href="https://ipisverige.se/kundservice-allmanna-villkor"
            className="italic text-blue-600 underline"
            target={"_blank"}
            rel="noreferrer"
          >
            IPIS AB:s allmänna villkor och integritetspolicy
          </a>{" "}
          och är redo att skriva under med BankID-appen.
        </label>
        <input
          type="checkbox"
          id="confirmCheckbox"
          {...props.form.register(props.name)}
          className="m-auto h-6 w-6 cursor-pointer"
          required
          readOnly={!!ctx?.readOnly}
          disabled={!!ctx?.readOnly}
        />
      </div>
    );
  }

  return <></>;
};

export default GDPRLinkAndConfirmationCheckboxV2;
