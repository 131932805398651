import { WorkOrder } from "@eljouren/domain/build";
import ClientUtils from "./../../../../../utils/ClientUtils";

import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useApiClients } from "../../../../../hooks/use-api-clients";
import HandymanWorkOrderRouteContext from "../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import SaveAndGoBackFooter from "../../../../SaveAndGoBackFooter";
import { useAddToBackButtonStack } from "../../../../common/navigation/use-back-button-stack";
import useBeforeBackButton from "../../../../common/navigation/use-before-back-button";
import { AppFormTextField } from "../../../../common/text-fields/AppFormTextField";

type FormValues = Pick<WorkOrder.HandymanType, "externalReference">;

interface Props {
  className?: string;
  goBack(): void;
}

const WorkOrderExternalReferenceSection = (props: Props) => {
  const { orderRes, order } = useContext(HandymanWorkOrderRouteContext);

  const form = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      externalReference: order.externalReference,
    },
  });

  const { workOrderRepo } = useApiClients();

  const beforeBack = useBeforeBackButton(() => {
    if (!form.formState.isDirty) {
      return false;
    }

    return async () => {
      const shouldSave = await window.modal.confirm({
        title: "Vill du spara ändringarna?",
        prompt: "Du har ändringar som inte är sparade.",
        yesLabel: "Spara",
        noLabel: "Avbryt",
      });

      if (shouldSave) {
        await form.handleSubmit(onSubmit)();
      }

      return true;
    };
  });

  useAddToBackButtonStack(props.goBack);

  async function onGoBack() {
    const value = await beforeBack();
    if (value) {
      props.goBack();
    }
  }

  async function onSubmit(values: FormValues) {
    try {
      await orderRes.mutate({
        callback: async () => {
          return workOrderRepo.edit({
            workOrderId: order.orderId,
            ...values,
          });
        },
        optimisticUpdate: {
          ...order,
          ...values,
        },
      });
      form.reset(values);
    } catch (ex) {
      window.modal.alert({
        title: "Det gick inte spara informationen just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: ex,
      });
    }
  }

  return (
    <section
      className={ClientUtils.twClassNames(
        "grid h-full grid-cols-1 grid-rows-1",
        props.className
      )}
    >
      <form
        className="grid h-full w-full grid-cols-1 grid-rows-[minmax(0,1fr),auto] gap-4 "
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <main className="flex flex-col gap-4 p-4">
          <AppFormTextField
            name="externalReference"
            register={form.register}
            label="Externt referensnummer"
            htmlAttributes={{
              readOnly: order.isFinished,
              maxLength: 250,
            }}
          />

          <span className="text-sm">
            Vissa av våra partners har sitt egna referensnummer till
            arbetsordern. Om du har fått ett sådant nummer kan du fylla i det
            här, så hamnar det även på fakturaunderlaget.
          </span>
        </main>

        <SaveAndGoBackFooter
          onGoBack={onGoBack}
          readonly={order.isFinished}
          onSave={form.handleSubmit(onSubmit)}
        />
      </form>
    </section>
  );
};

export default WorkOrderExternalReferenceSection;
