import { UseFormReturn } from "react-hook-form";
import { AppFormCheckbox } from "../../common/checkboxes/AppFormCheckbox";
import Dropdown, { DropdownProps } from "../../common/dropdown/Dropdown";
import ClientUtils from "../../../utils/ClientUtils";
import {
  HandymanWorkOrderFileContext,
  THandymanFileFilters,
} from "./DecoupledWorkOrderFileContextProvider";

import { useContext } from "react";

interface Props extends Partial<DropdownProps> {
  className?: string;
  form: UseFormReturn<THandymanFileFilters>;
}

const DecoupledWorkOrderFileFilterDropdown = (props: Props) => {
  const { className, form, ...rest } = props;
  //const routeCtx = useContext(HandymanWorkOrderRouteContext);
  const fileCtx = useContext(HandymanWorkOrderFileContext);

  const workOrderFilesLength =
    (fileCtx.workOrderDocuments?.length ?? 0) +
    (fileCtx.workOrderImages?.length ?? 0);
  const workOrderLineItemFilesLength =
    (fileCtx.workOrderLineItemDocuments?.length ?? 0) +
    (fileCtx.workOrderLineItemImages?.length ?? 0);
  const opportunityLineItemFilesLength =
    (fileCtx.opportunityLineItemDocuments?.length ?? 0) +
    (fileCtx.opportunityLineItemImages?.length ?? 0);
  const productPackageFilesLength =
    (fileCtx.productPackageDocuments?.length ?? 0) +
    (fileCtx.productPackageImages?.length ?? 0);
  const serviceContractFilesLength =
    (fileCtx.serviceContractDocuments?.length ?? 0) +
    (fileCtx.serviceContractImages?.length ?? 0);
  const preparationFormFilesLength = fileCtx.preparationFormImages?.length ?? 0;

  return (
    <Dropdown
      title="Filtrera"
      className={ClientUtils.twClassNames(props.className)}
      buttonClassName="h-full text-lg font-semibold p-2 justify-center"
      contentClassName="w-full"
      absolute
      {...rest}
    >
      <form className="flex flex-col gap-2 p-2">
        <AppFormCheckbox
          labelClassName="text-base"
          register={props.form.register}
          name="workOrderFiles"
          label={`Kopplad till arbetsorder (${workOrderFilesLength})`}
          type="checkbox"
          htmlAttributes={{
            disabled: !workOrderFilesLength,
          }}
        />
        <AppFormCheckbox
          labelClassName="text-base"
          register={props.form.register}
          name="workOrderLineItemFiles"
          label={`Kopplad till material (${workOrderLineItemFilesLength})`}
          type="checkbox"
          htmlAttributes={{
            disabled: !workOrderLineItemFilesLength,
          }}
        />
        <AppFormCheckbox
          labelClassName="text-base"
          register={props.form.register}
          name="opportunityLineItemFiles"
          label={`Kopplad till faktura (${opportunityLineItemFilesLength})`}
          type="checkbox"
          htmlAttributes={{
            disabled: !opportunityLineItemFilesLength,
          }}
        />
        <AppFormCheckbox
          labelClassName="text-base"
          register={props.form.register}
          name="productPackageFiles"
          label={`Kopplad till produktpaket (${productPackageFilesLength})`}
          type="checkbox"
          htmlAttributes={{
            disabled: !productPackageFilesLength,
          }}
        />

        {!!fileCtx.hasServiceContract && (
          <AppFormCheckbox
            labelClassName="text-base"
            register={props.form.register}
            name="serviceContractFiles"
            label={`Kopplad till serviceavtal (${serviceContractFilesLength})`}
            type="checkbox"
            htmlAttributes={{
              disabled: !serviceContractFilesLength,
            }}
          />
        )}
        {!!fileCtx.hasPreparationsForm && (
          <AppFormCheckbox
            labelClassName="text-base"
            register={props.form.register}
            name="preparationFormFiles"
            label={`Kopplad till förberedelseformulär (${preparationFormFilesLength})`}
            type="checkbox"
            htmlAttributes={{
              disabled: !preparationFormFilesLength,
            }}
          />
        )}
      </form>
    </Dropdown>
  );
};

export default DecoupledWorkOrderFileFilterDropdown;
