import ClientUtils from "./../../utils/ClientUtils";
import { useEffect } from "react";
import withSignUpId from "../../components/auth/hocs/withSignUpId";
import SignUpForm from "../../components/forms/SignUpForm";
import PageHeader from "../../components/layout/PageHeader";
import { useApiClients } from "../../hooks/use-api-clients";

const SignUpRoute = () => {
  const { authRepo } = useApiClients();
  useEffect(() => {
    authRepo.signOut();
  }, [authRepo]);

  return (
    <section className={ClientUtils.twClassNames("w-full")}>
      <PageHeader heading="Anslut" />
      <main>
        <SignUpForm type="set" />
      </main>
    </section>
  );
};

export default withSignUpId("set", SignUpRoute);
