import { useContext } from "react";
import { AppButton } from "../../../../components/common/buttons/AppButton";
import AppAdminIcon from "../../../../components/icons/AppAdminIcon";
import AppExternalLinkIcon from "../../../../components/icons/AppExternalLinkIcon";
import { useApiClients } from "../../../../hooks/use-api-clients";
import AppRoutes from "../../../../routes";
import ClientUtils from "../../../../utils/ClientUtils";
import HandymanWorkOrderRouteContext from "../contexts/HandymanWorkOrderRouteContext";
import WorkOrderOverviewCard from "./WorkOrderOverviewCard";

interface Props {
  className?: string;
}

const OverviewAdminSection = (props: Props) => {
  const { workOrderRepo } = useApiClients();
  const routeCtx = useContext(HandymanWorkOrderRouteContext);

  if (process.env.NODE_ENV !== "development") {
    return <></>;
  }

  function resetWorkOrder() {
    routeCtx.orderRes.mutate({
      callback: () => workOrderRepo.reset({ orderId: routeCtx.order.orderId }),
    });
  }
  const order = routeCtx.order;

  return (
    <WorkOrderOverviewCard
      className={ClientUtils.twClassNames(
        "grid h-full grid-cols-[auto,minmax(0,1fr)] grid-rows-[auto,minmax(0,1fr)] items-center gap-y-2 text-dark-gray",
        props.className
      )}
    >
      <span className="opacity flex rounded-full border bg-brand-blue-100 p-2 text-brand-blue-400">
        <AppAdminIcon size={40} />
      </span>
      <div className="flex flex-col p-4">
        <p className="text-sm">
          <span className="text-xs italic text-dark-gray text-opacity-80">
            Syns bara i utvecklingsmiljö
          </span>
        </p>

        <h3 className="text-base font-semibold">
          <span>Admin</span>
        </h3>
      </div>
      <div className="col-span-2 flex flex-col gap-2">
        <AppButton onClick={resetWorkOrder}>Återställ arbetsorder</AppButton>
        <a
          href={AppRoutes.customer.root(order.customerGuid ?? "")}
          className="flex items-center gap-2"
          target="_blank"
          rel="noreferrer"
        >
          <span>Gå till kundsida</span>
          <AppExternalLinkIcon />
        </a>
        {order.requiresMaterialToBeReviewed && (
          <a
            href={AppRoutes.externalCustomer.orderLineReviewList({
              workOrderGuid: order.externalCustomerGuid!,
            })}
            className="flex items-center gap-2"
            target="_blank"
            rel="noreferrer"
          >
            <span>Gå till BankId-sida</span>
            <AppExternalLinkIcon />
          </a>
        )}
        {order.paymentDetails.externalPaymentFlowAllowed && (
          <a
            href={AppRoutes.customer.workOrderPayment({
              workOrderGuid: order.customerGuid!,
            })}
            className="flex items-center gap-2"
            target="_blank"
            rel="noreferrer"
          >
            <span>Gå till betalningssida</span>
            <AppExternalLinkIcon />
          </a>
        )}
      </div>
    </WorkOrderOverviewCard>
  );
};

export default OverviewAdminSection;
