import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useApiClients } from "../../../hooks/use-api-clients";
import LoadingContent from "../../common/loaders/LoadingContent";
import SomethingWentWrong from "../../on-error/SomethingWentWrong";
import ClientUtils from "../../../utils/ClientUtils";

const withSalesGuid = (WrappedComponent: () => React.ReactElement) => () => {
  const params = useParams();
  const salesGuid = params["salesGuid"] || "";
  const { authRepo } = useApiClients();

  const res = useQuery(
    ["salesSignInState", salesGuid],
    () => {
      return authRepo.salesAuthentication(salesGuid);
    },
    {
      staleTime: Infinity,
      retry: 1,
    }
  );

  return (
    <LoadingContent loading={res.isLoading} renderContentSeparately>
      {ClientUtils.renderOne(res, [
        (res) =>
          res.isError && (
            <section className="mx-auto flex">
              <main>
                <SomethingWentWrong
                  error={res.error}
                  description="Vi kan för tillfället inte säkerställa att du har rätt att se den här sidan. Vänligen försök igen senare!"
                />
              </main>
            </section>
          ),
        (res) =>
          res.data === 404 && (
            <section className="mx-auto flex">
              <main>
                <SomethingWentWrong
                  error={res.error}
                  description="Denna order finns tyvärr inte. Du kommer åt din order genom att klicka på länken vi skickar ut till dig genom SMS eller e-post."
                />
              </main>
            </section>
          ),
        (res) => res.data === 200 && <WrappedComponent />,
      ])}
    </LoadingContent>
  );
};

export default withSalesGuid;
