import { BrandTheme, WorkOrder } from "@eljouren/domain";
import { SplashScreen } from "@ipis/client-essentials";
import { AnimatePresence } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import IWorkOrderRepo from "../../../_model/repos/interfaces/IWorkOrderRepo";
import IpisPageWithLogo from "../../../components/__v2__/layout-v2/IpisPageWithLogo";
import withCustomerGuidV2, {
  CustomerAuthContext,
} from "../../../components/auth/hocs/withCustomerGuidV2";
import { useStaticExternalBrand } from "../../../hooks/brand-hooks";
import useMutableQuery, {
  UseMutableQueryResult,
} from "../../../hooks/use-mutatable-query";
import { useApiClients } from "../../../hooks/use-api-clients";
import CustomerOrderLineReviewSectionV2 from "./CustomerOrderLineReviewSectionV2";

type RepoResponse = Awaited<
  ReturnType<IWorkOrderRepo["getMaterialReviewList"]>
>;
export const OrderLineReviewContext = React.createContext<{
  workOrder: WorkOrder.CustomerType;
  listData: RepoResponse;
  listRes: UseMutableQueryResult<RepoResponse>;
}>({} as never);

export const useOrderLineReviewContext = () => {
  return useContext(OrderLineReviewContext);
};

const eonMockBrand: BrandTheme.Type = {
  id: "eon",
  brandName: "E.ON",
  headerBackgroundColor: "#f9f6f4",
  accentColor: "#ea1b0a",
  buttonTextColor: "#ffffff",
  logoUrl: "https://upload.wikimedia.org/wikipedia/commons/9/93/EON_Logo.svg",
  headerTextColor: "#000000",
};

interface Props {}

const CustomerOrderLineReviewRouteV2 = (props: Props) => {
  let brandTheme: BrandTheme.Type | undefined = undefined;
  const brand = useStaticExternalBrand();
  if (brand === "eon") {
    brandTheme = eonMockBrand;
  }

  const { workOrderRepo } = useApiClients();
  const ctx = useContext(CustomerAuthContext);
  const [splashScreenDone, setSplashScreenDone] = useState(false);

  const workOrder = ctx.workOrder;

  const [isSuccessful] = useState(false);

  const listRes = useMutableQuery({
    queryKey: ["customerMaterialReviewList", isSuccessful, workOrder?.orderId],
    queryFn: () => {
      return workOrderRepo.getMaterialReviewList();
    },
    // 30 seconds
    staleTime: 1000 * 30,
    enabled: !!workOrder,
  });

  const listData = listRes.query.data;

  useEffect(() => {
    console.warn("CustomerOrderLineReviewRouteV2 needs an error page!");
  });

  const showPage = !!workOrder && !!listData && splashScreenDone;

  return (
    <IpisPageWithLogo
      headerProps={{
        subHeading: !!workOrder
          ? `Arbetsordernr. ${workOrder.serialNumber}`
          : undefined,
        heading: "Godkänn material och extraarbete",
      }}
    >
      <AnimatePresence>
        {!showPage && (
          <SplashScreen
            fixed
            key="splash-screen"
            onSplashScreenDone={() => setSplashScreenDone(true)}
            externalBrandTheme={brandTheme}
          />
        )}
        {showPage && (
          <OrderLineReviewContext.Provider
            value={{ workOrder, listData, listRes }}
          >
            <CustomerOrderLineReviewSectionV2 />
          </OrderLineReviewContext.Provider>
        )}
      </AnimatePresence>
    </IpisPageWithLogo>
  );
};

export default withCustomerGuidV2(CustomerOrderLineReviewRouteV2);
