import { BrandTheme } from "@eljouren/domain";
import { IpisButton, SplashScreen } from "@ipis/client-essentials";
import { AnimatePresence } from "framer-motion";
import React, { useContext, useState } from "react";
import { IIpisFormRepo } from "../../../_model/repos/IpisFormRepo";
import IpisPageWithLogo from "../../../components/__v2__/layout-v2/IpisPageWithLogo";
import withCustomerGuidV2, {
  CustomerAuthContext,
} from "../../../components/auth/hocs/withCustomerGuidV2";
import PreChecklistComponent from "../../../components/checklist/v2/pre-checklist/PreChecklistComponent";
import SomethingWentWrongV2 from "../../../components/on-error/SomethingWentWrongV2";
import { useStaticExternalBrand } from "../../../hooks/brand-hooks";
import useMutableQuery, {
  UseMutableQueryResult,
} from "../../../hooks/use-mutatable-query";
import { useApiClients } from "../../../hooks/use-api-clients";

export type PreparationFormContextType = {
  formResponse: UseMutableQueryResult<
    Awaited<ReturnType<IIpisFormRepo["getPreparationsFormForCustomer"]>>
  >;
  preview?: boolean;
};

export const PreparationFormContext =
  React.createContext<PreparationFormContextType>({} as never);

export function usePreparationFormContext() {
  return useContext(PreparationFormContext);
}

const eonMockBrand: BrandTheme.Type = {
  id: "eon",
  brandName: "E.ON",
  headerBackgroundColor: "#f9f6f4",
  accentColor: "#ea1b0a",
  buttonTextColor: "#ffffff",
  logoUrl: "https://upload.wikimedia.org/wikipedia/commons/9/93/EON_Logo.svg",
  headerTextColor: "#000000",
};

interface Props {}

const CustomerWorkOrderPreparationsRouteV2 = (props: Props) => {
  let brandTheme: BrandTheme.Type | undefined = undefined;
  const brand = useStaticExternalBrand();
  if (brand === "eon") {
    brandTheme = eonMockBrand;
  }

  const ctx = useContext(CustomerAuthContext);
  const [splashScreenDone, setSplashScreenDone] = useState(false);

  const workOrder = ctx.workOrder;
  const repos = useApiClients();

  const formRes = useMutableQuery({
    queryKey: ["customerPreparationsForm", workOrder?.orderId],
    queryFn: () => {
      return repos.ipisFormRepo.getPreparationsFormForCustomer({
        fileRepo: repos.workOrderFileRepo,
      });
    },
    enabled: !!workOrder,
  });

  function reset() {
    return formRes.mutate({
      callback: async () => {
        await repos.ipisFormRepo.resetFormAnswers();
      },
    });
  }

  const formData = formRes.query.data;
  const formError = formRes.query.error;
  const workOrderError = ctx.workOrderRes.query.error;

  const hasWorkOrder = !!workOrder;
  const hasFormData = !!formData;
  const hasData = hasWorkOrder && hasFormData;
  const hasError = !!formError || !!workOrderError;

  const showSplashScreen = !splashScreenDone || (!hasData && !hasError);
  const showPage = hasData && splashScreenDone && !hasError;
  const showError = hasError && !showSplashScreen;

  const showResetButton = process.env.NODE_ENV === "development";

  return (
    <IpisPageWithLogo
      headerProps={{
        subHeading: !!workOrder
          ? `Arbetsordernr. ${workOrder.serialNumber}`
          : undefined,
        heading: "Förberedelser inför arbetsordern",
        rightSlot: showResetButton ? (
          <IpisButton
            variant="secondary-on-dark-background"
            onClick={reset}
            label="Återställ"
          />
        ) : undefined,
      }}
    >
      {/* 
        Splash screen exit animation is not working properly.
      */}
      <AnimatePresence>
        {showSplashScreen && (
          <SplashScreen
            fixed
            key="splash-screen"
            onSplashScreenDone={() => setSplashScreenDone(true)}
            externalBrandTheme={brandTheme}
          />
        )}
        {showError && <SomethingWentWrongV2 error={null} />}
        {showPage && (
          <PreparationFormContext.Provider value={{ formResponse: formRes }}>
            <PreChecklistComponent
              formResponse={formData}
              workOrder={workOrder}
              /* onFinished={onFinished} */
            />
          </PreparationFormContext.Provider>
        )}
      </AnimatePresence>
    </IpisPageWithLogo>
  );
};

export default withCustomerGuidV2(CustomerWorkOrderPreparationsRouteV2);
