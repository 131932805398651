import { htmlToSlate, slateToHtml } from "@slate-serializers/html";
import { MotionProps, motion } from "framer-motion";

import isEqual from "lodash.isequal";
import { useCallback, useImperativeHandle, useRef, useState } from "react";
import { RefCallBack } from "react-hook-form";
import { Descendant, Transforms } from "slate";
import { Editable, ReactEditor, Slate } from "slate-react";
import ClientUtils from "../../utils/ClientUtils";
import CustomSlateEditor from "./CustomSlateEditor";
import { useEditor } from "./rich-text-editor-hooks";
import { FauxFieldsetWrapper } from "@ipis/client-essentials";

const Leaf = (props: any) => {
  return (
    <span
      {...props.attributes}
      style={{
        fontWeight: props.leaf.bold ? "bold" : "normal",
        fontStyle: props.leaf.italic ? "italic" : "normal",
        textDecoration: props.leaf.underline ? "underline" : "none",
      }}
    >
      {props.children}
    </span>
  );
};

interface Props {
  className?: string;
  onChange: (html: string | undefined) => void;
  setFocus: (focus: boolean) => void;
  hideToolbar?: boolean;
  onlyView?: boolean;
  editorId: string;
  motionProps?: MotionProps;
  required?: boolean;
  useCharacterCount?: boolean;
  label?: string;
  refCallback: RefCallBack;
  defaultHtml?: string;
}

const SimpleRichTextEditor = (props: Props) => {
  const editor = useEditor(props.editorId);

  const emptyValue = [
    {
      type: "p",
      children: [{ text: "" }],
    },
  ];
  const initialValue = props.defaultHtml
    ? htmlToSlate(props.defaultHtml)
    : emptyValue;

  function isEmpty(value: Descendant[]): boolean {
    const otherEmptyValue: Descendant[] = [
      {
        text: "",
      },
    ];
    return isEqual(value, emptyValue) || isEqual(value, otherEmptyValue);
  }

  const [isBoldActive, setIsBoldActive] = useState(false);
  const [isItalicActive, setIsItalicActive] = useState(false);
  const [isUnderlineActive, setIsUnderlineActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const hasForcedFocusRef = useRef(false);

  /* 
    This is a fix to the editor not being focusable using tab before clicking on it
  */
  function forceFocus() {
    try {
      const isFocused = getIsFocused();
      if (!isFocused && !hasForcedFocusRef.current) {
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
        hasForcedFocusRef.current = true;
      }
      //ReactEditor.focus(editor);
      /*  */
    } catch (er) {
      console.error(er);
    }
  }

  function getIsFocused() {
    try {
      return ReactEditor.isFocused(editor);
    } catch (er) {
      return false;
    }
  }

  function updateState() {
    setIsBoldActive(CustomSlateEditor.isMarkActive(editor, "bold"));
    setIsItalicActive(CustomSlateEditor.isMarkActive(editor, "italic"));
    setIsUnderlineActive(CustomSlateEditor.isMarkActive(editor, "underline"));
  }

  function toggleBold() {
    CustomSlateEditor.toggleMark(editor, "bold");
    updateState();
  }

  function toggleItalic() {
    CustomSlateEditor.toggleMark(editor, "italic");
    updateState();
  }

  function toggleUnderline() {
    CustomSlateEditor.toggleMark(editor, "underline");
    updateState();
  }

  useImperativeHandle(props.refCallback, () => {
    return {
      focus: () => {
        try {
          ReactEditor.focus(editor);
        } catch (er) {
          console.error(er);
        }
      },
    };
  });

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  const showToolbar = !props.onlyView && !props.hideToolbar;

  return (
    <motion.fieldset
      className={ClientUtils.classNames(
        "flex w-full flex-col",
        props.className
      )}
      {...props.motionProps}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <FauxFieldsetWrapper
        required={!!props.required}
        size="sm"
        id={`editor-wrapper-${props.editorId}`}
        isFocused={getIsFocused()}
        isHovered={isHovered}
        label={
          props.label
            ? {
                text: props.label,
              }
            : undefined
        }
        rows={2}
        leftSidePaddingInPixels={props.onlyView ? 0 : 12}
      >
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={(value) => {
            if (isEmpty(value)) {
              props.onChange(undefined);
            } else {
              const html = slateToHtml(value);
              props.onChange(html);
            }

            updateState();
          }}
        >
          <Editable
            required={props.required}
            onFocus={() => {
              props.setFocus(true);
              forceFocus();
            }}
            onBlur={() => {
              props.setFocus(false);
            }}
            className={ClientUtils.classNames(
              "col-span-3 col-start-1 row-start-1 focus:outline-none",
              !props.onlyView && "p-3"
            )}
            renderLeaf={renderLeaf}
            onKeyDown={(event) => {
              /* if (event.key === "Tab") {
                event.preventDefault();
              } */
              if (!event.ctrlKey) {
                return;
              }

              switch (event.key) {
                // When "B" is pressed, bold the text in the selection.
                case "b": {
                  event.preventDefault();
                  CustomSlateEditor.toggleMark(editor, "bold");
                  break;
                }
                // When "I" is pressed, italicize the text in the selection.
                case "i": {
                  event.preventDefault();
                  CustomSlateEditor.toggleMark(editor, "italic");
                  break;
                }
                // When "U" is pressed, underline the text in the selection.
                case "u": {
                  event.preventDefault();
                  CustomSlateEditor.toggleMark(editor, "underline");
                  break;
                }
              }
            }}
          />
        </Slate>

        {showToolbar && (
          <motion.div className="col-span-3 col-start-1 row-start-2 ml-auto flex">
            <ToolbarButton
              onClick={toggleBold}
              isActive={isBoldActive}
              label="B"
            />
            <ToolbarButton
              onClick={toggleItalic}
              isActive={isItalicActive}
              label="I"
            />
            <ToolbarButton
              onClick={toggleUnderline}
              isActive={isUnderlineActive}
              label="U"
            />
          </motion.div>
        )}
      </FauxFieldsetWrapper>
    </motion.fieldset>
  );
};

const ToolbarButton = (props: {
  onClick: () => void;
  isActive: boolean;
  label: string;
}) => {
  return (
    <button
      className={ClientUtils.classNames(
        "flex h-8 w-8 items-center justify-center p-2 transition-all last:rounded-br",
        !props.isActive && "bg-secondary-100 hover:bg-secondary-200",
        props.isActive && "bg-secondary-400 text-light-background"
      )}
      onClick={props.onClick}
      type="button"
      tabIndex={-1}
      style={{
        transform: "translate(-1px, -1px)",
      }}
    >
      {props.label}
    </button>
  );
};

export default SimpleRichTextEditor;
