import "./App.css";
import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import BackButtonStackContextProvider from "./components/common/navigation/BackButtonStackContextProvider";
import AppLayout from "./components/layout/AppLayout";
import Modal from "./components/notifications/Modal";
import QueueModal from "./components/notifications/QueueModal";
import SomethingWentWrong from "./components/on-error/SomethingWentWrong";
import QueueContextProvider from "./components/queue/QueueContextProvider";
import { useIsOnline } from "./hooks/connectivity-hooks";
import { usePrevious } from "./hooks/hooks";
import { useApiClients } from "./hooks/use-api-clients";
import { GlobalContext } from "./top-level-contexts";
import FetchInterceptor from "./utils/FetchInterceptor";
import { useBind } from "@ipis/client-essentials";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      // 5 minute staletime
      staleTime: 1000 * 60 * 5,
    },
  },
});
function fixAppHeightForMobileDevices() {
  const doc = document.documentElement;
  doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
}

window.addEventListener("resize", fixAppHeightForMobileDevices);
fixAppHeightForMobileDevices();
window.fetch = FetchInterceptor.func;

interface Props {
  children?: React.ReactNode;
}

function App(props: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <AfterQueryClient {...props} />
    </QueryClientProvider>
  );
}

function AfterQueryClient(props: Props) {
  const isOnline = useIsOnline();
  const { authRepo, workOrderRepo } = useApiClients();
  const signInState = useBind(authRepo.signedInState);
  const previouslySignedIn = usePrevious(signInState.isSignedIn);
  const [searchParams, setSearchParams] = useSearchParams();

  /* 
    Not used yet
  */
  const activeSessionPoll = useQuery({
    queryKey: ["activeSession", JSON.stringify(signInState)],
    queryFn: () => authRepo.validateSessionActive(),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60,
    refetchIntervalInBackground: true,
    refetchOnMount: false,
    refetchOnReconnect: "always",
    refetchOnWindowFocus: "always",
    enabled: false,
  });

  /* 
    Remove the internalNavigation param from the url on first render
  */
  useEffect(() => {
    if (!!searchParams.get("internalNavigation")) {
      searchParams.delete("internalNavigation");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
    To show the right logo based on brand.
    Not really a fan of this.
  */
  useEffect(() => {
    const unsub = workOrderRepo.onWorkOrderFetched((order) =>
      authRepo.notifyCustomerOrderFetched(order)
    );
    return unsub;
  });

  /*
    Clear all data when the user signs out 
  */
  useEffect(() => {
    if (!signInState.isSignedIn && previouslySignedIn) {
      queryClient.resetQueries();
    }
  }, [signInState.isSignedIn, previouslySignedIn]);

  /* 
    So that the profile route can use the cached data
  */
  useEffect(() => {
    queryClient.setQueryData("signInStateProfile", signInState.handyman);
  }, [signInState.isSignedIn, signInState.handyman]);

  const location = useLocation();

  return (
    <ErrorBoundary
      fallbackRender={(props) => {
        return <SomethingWentWrong error={props.error} />;
      }}
    >
      <GlobalContext.Provider
        value={{
          signInState,
          location,
          isOnline,
          sessionActive: activeSessionPoll.data ?? activeSessionPoll.isFetching,
        }}
      >
        <BackButtonStackContextProvider>
          <QueueContextProvider>
            <AppLayout>
              <Outlet />
              {props.children}
            </AppLayout>

            {/* 
            ToDo: Better fallback render
          */}
            <ErrorBoundary
              fallbackRender={() => {
                console.error("Modal failed to render");

                return <></>;
              }}
            >
              <Modal />
              <QueueModal />
            </ErrorBoundary>
          </QueueContextProvider>
        </BackButtonStackContextProvider>
      </GlobalContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
