import { useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import PageSection from "../../../components/common/PageSection";
import { useApiClients } from "../../../hooks/use-api-clients";
import { DateHelper } from "@eljouren/utils";
import HandymanCompanyRouteContext from "./HandymanCompanyRouteContext";
import ClientUtils from "../../../utils/ClientUtils";
import AppDownloadIcon from "../../../components/icons/AppDownloadIcon";

interface Props {
  className?: string;
}

const HandymanCompanyInvoiceTab = (props: Props) => {
  const { handyman: worker } = useContext(HandymanCompanyRouteContext);
  const { companyRepo } = useApiClients();

  const startYear = 2022;
  const startDate = new Date(`${startYear}-01-01`);
  const currentYear = useRef(new DateHelper().year).current;
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const invoiceRes = useQuery(
    ["supplierInvoices", worker.companyId, selectedYear],
    () => {
      const pageIndex = yearToPageIndex(selectedYear);
      const from = new DateHelper(startDate).add(
        "years",
        pageIndex
      ).startOfYear;
      const to = from.endOfYear;
      return companyRepo.fetchSupplierInvoices({
        from: from.date,
        to: to.date,
      });
    }
  );

  function getYearRange() {
    return Array.from(
      { length: currentYear - startYear + 1 },
      (value, index) => index + startYear
    );
  }

  function onYearChange(year: number) {
    setSelectedYear(year);
    //const page = yearToPageIndex(year);
    //invoiceRes.observable.setPage(page);
  }

  function yearToPageIndex(year: number) {
    const date = new Date(`${year}-01-01`);
    const startDate = new Date(`${startYear}-01-01`);
    const helper = new DateHelper(date);
    const page = helper.differenceIn("years", startDate);
    return page;
  }

  return (
    <PageSection as="section" className="flex h-full w-full flex-col gap-2">
      <header className="flex border-b pb-2">
        <p className="ml-auto flex items-center gap-2">
          <label htmlFor="yearSelect" className="text-lg font-semibold">
            År
          </label>
          <select
            className="bg-blue-100 p-2"
            defaultValue={currentYear}
            onChange={(e) => {
              onYearChange(Number.parseInt(e.target.value));
            }}
            id="yearSelect"
          >
            {getYearRange().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </p>
      </header>
      <main className="">
        {ClientUtils.renderOne(invoiceRes, [
          (res) => res.isLoading && <AppLoader />,
          (res) =>
            res.isError && <p>Det gick inte att hämta fakturorna just nu.</p>,
          (res) =>
            !!res.data &&
            !!res.data.length && (
              <ul className="flex flex-col gap-2">
                {res.data.map((invoice) => {
                  const startHelper = new DateHelper(invoice.periodStart);
                  const endHelper = new DateHelper(invoice.periodEnd);
                  const startWeek = startHelper.week;
                  const startYear = startHelper.startOfWeek.yearLabel;

                  const endWeek = endHelper.week;
                  const endYear = endHelper.yearLabel;

                  const sameWeek = startWeek === endWeek;
                  const sameYear = startYear === endYear;

                  let label: string;
                  if (sameWeek && sameYear) {
                    label = `V. ${startWeek}, ${startYear}`;
                  } else {
                    label = `V. ${startWeek}, ${startYear} - V. ${endWeek}, ${endYear}`;
                  }

                  return (
                    <li key={invoice.id} className="text-lg font-semibold">
                      <a
                        href={invoice.documentHref}
                        download
                        className="flex items-center gap-2 hover:underline"
                      >
                        <AppDownloadIcon size={24} />

                        <span>{label}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            ),
          (res) =>
            !!res.data &&
            !res.data.length && (
              <p>Det finns inga leverantörsfakturor att vissa detta år</p>
            ),
        ])}
      </main>
    </PageSection>
  );
};

export default HandymanCompanyInvoiceTab;
