import { HandymanProfession } from "@eljouren/domain/build";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { z } from "@ipis/centralized-zod";
import Paginator from "../../../_model/helpers/Paginator";
import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";
import { WorkerAssociationSchema } from "../../../_model/repos/interfaces/IHandymanRepo";
import withSalesCredentials from "../../../components/auth/hocs/withSalesCredentials";
import PageSection from "../../../components/common/PageSection";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import PaginationControls from "../../../components/common/pagination/PaginationControls";
import { AppFormTextField } from "../../../components/common/text-fields/AppFormTextField";
import AppPage from "../../../components/layout/AppPage";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import MyLink from "../../../components/routing/MyLink";
import { useApiClients } from "../../../hooks/use-api-clients";
import AppRoutes from "../../../routes";
import ClientUtils from "../../../utils/ClientUtils";
import SalesTeamFilterSelect from "./SalesTeamFilterSelect";
import SalesTeamPartnerListItem from "./SalesTeamPartnerListItem";

const ValidValues = z.enum([
  "el",
  "vvs",
  "spol",
  "låssmed",
  "läckage",
  "snickare",
  "fixare",
]);
const Transform = ValidValues.transform<HandymanProfession.Type>((value) => {
  switch (value) {
    case "el":
      return "electrician";
    case "spol":
      return "plumber-spolbil";
    case "vvs":
      return "plumber";
    case "låssmed":
      return "locksmith";
    case "läckage":
      return "leakage";
    case "snickare":
      return "carpenter";
    case "fixare":
      return "generalist";
  }
});

interface Props extends TSignedInWorkerState {
  className?: string;
}

const SalesTeamPartnerListAssociationRoute = (props: Props) => {
  const { workerAssociation } = useParams();
  const navigate = useNavigate();

  const [params, setSearchParams] = useSearchParams();
  const areaFilter = params.get("omrade");
  const qualificationFilter = params.get("kompetens");
  const pageStr = params.get("sida");
  const pageIndex = pageStr ? Number.parseInt(pageStr) : 0;
  const form = useForm<{ filter: string }>({
    defaultValues: {
      filter: "",
    },
  });

  //const areaFilter = useBundledState<false | string>(false);
  const { handymanRepo } = useApiClients();

  const partnerRes = useQuery(["partners", workerAssociation], async () => {
    const res = await handymanRepo.fetchByProfession(
      Transform.parse(workerAssociation)
    );
    // Sorting here should by done in the backend query - this is just a quick fix
    res.sort((a, b) =>
      `${a.firstName} ${a.lastName}`
        .toLowerCase()
        .localeCompare(`${b.firstName} ${b.lastName}`.toLowerCase())
    );
    return res;
  });

  function previousPage() {
    setSearchParams({
      ...Object.fromEntries(params.entries()),
      ...{
        sida: (pageIndex - 1).toString(),
      },
    });
  }

  function nextPage() {
    setSearchParams({
      ...Object.fromEntries(params.entries()),
      ...{
        sida: (pageIndex + 1).toString(),
      },
    });
  }

  const paginator = new Paginator({
    pageIndex: pageIndex,
    elementsPerPage: 5,
    totalCountOfElements: "countData",
    data: getFilteredData(),
  });

  function setAreaFilter(value: string) {
    setSearchParams({
      ...Object.fromEntries(params.entries()),
      ...{
        omrade: value,
        sida: "0",
      },
    });
  }
  function setQualificationFilter(value: string) {
    setSearchParams({
      ...Object.fromEntries(params.entries()),
      ...{
        kompetens: value,
        sida: "0",
      },
    });
  }

  function isFilteredByArea(): boolean {
    return !!areaFilter && areaFilter !== "default";
  }
  function isFilteredByQualification(): boolean {
    return !!qualificationFilter && qualificationFilter !== "default";
  }
  function isFiltered(): boolean {
    return (
      isFilteredByArea() ||
      isFilteredByQualification() ||
      !!form.watch("filter")
    );
  }

  useEffect(() => {
    if (!WorkerAssociationSchema.safeParse(workerAssociation).success) {
      navigate("/");
    }
  }, [navigate, workerAssociation]);

  function getFilteredData() {
    if (!partnerRes.data) {
      return [];
    }
    const filtered = isFiltered();

    return partnerRes.data.filter((worker) => {
      if (!filtered) {
        return true;
      }
      let includesArea = true;
      let includesQualification = true;
      let matchesFreeText = true;
      if (isFilteredByArea() && areaFilter) {
        includesArea = worker.activeAreas.includes(areaFilter);
      }
      if (isFilteredByQualification() && qualificationFilter) {
        includesQualification =
          worker.qualifications.includes(qualificationFilter);
      }
      if (form.watch("filter").length > 0) {
        matchesFreeText = form
          .watch("filter")
          .split(" ")
          .every((str) => {
            const lc = str.toLowerCase();
            const {
              permittedToViewPrices,
              qualifications,
              activeAreas,
              ...rest
            } = worker;

            const matchesStrValues = [
              ...Object.values(rest),
              ...qualifications,
              ...activeAreas,
            ].some((el) => {
              if (typeof el === "string") {
                return el.toLowerCase().includes(lc);
              }
              return false;
            });

            return matchesStrValues;
          });
      }
      return includesArea && includesQualification && matchesFreeText;
    });
  }

  function getUniqueCities(): string[] {
    if (!partnerRes.data) {
      return [];
    }
    const set = new Set(
      partnerRes.data
        .filter((worker) => !!worker.city)
        .flatMap((worker) => worker.activeAreas)
    );
    return Array.from(set).sort();
  }
  function getUniqueQualifications(): string[] {
    if (!partnerRes.data) {
      return [];
    }
    const set = new Set(
      partnerRes.data
        .filter((worker) => !!worker.city)
        .flatMap((worker) => worker.qualifications)
    );
    return Array.from(set).sort();
  }

  return (
    <AppPage
      headerProps={{
        overrideLink: AppRoutes.salesTeam.partnerList(),
        RenderHeadings: () => {
          return (
            <span className="flex ">
              <HeaderLink
                href={AppRoutes.salesTeam.partnerListWithAssociation("vvs")}
                label="VVS"
                current={workerAssociation === "vvs"}
              />
              <HeaderLink
                href={AppRoutes.salesTeam.partnerListWithAssociation("el")}
                label="El"
                current={workerAssociation === "el"}
              />
              <HeaderLink
                href={AppRoutes.salesTeam.partnerListWithAssociation("spol")}
                label="Spol"
                current={workerAssociation === "spol"}
              />
              <HeaderLink
                href={AppRoutes.salesTeam.partnerListWithAssociation("låssmed")}
                label="Låssmed"
                current={workerAssociation === "låssmed"}
              />
              <HeaderLink
                href={AppRoutes.salesTeam.partnerListWithAssociation("läckage")}
                label="Läckage"
                current={workerAssociation === "läckage"}
              />
              <HeaderLink
                href={AppRoutes.salesTeam.partnerListWithAssociation(
                  "snickare"
                )}
                label="Snickare"
                current={workerAssociation === "snickare"}
              />
              <HeaderLink
                href={AppRoutes.salesTeam.partnerListWithAssociation("fixare")}
                label="Fixare"
                current={workerAssociation === "fixare"}
              />
            </span>
          );
        },
      }}
      className="grid gap-2 p-2 md:grid-cols-3"
      noLayoutStyling
    >
      <PageSection as="section" className="flex flex-col gap-2 md:col-span-2">
        {ClientUtils.renderOne({ res: partnerRes }, [
          ({ res }) => res.isLoading && <AppLoader />,
          ({ res }) =>
            res.isError && (
              <SomethingWentWrong
                error={res.error}
                description="Det gick inte att hämta partners just nu. Vänligen försök igen senare."
              />
            ),
          ({ res }) => res.isLoading && <AppLoader />,
          ({ res }) =>
            !!res.data && (
              /* 
                  ToDO: Fix using props not passed to the component which is bad practice...
                */
              <>
                <PaginationControls
                  className="ml-auto"
                  canGoBack={paginator.canGoBack}
                  canGoForward={paginator.canGoForward}
                  pageIndex={paginator.pageIndex}
                  maxPageIndex={paginator.limits.maxPageIndex}
                  onGoBack={previousPage}
                  onGoForward={nextPage}
                />
                <AppFormTextField
                  register={form.register}
                  name="filter"
                  htmlAttributes={{
                    placeholder: "Filtrera",
                  }}
                />
                <ul className="flex flex-col gap-2 overflow-auto pt-0">
                  {paginator.currentData.map((worker) => (
                    <SalesTeamPartnerListItem
                      key={worker.id}
                      handyman={worker}
                    />
                  ))}
                </ul>
              </>
            ),
        ])}
      </PageSection>
      <PageSection
        as="aside"
        className="flex h-full grow flex-col gap-2 p-4 md:gap-4"
      >
        <SalesTeamFilterSelect
          className="md:col-start-2 md:row-span-2 md:row-start-1 md:p-0"
          id="citySelect"
          label="Område"
          values={getUniqueCities()}
          defaultValue={"default"}
          currentValue={areaFilter}
          onChange={setAreaFilter}
        />
        <SalesTeamFilterSelect
          className="md:col-start-2 md:row-start-3 md:p-0"
          id="qualificationSelect"
          label="Kompetens"
          values={getUniqueQualifications()}
          defaultValue={"default"}
          currentValue={qualificationFilter}
          onChange={setQualificationFilter}
        />
      </PageSection>
    </AppPage>
  );
};

const HeaderLink = (props: {
  href: string;
  label: string;
  current: boolean;
}) => {
  return (
    <MyLink
      to={props.href}
      className={ClientUtils.classNames(
        "bg-black-20 grid grid-cols-1 grid-rows-1 border-y border-r px-4 py-2 transition-all first:rounded-l first:border-l last:rounded-r hover:bg-black/60",
        "min-w-[70px] items-center justify-center",
        props.current && "bg-black/80"
      )}
    >
      {/* <span className="col-start-1 row-start-1 font-bold opacity-0">
        {props.label}
      </span> */}
      <span className="col-start-1 row-start-1 text-center">{props.label}</span>
    </MyLink>
  );
};

export default withSalesCredentials(SalesTeamPartnerListAssociationRoute);
