import { motion } from "framer-motion";
import React, { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import HandymanCalendar from "../../../../components/calendar/HandymanCalendar";
import SearchFieldSlideDown from "../../../../components/common/search/SearchFieldSlideDown";
import HandymanContextProvider from "../../../../components/handyman-context/HandymanContextProvider";
import AppPage from "../../../../components/layout/AppPage";
import SomethingWentWrong from "../../../../components/on-error/SomethingWentWrong";
import HandymanWorkOrderListItem from "../../../../components/work-order/handyman/HandymanWorkOrderListItem";
import HandymanWorkOrderListSection from "../../../../components/work-order/handyman/HandymanWorkOrderListSection";
import { useLsState } from "../../../../hooks/local-storage-hooks";
import { useApiClients } from "../../../../hooks/use-api-clients";
import ClientUtils from "./../../../../utils/ClientUtils";

import LoadingContent from "../../../../components/common/loaders/LoadingContent";
import AppArrowIcon from "../../../../components/icons/AppArrowIcon";
import { HandymanLandingPageContext } from "../HandymanRoute";
import HandymanWorkingHoursTab from "../workings-hours/HandymanWorkingHoursTab";
import LandingPageCompanyWidget from "./LandingPageCompanyWidget";
import LandingPageProfileWidget from "./LandingPageProfileWidget";
import LandingPageStatsWidget from "./LandingPageStatsWidget";

interface Props {
  className?: string;
}

const HandymanDesktopLandingPage = (props: Props) => {
  const ctx = useContext(HandymanLandingPageContext);

  return (
    <AppPage
      className={ClientUtils.twClassNames(
        "grid grid-cols-[auto,minmax(0,1fr)] grid-rows-[auto,minmax(0,1fr)] gap-x-4 bg-bg-base-layer",
        props.className
      )}
      headerProps={{
        heading: "Översikt",
      }}
    >
      <LoadingContent
        renderContentSeparately
        loading={ctx.isLoading}
        className="col-start-2 row-start-2"
      >
        <Header />
        <SideMenu />
        <div className="flex gap-4 p-4">
          <CalendarModule />
        </div>
      </LoadingContent>
    </AppPage>
  );
};

const CurrentDayOverviewModule = () => {
  return (
    <section className="h-full border-r bg-slate-100">
      <HandymanWorkOrderListSection onlyForToday />
    </section>
  );
};

const CalendarModule = () => {
  const { handyman } = useContext(HandymanLandingPageContext);
  return (
    <ErrorBoundary
      fallbackRender={(props) => (
        <SomethingWentWrong
          error={props.error}
          description="Något gick fel när vi skulle visa kalendern. Vänligen försök igen senare!"
        />
      )}
    >
      <HandymanContextProvider
        handymanId={handyman.id}
        pageIndexSearchParamKey="cIndex"
      >
        <Section className="col-span-2">
          <HandymanCalendar />
        </Section>
      </HandymanContextProvider>
    </ErrorBoundary>
  );
};

const WorkingHoursModule = () => {
  const { handyman } = useContext(HandymanLandingPageContext);
  return (
    <HandymanContextProvider
      handymanId={handyman.id}
      pageIndexSearchParamKey="whIndex"
    >
      <HandymanWorkingHoursTab className="bg-slate-100" />
    </HandymanContextProvider>
  );
};

const Section = (props: { children?: React.ReactNode; className?: string }) => {
  return (
    <motion.section
      className={ClientUtils.twClassNames(
        props.className,
        "h-full w-full min-w-[450px] overflow-auto rounded bg-off-white shadow"
      )}
    >
      {props.children}
    </motion.section>
  );
};

enum SideMenuPage {
  currentDayOverview = "Dagsöversikt",
  workingHours = "Arbetstider",
}

const SideMenu = (props: {}) => {
  const [selectedPage, setSelectedPage] = useLsState<SideMenuPage>(
    "overviewSidebarPage",
    SideMenuPage.currentDayOverview
  );
  const [visible, setVisible] = useLsState("overviewSidebarVisible", true, {
    decode: (str) => {
      return str !== "false";
    },
    encode: (bool) => bool.toString(),
  });

  return (
    <motion.section
      onClick={visible ? undefined : () => setVisible(true)}
      className={ClientUtils.twClassNames(
        "grid h-full grid-cols-1 grid-rows-[auto,minmax(0,1fr)] border-r bg-main-bg-light/10",
        !visible && "auto cursor-pointer",
        visible && "w-[450px]"
      )}
    >
      <header
        className={ClientUtils.twClassNames("flex", !visible && "flex-col")}
      >
        {visible &&
          Object.values(SideMenuPage).map((page) => {
            return (
              <button
                key={page}
                onClick={() => setSelectedPage(page)}
                className={ClientUtils.twClassNames(
                  "flex grow flex-col items-center border-t-2 p-1 py-2 font-bold transition-all last:border-r-0 lg:flex-row lg:justify-center lg:gap-2 lg:border-b-2 lg:border-t-0 lg:p-2",
                  "hover:border-tab-border-selected hover:text-black",
                  selectedPage === page &&
                    "border-tab-border-selected text-black",
                  selectedPage !== page &&
                    "border-tab-border-not-selected text-black/60"
                )}
              >
                {page}
              </button>
            );
          })}
        {}
        <button
          className={ClientUtils.twClassNames(
            "ml-auto p-4",
            !visible && "rotate-180"
          )}
          onClick={() => setVisible(!visible)}
        >
          <AppArrowIcon direction="left" size={24} />
        </button>
      </header>
      {visible && (
        <main className="grid w-full grid-cols-1">
          {selectedPage === SideMenuPage.currentDayOverview && (
            <CurrentDayOverviewModule />
          )}
          {selectedPage === SideMenuPage.workingHours && <WorkingHoursModule />}
        </main>
      )}
    </motion.section>
  );
};

const Header = () => {
  const { handyman } = useContext(HandymanLandingPageContext);
  const { workOrderRepo } = useApiClients();
  return (
    <section className="col-span-2 grid grid-cols-3 items-center gap-8 bg-slate-100 p-4 px-8 3xl:grid-cols-[repeat(13,minmax(0,1fr))]">
      <HandymanContextProvider
        handymanId={handyman.id}
        pageIndexSearchParamKey="statsIndex"
      >
        <LandingPageStatsWidget className="3xl:col-span-5" />
      </HandymanContextProvider>
      {/* 
      ToDo: Needs accessible label
    */}
      <SearchFieldSlideDown
        className="3xl:col-span-3"
        inputWrapperClassName="py-2 shadow-inner shadow-black/50"
        placeholder="Sök på arbetsordrar"
        fetch={(query: string) =>
          workOrderRepo.searchForOrders({
            query,
            handymanId: handyman.id,
          })
        }
        id="orders"
        Render={(props) => {
          if (!props.data.length) {
            return <p className="p-2">Inga arbetsordrar hittades</p>;
          }

          return (
            <ul className="flex flex-col gap-2">
              {props.data.map((order) => (
                <HandymanWorkOrderListItem
                  className="rounded"
                  key={order.orderId}
                  order={order}
                />
              ))}
            </ul>
          );
        }}
      />
      <span className="ml-auto flex flex-col gap-2 2xl:flex-row-reverse 2xl:gap-4 3xl:col-span-5 3xl:gap-4">
        <LandingPageCompanyWidget />
        <LandingPageProfileWidget />
      </span>
    </section>
  );
};

export default HandymanDesktopLandingPage;
