import {
  FormElementAnswer,
  FormElementWithState,
  IpisForm,
  IpisFormElementIsolatedAnswer,
} from "@eljouren/domain";
import { IpisFileV2 } from "@eljouren/file-schemas";
import type { inferProcedureInput, inferProcedureOutput } from "@trpc/server";
import type { TDomainAppRouter } from "../../../../server/dist/routes/domain/DomainAppRouter";
import {
  FileUploadHookFile,
  FileUploadNewFile,
} from "../../hooks/file-upload-hooks";
import trpcClient from "../../trpc-setup";
import IClientWorkOrderFileRepoV2 from "./interfaces/IClientWorkOrderFileRepoV2";

export type IIpisFormRepo = IpisFormRepo;
export type SaveFormInput = inferProcedureInput<
  TDomainAppRouter["formStaffRouter"]["saveForm"]
>;
export type SaveFormOutput = inferProcedureOutput<
  TDomainAppRouter["formStaffRouter"]["saveForm"]
>;
export type GetAllFormsOutput = inferProcedureOutput<
  TDomainAppRouter["formStaffRouter"]["getAllForms"]
>;
export type DeleteFormInput = inferProcedureInput<
  TDomainAppRouter["formStaffRouter"]["deleteForm"]
>;
export type GetAllValidFormConnectionsOutput = inferProcedureOutput<
  TDomainAppRouter["formStaffRouter"]["getAllValidFormConnections"]
>;
export type TrpcGetPreparationsFormOutput = inferProcedureOutput<
  TDomainAppRouter["formCustomerRouter"]["getPreparationsForm"]
>;

type TrpcSuccessfulGetPreparationsFormOutput = Extract<
  TrpcGetPreparationsFormOutput,
  { success: true }
>;
type TrpcFailedGetPreparationsFormOutput = Extract<
  TrpcGetPreparationsFormOutput,
  { success: false }
>;
type ClientSideSuccessfulGetPreparationsFormOutput = Omit<
  TrpcSuccessfulGetPreparationsFormOutput,
  "answers" | "presigned"
> & {
  answers: IpisFormElementIsolatedAnswer.ClientSideType[];
};
type CustomerGetPreparationsFormOutput =
  | ClientSideSuccessfulGetPreparationsFormOutput
  | TrpcFailedGetPreparationsFormOutput;

export type HandymanGetPreparationsFormOutput =
  ClientSideSuccessfulGetPreparationsFormOutput;

export type SubmitPreparationsFormInput = inferProcedureInput<
  TDomainAppRouter["formCustomerRouter"]["submitPreparationsForm"]
>;

export type SubmitPreparationsFormOutput = inferProcedureOutput<
  TDomainAppRouter["formCustomerRouter"]["submitPreparationsForm"]
>;

export default class IpisFormRepo {
  async saveForm(args: SaveFormInput): Promise<SaveFormOutput> {
    return trpcClient.formStaffRouter.saveForm.mutate(args);
  }

  async getAll(): Promise<GetAllFormsOutput> {
    return trpcClient.formStaffRouter.getAllForms.query();
  }

  async delete(args: DeleteFormInput): Promise<void> {
    return trpcClient.formStaffRouter.deleteForm.mutate(args);
  }

  async getAllValidFormConnections(): Promise<GetAllValidFormConnectionsOutput> {
    return trpcClient.formStaffRouter.getAllValidFormConnections.query();
  }

  private async transformSuccessfulGetPreparationsFormOutput(args: {
    res: TrpcSuccessfulGetPreparationsFormOutput;
    fileRepo: IClientWorkOrderFileRepoV2;
  }): Promise<ClientSideSuccessfulGetPreparationsFormOutput> {
    const presigned = args.res.presigned;
    const serverSideAnswers = args.res.answers;
    let images: IpisFileV2.Type[] = [];

    if (presigned) {
      images = await args.fileRepo.getFilesWithPresignedUrl({
        url: presigned.url,
      });
    }

    const clientSide: IpisFormElementIsolatedAnswer.ClientSideType[] =
      serverSideAnswers.map((a) => {
        if (a.type !== "image-group") {
          return a;
        }

        const answerImages = images
          .filter((i) => a.id === i.recordId)
          .map((el) => {
            const img: FormElementAnswer.PostUploadImageType = {
              state: "postupload",
              ...el,
            };
            return img;
          });

        const answer: IpisFormElementIsolatedAnswer.ClientSideImageGroupType = {
          ...a,
          answer: {
            imageUploadComment: a.answer.imageUploadComment ?? undefined,
            images: answerImages,
          },
        };

        return answer;
      });

    return {
      success: true,
      answers: clientSide,
      form: args.res.form,
      formIsAnswered: args.res.formIsAnswered,
      answeredDate: args.res.answeredDate,
    };
  }

  async getPreparationsFormForHandyman(args: {
    fileRepo: IClientWorkOrderFileRepoV2;
    workOrderId: string;
  }): Promise<HandymanGetPreparationsFormOutput> {
    const res = await trpcClient.formHandymanRouter.getPreparationsForm.query({
      workOrderId: args.workOrderId,
    });
    if (!res.success) {
      throw new Error("Failed to get preparations form");
    }

    const transformed = await this.transformSuccessfulGetPreparationsFormOutput(
      {
        res,
        fileRepo: args.fileRepo,
      }
    );
    return transformed;
  }

  async getPreparationsFormForCustomer(args: {
    fileRepo: IClientWorkOrderFileRepoV2;
  }): Promise<CustomerGetPreparationsFormOutput> {
    const res = await trpcClient.formCustomerRouter.getPreparationsForm.query();
    if (!res.success) {
      return res;
    }

    const transformed = await this.transformSuccessfulGetPreparationsFormOutput(
      {
        res,
        fileRepo: args.fileRepo,
      }
    );
    return transformed;
  }

  async submitPreparationsForm(
    args: SubmitPreparationsFormInput & {
      validatedForm: IpisForm.FullyValidatedType;
      fileRepo: IClientWorkOrderFileRepoV2;
    }
  ): Promise<SubmitPreparationsFormOutput> {
    const res =
      await trpcClient.formCustomerRouter.submitPreparationsForm.mutate({
        form: args.form,
        answers: args.answers,
      });

    if (res.success) {
      const presigned = res.presignedUploadUrls;
      const elements = args.validatedForm.pages
        .flatMap((p) => p.elements)
        .filter(
          (el): el is FormElementWithState.ValidImageGroupType =>
            el.typeOfQuestion === "image-group"
        );
      const promises = elements.map((el) => {
        const imageMap = el.state.answer?.images;
        const url = presigned[el.id]?.presignResponse.url;
        if (!imageMap || !url) {
          return Promise.resolve();
        }
        /* 
          We probably need some kind of tagging system to know which images belong to which prompt
        */
        const values: FileUploadHookFile[] = Object.values(imageMap)
          .filter((v): v is FileUploadHookFile[] => !!v && Array.isArray(v))
          .flat();
        const newValues = values.filter(
          (v): v is FileUploadNewFile => v.state === "preupload"
        );

        if (newValues.length === 0) {
          return Promise.resolve();
        }

        return args.fileRepo.uploadWithPresignedUrl({
          url,
          files: newValues,
        });
      });

      await Promise.all(promises);
    }

    return res;
  }

  async resetFormAnswers(): Promise<void> {
    if (process.env.NODE_ENV !== "development") {
      throw new Error("This method is only available in development mode");
    }
    await trpcClient.formCustomerRouter.resetAnswers.mutate();
  }
}
