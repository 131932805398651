import { HandymanWorkingHours, WorkOrder } from "@eljouren/domain/build";
import { DateHelper, ScheduleGap, UUID } from "@eljouren/utils";
import { AnimatePresence } from "framer-motion";
import { useContext, useRef, useState } from "react";
import { useOnEscapeClick } from "../../hooks/keyboard-event-hooks";
import AppRoutes from "../../routes";

import ClientUtils from "../../utils/ClientUtils";
import MidDot from "../common/MidDot";
import ContextLikeMenu from "../common/menu/ContextLikeMenu";
import HandymanContext from "../handyman-context/HandymanContext";
import MyLink from "../routing/MyLink";

function itemIsWorkOrderGroup(item: any): item is WorkOrderGroup {
  return "workOrders" in item;
}

function itemIsWorkOrder(
  item: any
): item is WorkOrder.HandymanWithPermissionsType {
  return "orderId" in item;
}

function itemIsScheduleGap(item: any): item is ScheduleGap<any> {
  return "startDate" in item;
}

interface Props {
  className?: string;
  dayHelper: DateHelper;
  index: number;
}

type WorkOrderGroup = {
  id: string;
  // Start of the first workorder
  start: Date;
  // End of the last workorder
  end: Date;
  workOrders: WorkOrder.HandymanWithPermissionsType[];
};
type CalendarItem =
  | WorkOrder.HandymanWithPermissionsType
  | WorkOrderGroup
  | ScheduleGap<any>;

const CalendarDayGridItem = (props: Props) => {
  const { dayHelper } = props;
  const handymanCtx = useContext(HandymanContext);

  const { workingHours, workOrders } = handymanCtx.merger.getEntry(
    props.dayHelper.date
  );
  const gaps = handymanCtx.merger.getScheduleGaps(props.dayHelper.date) || [];

  const offWork = workingHours?.status === "offWork";
  const reported = workingHours?.status === "reported";

  function getTime(gapOrEvent: CalendarItem, when: "start" | "end") {
    if ("startDate" in gapOrEvent) {
      return when === "start" ? gapOrEvent.startDate : gapOrEvent.endDate;
    } else {
      return gapOrEvent[when];
    }
  }

  function groupOverlappingItems(
    items: (WorkOrder.HandymanWithPermissionsType | ScheduleGap<any>)[]
  ): CalendarItem[] {
    // Sort items by start time
    items.sort((a, b) => {
      const startA = "startDate" in a ? a.startDate : a.start;
      const startB = "startDate" in b ? b.startDate : b.start;

      if (startA.getTime() === startB.getTime()) {
        const endA = "endDate" in a ? a.endDate : a.end;
        const endB = "endDate" in b ? b.endDate : b.end;
        return endA.getTime() - endB.getTime();
      }
      return startA.getTime() - startB.getTime();
    });

    const calendarItems: CalendarItem[] = [];

    items.forEach((item, i) => {
      const currentItemIndex = calendarItems.length;
      const previousItemIndex = currentItemIndex - 1;
      const previousItem = calendarItems[previousItemIndex];

      const itemStart = getTime(item, "start");
      const itemEnd = getTime(item, "end");

      if (previousItem) {
        const previousItemStart = getTime(previousItem, "start");
        const previousItemEnd = getTime(previousItem, "end");

        if (
          itemStart.getTime() < previousItemEnd.getTime() &&
          "orderId" in item
        ) {
          if ("workOrders" in previousItem) {
            previousItem.workOrders.push(item);
            previousItem.end = itemEnd;
            return;
          } else if ("orderId" in previousItem) {
            calendarItems[previousItemIndex] = {
              id: UUID.generate().value,
              start: previousItemStart,
              end: itemEnd,
              workOrders: [previousItem, item],
            };
            return;
          }
        }
      }
      calendarItems.push(item);
    });

    return calendarItems;
  }

  /* 
    This is now showing any errors in the IDE but is causing errors in the browser,
    not sure why.

    Obviously needs to be fixed.
  */
  //@ts-ignore
  const workOrdersAndGaps = groupOverlappingItems([...gaps, ...workOrders]);

  const holiday = handymanCtx.getHoliday(dayHelper.date);

  const showRed = !!holiday || dayHelper.isWeekend;

  const dayOfMonth = dayHelper.dayOfMonth.toString().padStart(2, "0");
  const month = (dayHelper.month + 1).toString().padStart(2, "0");
  const dateStr = `${dayOfMonth}/${month}`;

  const workOrderOrValidGaps = workOrdersAndGaps.find(
    (el) =>
      itemIsWorkOrder(el) ||
      itemIsWorkOrderGroup(el) ||
      (itemIsScheduleGap(el) && el.isValid)
  );

  return (
    <div
      className={ClientUtils.twClassNames(
        "grid h-full grid-cols-1 grid-rows-[auto,minmax(0,1fr)]  border border-l-0 text-xs first:border-l",
        "grow",
        offWork && "bg-black/20",
        workOrderOrValidGaps && "min-w-[170px]",
        !workOrderOrValidGaps && "min-w-[120px]"
      )}
    >
      <header
        className={ClientUtils.twClassNames(
          "flex flex-col gap-1 bg-slate-100 p-1",
          showRed && "text-red-600"
        )}
      >
        <span className="flex justify-between">
          <span className="text-xs font-semibold">
            {dayHelper.dayOfWeek}, {dateStr}
          </span>
        </span>

        {reported && (
          <span>
            {new DateHelper(workingHours.start).timeInputFormat}-
            {new DateHelper(workingHours.end).timeInputFormat}
          </span>
        )}
        {offWork && <span>Otillänglig</span>}
        {!workingHours && <span>Ej rapporterat</span>}
        {!!holiday && <span className="italic">{holiday.swedishName}</span>}
      </header>
      <div className="h-full w-full p-1">
        <ul className="flex h-full flex-col gap-1 overflow-x-hidden overflow-y-visible">
          {workOrdersAndGaps.map((workOrderOrGap, i) => {
            return (
              <WorkOrderOrGap
                key={props.dayHelper.date.getTime() + "" + i}
                item={workOrderOrGap}
                workingHours={workingHours}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const WorkOrderOrGap = (props: {
  style?: React.CSSProperties;
  item: CalendarItem;
  workingHours: HandymanWorkingHours.Type | undefined;
}) => {
  const { item, workingHours } = props;
  const isWorkOrder = "startDate" in item;
  const start = isWorkOrder ? item.startDate : item.start;
  const end = isWorkOrder ? item.endDate : item.end;
  const startHelper = new DateHelper(start);
  const endHelper = new DateHelper(end);

  const reported = workingHours?.status === "reported";
  let height: string = "auto";
  let minHeightInPixels: number = 125;

  if (reported) {
    const duration = endHelper.date.getTime() - startHelper.date.getTime();
    const totalDuration =
      workingHours.end.getTime() - workingHours.start.getTime();
    const percentage = duration / totalDuration;
    height = `${percentage * 100}%`;
  }

  if (itemIsWorkOrder(item)) {
    return (
      <WorkOrderItem
        workOrder={item}
        style={props.style}
        height={height}
        minHeightInPixels={minHeightInPixels}
      />
    );
  } else if (itemIsWorkOrderGroup(item)) {
    return (
      <WorkOrderGroupItem
        group={item}
        style={props.style}
        height={height}
        minHeightInPixels={minHeightInPixels}
        workingHours={props.workingHours}
      />
    );
  } else if (itemIsScheduleGap(item)) {
    return (
      <GapItem
        gap={item}
        style={props.style}
        height={height}
        minHeightInPixels={125}
        workingHours={workingHours}
      />
    );
  }

  return <></>;
};

const WorkOrderItem = (props: {
  className?: string;
  style?: React.CSSProperties;
  workOrder: WorkOrder.HandymanWithPermissionsType;
  height?: string;
  minHeightInPixels?: number;
}) => {
  const wo = props.workOrder;
  const startHelper = new DateHelper(wo.startDate);
  const endHelper = new DateHelper(wo.endDate);

  let description: string;
  const maxDescriptionLength = 25;
  if (wo.description.length > maxDescriptionLength) {
    description = wo.description.substring(0, maxDescriptionLength) + "...";
  } else {
    description = wo.description;
  }
  const formattedDate = `${startHelper.hourAndMinutesLabel}-${endHelper.hourAndMinutesLabel}`;
  const street = wo.location.street;
  //const street = "A longer street name for testing purposes";
  const postalCode = wo.location.postalCode;
  const city = wo.location.city;
  //const city = "A longer city name for testing purposes. Even longer now";

  return (
    <MyLink
      key={wo.orderId}
      className={ClientUtils.twClassNames(
        "flex cursor-pointer flex-col rounded border bg-slate-200 p-2 text-xs shadow shadow-slate-300 hover:bg-slate-300",
        wo.isFinished && "border-green-700",
        props.className
      )}
      to={AppRoutes.partner.customerOrder(wo.workerGuid)}
      style={{
        height: props.height,
        minHeight: props.minHeightInPixels,
        flexShrink: 1,
        ...props.style,
      }}
    >
      <span
        className={ClientUtils.twClassNames(
          "w-full pb-1 text-xs font-bold leading-3",
          !!wo.serviceContract && "text-orange-600"
        )}
      >
        {description}
      </span>
      <span className="text-2xs text-gray-700">{wo.typeOfService.name}</span>
      <span className="flex items-center gap-1 text-2xs text-gray-700">
        <span>#{wo.serialNumber}</span>
        <MidDot />
        <span className="flex whitespace-nowrap italic">{formattedDate}</span>
      </span>

      <span className="text-2xs italic text-dark-gray">{street}</span>
      <span className="text-2xs italic text-dark-gray">
        {postalCode}, {city}
      </span>
    </MyLink>
  );
};

const WorkOrderGroupItem = (props: {
  style?: React.CSSProperties;
  workingHours: HandymanWorkingHours.Type | undefined;
  height: string;
  minHeightInPixels: number;
  group: WorkOrderGroup;
}) => {
  const group = props.group;
  const startHelper = new DateHelper(group.start);
  const endHelper = new DateHelper(group.end);
  const [showGroup, setShowGroup] = useState(false);
  const toggleRef = useRef<HTMLLIElement | null>(null);
  useOnEscapeClick(() => {
    setShowGroup(false);
  });

  return (
    <>
      <li
        ref={toggleRef}
        key={group.id}
        onClick={() => {
          setShowGroup(true);
        }}
        className="flex w-full cursor-pointer flex-col overflow-hidden rounded border border-slate-400 bg-slate-300 hover:bg-slate-400"
        style={{
          height: props.height,
          minHeight: props.minHeightInPixels,
          flexShrink: 1,
          ...props.style,
        }}
      >
        <header>
          <span className="flex w-full justify-center whitespace-nowrap bg-slate-400 p-1 text-xs italic text-white">
            {startHelper.hourAndMinutesLabel} - {endHelper.hourAndMinutesLabel}
          </span>
        </header>
        <main>
          <ul className="w-full p-2 text-black">
            {group.workOrders.map((wo, i) => {
              const maxDescriptionLength = 25;
              let description: string;
              if (wo.description.length > maxDescriptionLength) {
                description =
                  wo.description.substring(0, maxDescriptionLength) + "...";
              } else {
                description = wo.description;
              }

              return (
                <li
                  key={wo.orderId + "group"}
                  className="w-full overflow-hidden text-xs"
                >
                  {/* 
                    Should use list-decimal instead of using index but not showing for some reason
                  */}
                  <span
                    className={ClientUtils.twClassNames(
                      "w-full py-1 text-xs font-semibold",
                      !!wo.serviceContract && "text-orange-600"
                    )}
                  >
                    {i + 1}. {description}
                  </span>
                </li>
              );
            })}
          </ul>
        </main>

        <AnimatePresence>
          {showGroup && (
            <ContextLikeMenu
              toggleRef={toggleRef}
              className="min-w-[200px] bg-slate-400"
              onOutsideClick={() => {
                setShowGroup(false);
              }}
            >
              <ul className="flex w-full flex-col gap-1">
                {group.workOrders.map((wo, i) => {
                  return (
                    <WorkOrderItem
                      key={wo.orderId}
                      workOrder={wo}
                      className="w-full"
                    />
                  );
                })}
              </ul>
            </ContextLikeMenu>
          )}
        </AnimatePresence>
      </li>
    </>
  );
};

const GapItem = (props: {
  style?: React.CSSProperties;
  gap: ScheduleGap<any>;
  workingHours: HandymanWorkingHours.Type | undefined;
  height: string;
  minHeightInPixels: number;
}) => {
  const gap = props.gap;
  const startHelper = new DateHelper(gap.start);
  const endHelper = new DateHelper(gap.end);

  return (
    <li
      key={startHelper.timeInputFormat}
      className={ClientUtils.twClassNames(
        "flex flex-col items-center justify-center rounded border p-2 text-xs shadow-inner shadow-green-600/50",
        props.gap.isValid && "border-2 border-green-600 bg-green-600/20 ",
        !props.gap.isValid && "opacity-0"
      )}
      style={{
        height: props.height,
        minHeight: props.gap.isValid ? props.minHeightInPixels : undefined,
        flexShrink: 1,
      }}
    >
      <span
        className={ClientUtils.twClassNames(
          "flex w-full justify-center whitespace-nowrap text-sm italic"
        )}
      >
        {startHelper.hourAndMinutesLabel} - {endHelper.hourAndMinutesLabel}
      </span>
    </li>
  );
};

export default CalendarDayGridItem;
