import { useContext } from "react";
import { useQuery } from "react-query";
import { SignedInContext } from "../../../../components/auth/hocs/withWorkerCredentials";
import Dropdown from "../../../../components/common/dropdown/Dropdown";
import MyLink from "../../../../components/routing/MyLink";
import { useApiClients } from "../../../../hooks/use-api-clients";
import AppRoutes from "../../../../routes";
import ClientUtils from "./../../../../utils/ClientUtils";

import AppUserIcon from "../../../../components/icons/AppUserIcon";
import { HandymanLandingPageContext } from "../HandymanRoute";

interface Props {
  className?: string;
}

const LandingPageProfileWidget = (props: Props) => {
  const signedInCtx = useContext(SignedInContext);
  const landingPageCtx = useContext(HandymanLandingPageContext);
  const signedInHandyman = landingPageCtx.signedInHandyman;
  const handyman = landingPageCtx.handyman;
  const { companyRepo } = useApiClients();

  const employeeRes = useQuery(
    [
      "employees",
      signedInHandyman.companyId,
      signedInHandyman.id,
      signedInHandyman.companyRole,
    ],
    () => {
      if (signedInHandyman.companyRole !== "supervisor") {
        return [];
      }
      return companyRepo.fetchEmployees();
    }
  );

  const fullName = `${handyman.firstName} ${handyman.lastName}`;
  const isSupervisor = signedInCtx.handyman.companyRole === "supervisor";

  if (isSupervisor) {
    return (
      <Dropdown
        absolute
        title={() => (
          <>
            <AppUserIcon size={24} className="row-span-full rounded-full" />
            <h3 className="my-auto">{fullName}</h3>
          </>
        )}
        className="my-auto flex min-w-[350px] items-center gap-2"
        contentClassName="w-full"
      >
        <ul className="w-full p-2">
          <li
            className={ClientUtils.twClassNames(
              "rounded",
              landingPageCtx.isViewingOwnProfile &&
                "pointer-events-none cursor-none bg-brand-blue-200 opacity-60"
            )}
          >
            <MyLink
              to={AppRoutes.partner.root()}
              className={ClientUtils.twClassNames(
                "flex p-2 font-semibold transition-colors hover:bg-brand-blue-200"
              )}
            >
              Din egna profil
            </MyLink>
          </li>
          {employeeRes.data
            ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
            .map((employee) => (
              <li
                key={employee.id}
                className={ClientUtils.twClassNames(
                  "rounded",
                  employee.id === handyman.id &&
                    "pointer-events-none cursor-none bg-brand-blue-200 opacity-60"
                )}
              >
                <MyLink
                  to={AppRoutes.partner.root({ userId: employee.id })}
                  state={{ handyman: employee }}
                  className={ClientUtils.twClassNames(
                    "flex p-2 font-semibold transition-colors hover:bg-brand-blue-200"
                  )}
                >
                  {employee.firstName} {employee.lastName}
                </MyLink>
              </li>
            ))}
        </ul>
      </Dropdown>
    );
  }

  return (
    <MyLink
      to={AppRoutes.partner.profile()}
      className="grid grid-cols-[auto,minmax(0,1fr)] grid-rows-[min-content,min-content] gap-x-4"
    >
      <span className="row-span-full rounded-full bg-brand-blue-200 p-2">
        <AppUserIcon size={35} />
      </span>
      <span className="text-lg font-semibold">{fullName}</span>
      <span className="text-sm">{handyman.companyName}</span>
    </MyLink>
  );
};

export default LandingPageProfileWidget;
