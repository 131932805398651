import { UseFormReturn } from "react-hook-form";

interface Props {
  className?: string;
  form: UseFormReturn<any>;
}

const GDPRLinkAndConfirmationCheckbox = (props: Props) => {
  return (
    <fieldset className={props.className}>
      <a
        href="https://ipisverige.se/kundservice-allmanna-villkor"
        className="col-span-2 text-base italic text-blue-700"
        target={"_blank"}
        rel="noreferrer"
      >
        Länk till IPIS AB:s allmänna villkor och integritetspolicy.
      </a>
      <p className="col-span-2 grid grid-cols-[minmax(0,1fr),auto] gap-3 rounded border border-transparent py-3 focus-within:border-main-bg-dark">
        <label htmlFor="confirmCheckbox" className="cursor-pointer">
          Jag godkänner IPIS AB:s allmänna villkor och accepterar att de lagrar
          och bearbetar mina uppgifter.
        </label>
        <input
          type="checkbox"
          id="confirmCheckbox"
          {...props.form.register("gdprConfirmed")}
          className="m-auto h-6 w-6 cursor-pointer"
          required
        />
      </p>
    </fieldset>
  );
};

export default GDPRLinkAndConfirmationCheckbox;
