import { PaymentOption } from "@eljouren/domain";

namespace PaymentMethodUtils {
  type Headings = {
    heading: string;
    subheading?: string;
  };

  type Method = Pick<PaymentOption.Type, "brand" | "name" | "type"> & {
    variant?: PaymentOption.PartialPaymentType["variant"];
  };

  export function getHeadings(method: Method): Headings {
    if (method.brand === "ipis" && method.type === "invoice") {
      return {
        heading: "Faktura",
        subheading:
          "Vi skickar en faktura till dig med 10 dagars förfallotid. Fakturaavgift 60:- tillkommer.",
      };
    }

    switch (method.type) {
      case "invoice":
        return {
          heading: "Faktura - Utan extra kostnader!",
          /* 
				  Like below with the partialPayment, I know that 60 days is the only option,
				  although it should be dynamic and based on the payment option
				*/
          subheading:
            "Betala via faktura med upp till 60 dagars betalningstid. Inga extra kostnader!",
        };
      case "directPayment":
        switch (method.brand) {
          case "swish":
            return {
              heading: "Swish",
            };
          case "trustly":
            return {
              heading: "Direktbetalning från din bank - Trustly",
              subheading:
                "Betala direkt från din bankkonto med hjälp av Trustly. Inga extra kostnader!",
            };
          default:
            return {
              heading: "Direktbetalning",
            };
        }

      case "partialPayment":
        switch (method.variant) {
          case "FIXED_PRICE_1500":
            return {
              heading: "1500:- delbetalning/månad",
              subheading: "Betala med fast månadsbelopp på 1500:-",
            };
          case "FIXED_PRICE_2000":
            return {
              heading: "2000:- delbetalning/månad",
              subheading: "Betala med fast månadsbelopp på 2000:-",
            };
          case "12_MONTHS":
            return {
              heading: "Delbetalning - Räntefritt!",
              subheading:
                "Betala på upp till 12 månader. Självklart helt räntefritt. Uppläggningsavgift på 295:-.",
            };
          default:
            return {
              heading: "Delbetalning",
              subheading:
                "Betala på upp till 12 månader. Självklart helt räntefritt. Uppläggningsavgift på 295:-.",
            };
        }

      default:
        return {
          heading: method.name,
        };
    }
  }
}

export default PaymentMethodUtils;
