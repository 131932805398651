"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisBrand = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var IpisBrand;
(function (IpisBrand) {
    IpisBrand.ZodEnum = centralized_zod_1.z.enum([
        "Svenska Eljouren",
        "Rörmokare.nu",
        "Rörjour 247",
        "Boka Handyman",
        "Snickare.online",
        "IPIS Contract",
        "IPIS",
        "Aii",
    ]);
    IpisBrand.Schema = centralized_zod_1.z.string().transform(function (val) {
        var safeParse = IpisBrand.ZodEnum.safeParse(val);
        if (safeParse.success) {
            return safeParse.data;
        }
        return "IPIS";
    });
})(IpisBrand || (IpisBrand = {}));
exports.IpisBrand = IpisBrand;
